import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { caseListActions } from 'app/components/routes/CaseList/logic'
import { ActionButton } from 'app/components/ui/ActionButton'
import { Phone } from 'app/components/ui/Icons/common'
import { Padder } from 'app/components/ui/Padder'
import styled from 'styled-components'

interface ContactSupportButtonProps {
  caseId?: string
}

const ContactSupportButton: React.FC<ContactSupportButtonProps> = (props) => {
  const { caseId } = props
  const dispatch = useDispatch()
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(caseListActions.supportButtonClicked())
  }

  return (
    <ActionButton data-testid={`ContactSupportButton-${caseId}`} onClick={handleClick}>
      <Phone width="16px" />
      <Padder width="5px" />
      <ButtonText>
        <FormattedMessage id="cases.list.doctor.button.support" />
      </ButtonText>
    </ActionButton>
  )
}

const ButtonText = styled.span`
  white-space: nowrap;
`

export { ContactSupportButton }
