import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    .ant-dropdown {
      z-index: 10000;
      
      .ant-dropdown-menu-item:hover {
        background: transparent;
      }

      .ant-dropdown-menu-title-content {
        &:hover,
        &:focus,
        &:focus-within,
        &:active {
          background: ${({ theme }) => theme.colors.primary10};
        }
      }
    }

    .ant-select-dropdown.select-legacy-dropdown {
      border: 1px solid ${({ theme }) => theme.colors.base300};
    
      border-radius: 4px;

      .ant-select-item-option-content {
        font-size: 13px;
        font-weight: 400;
      }

      .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
        background-color: ${({ theme }) => theme.colors.base10};
      }

      .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
        background-color: ${({ theme }) => theme.colors.primary10};
      }
    }
  }
`
