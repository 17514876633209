import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { StaffInput as ScanOrderIdInput } from 'app/components/ui/Form/StaffInput'

import { ScanOrderType } from './ScanOrderType'
import { editScansActions } from './logic'

const ScanOrderId = () => {
  const dispatch = useDispatch()
  const form = useFormContext<ScanOrderType>()

  const updateScanOrderId = () => {
    dispatch(editScansActions.updateScanOrderIdRequested(form.getValues('scanOrderId') ?? ''))
  }

  return (
    <div data-testid="ScanOrderId-div">
      <Controller
        name="scanOrderId"
        render={({ field }) => (
          <ScanOrderIdInput
            name={field.name}
            onChange={field.onChange}
            value={field.value}
            onBlur={updateScanOrderId}
          />
        )}
      />
    </div>
  )
}

export { ScanOrderId }
