import React from 'react'

import { Box } from 'app/components/ui/SDS/common/Box'
import styled from 'styled-components'

const HorizontalLine = () => (
  <Box margin="0 1em" flexDirection="row">
    <HorizontalLineBorder />
  </Box>
)

const HorizontalLineBorder = styled.div`
  width: 100%;

  border-top: 1px solid ${({ theme }) => theme.colors.black};
`

export { HorizontalLine }
