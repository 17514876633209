import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { Tooltip } from 'antd'
import { TableCellStyledButton } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/TableCellStyledButtons'
import { caseListActions } from 'app/components/routes/CaseList/logic'
import { SdsPhoneOutlined } from 'app/components/ui/Icons/sds'

interface ContactSupportButtonProps {
  caseId?: string
}

const OnHoldContactSupportButton: React.FC<ContactSupportButtonProps> = (props) => {
  const { caseId } = props
  const dispatch = useDispatch()

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(caseListActions.supportButtonClicked())
  }

  return (
    <Tooltip placement="top" title={<FormattedMessage id="cases.list.doctor.button.support" />}>
      <TableCellStyledButton data-testid={`ContactSupportButton-${caseId}`} onClick={handleClick}>
        <SdsPhoneOutlined width="11px" color="secondary300" />
      </TableCellStyledButton>
    </Tooltip>
  )
}

export { OnHoldContactSupportButton }
