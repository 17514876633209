import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

import { Typography } from 'app/components/ui/SDS/common/Typography'

import { HeaderNavigationLinkProps } from './interfaces'
import { StyledNavigationLink } from './styles'

export const HeaderNavigationLink = ({
  icon,
  href,
  textId,
  isLink,
  isActive,
  'data-testid': dataTestId,
  onClick,
}: HeaderNavigationLinkProps) => {
  if (isLink) {
    return (
      <StyledNavigationLink
        as={Link}
        to={href}
        onClick={onClick}
        data-testid={dataTestId}
        isActive={isActive}
      >
        {icon}
        <Typography component="span">
          <FormattedMessage id={textId} />
        </Typography>
      </StyledNavigationLink>
    )
  }
  return (
    <StyledNavigationLink
      href={href}
      isActive={href === window.location.pathname}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {icon}
      <Typography component="span">
        <FormattedMessage id={textId} />
      </Typography>
    </StyledNavigationLink>
  )
}
