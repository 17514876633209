import { AuthenticationManager } from 'app/core/auth/AuthenticationManager'
import { AxiosRequestConfig } from 'axios'
import { lastValueFrom } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
import URIJS from 'urijs'

const axiosAuthMiddleware = (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const reqUrl = URIJS(config.url)
  const isSameDomainButOauthPath =
    reqUrl.path() === '/oauth/token' &&
    reqUrl.is('absolute') &&
    reqUrl.origin() === URIJS(window.location.href).origin()

  if (
    (reqUrl.is('absolute') && reqUrl.origin() !== URIJS(window.location.href).origin()) ||
    isSameDomainButOauthPath
  ) {
    return Promise.resolve(config)
  }

  return lastValueFrom(
    AuthenticationManager.updateToken().pipe(
      map(() => ({
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${AuthenticationManager.accessToken}`,
        },
      })),
      catchError((error, caught) => {
        if (error.response.status === 401) {
          console.error(
            `Received ${error.status.code} in response. Token expired or invalid. Redirecting to login `,
          )
          AuthenticationManager.authenticate()
        }
        return caught
      }),
    ),
  )
}

export { axiosAuthMiddleware }
