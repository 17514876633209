import { useSelector } from 'react-redux'

import { Box } from 'app/components/ui/SDS/common/Box'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled from 'styled-components'

import { PatientFilter } from './DoctorCaseList/CaseFilter/PatientFilter'
import { ProductFilter } from './DoctorCaseList/CaseFilter/ProductFilter'

const CaseFilter = () => {
  const featureFlags = useSelector(featureFlagSelectors.getFeatureFlags())

  return (
    <CaseFilterContainer
      justify="flex-end"
      align="flex-start"
      width="auto"
      data-testid="CaseFilter"
    >
      <CaseFilterWrapper width="auto">
        {featureFlags.IDB && <ProductFilter />}
        <PatientFilter />
      </CaseFilterWrapper>
    </CaseFilterContainer>
  )
}

const CaseFilterContainer = styled(Box)`
  position: relative;
`

const CaseFilterWrapper = styled(Box)`
  position: absolute;

  z-index: ${({ theme }) => theme.zIndex.layer2};
`

export { CaseFilter }
