import { FC } from 'react'
import { FormattedMessage } from 'react-intl'

import { Col, Row } from 'antd'
import styled from 'styled-components'

import { AuxComponent } from '../useAvailableAuxiliaries'

import { AuxiliaryTypeSummary } from './AuxiliaryTypeSummary'
import { TeethNums } from './TeethNums'

interface AuxiliariesSummaryProps {
  auxiliaries: AuxComponent[]
}

const AuxiliariesSummary: FC<AuxiliariesSummaryProps> = ({ auxiliaries }) => (
  <Container span={8} data-testid="TeethNumbersContainer">
    <Row />
    {auxiliaries.map(({ type, name, arr, Icon }) => (
      <AuxiliaryTypeSummary
        key={name}
        type={name}
        icon={<Icon width="22px" />}
        caption={
          <FormattedMessage
            id="caseWizard.casePrescription.auxiliaries.showTeethNumbers.caption"
            values={{ mode: type }}
          />
        }
      >
        <TeethNums data-testid="casePrescription-teethPlace" arr={arr} />
      </AuxiliaryTypeSummary>
    ))}
  </Container>
)

const Container = styled(Col)`
  padding: 15px;
`

export { AuxiliariesSummary }
