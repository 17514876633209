import { Doctor } from 'app/core/domain/Doctor'
import { ProductGroup } from 'app/core/domain/ProductGroup'
import { appSelectors } from 'app/logic/app/logic'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

import { PatientFilterNames } from './PatientFilter/PatientFilterNames'

interface CaseFilterState {
  isCaseFilterOpen?: boolean
  doctors: Doctor[]
  productGroup: ProductGroup
}

interface CaseFilterUserListReceivedPayload {
  doctors: Doctor[]
  username: string
  selectedUsers: string[]
}

const INITIAL_STATE: CaseFilterState = {
  isCaseFilterOpen: false,
  doctors: [],
  productGroup: ProductGroup.All,
}

const caseFilterActions = {
  caseFilterUserListReceived: createAction(
    '@CASE_FILTER/CASE_FILTER_USER_LIST_RECEIVED',
  )<CaseFilterUserListReceivedPayload>(),
  caseFilterUserListRequestFailed: createAction(
    '@CASE_FILTER/CASE_FILTER_USER_LIST_REQUEST_FAILED',
  )<{ message: string }>(),
  caseFilterClicked: createAction('@CASE_FILTER/CASE_FILTER_CLICKED')(),
  caseFilterMounted: createAction('@CASE_FILTER/CASE_FILTER_MOUNTED')(),
  caseFilterUsersReceived: createAction('@CASE_FILTER/CASE_FILTER_USERS_RECEIVED')<Array<string>>(),
  caseFilterUsersRequestFailed: createAction('@CASE_FILTER/CASE_FILTER_USERS_REQUEST_FAILED')<{
    message: string
  }>(),
  caseFilterUsersCheckBoxClicked: createAction('@CASE_FILTER/CASE_FILTER_USERS_CHECKBOX_CLICKED')<
    Array<Doctor>
  >(),
  caseFilterAllPatientsButtonClicked: createAction(
    '@CASE_FILTER/CASE_FILTER_ALL_PATIENTS_BUTTON_CLICKED',
  )(),
  caseFilterMyPatientsButtonClicked: createAction(
    '@CASE_FILTER/CASE_FILTER_MY_PATIENTS_BUTTON_CLICKED',
  )<{ currentUser: string }>(),
  caseFilterProductGroupSelected: createAction(
    '@CASE_FILTER/CASE_FILTER_PRODUCT_GROUP_SELECTED',
  )<ProductGroup>(),
}

type CaseFilterActions = ActionType<typeof caseFilterActions>

const caseFilterReducer = createReducer<CaseFilterState, CaseFilterActions>(INITIAL_STATE)
  .handleAction(caseFilterActions.caseFilterClicked, (state) => ({
    ...state,
    isCaseFilterOpen: !state.isCaseFilterOpen,
  }))
  .handleAction(caseFilterActions.caseFilterUserListReceived, (state, action) => {
    const { doctors, username, selectedUsers } = action.payload

    return {
      ...state,
      doctors: doctors
        .map((doctor) => ({
          ...doctor,
          checked: selectedUsers !== undefined ? selectedUsers.includes(doctor.username) : false,
        }))
        .sort((doctor) => (doctor.username === username ? -1 : 1)),
    }
  })
  .handleAction(
    [
      caseFilterActions.caseFilterUserListRequestFailed,
      caseFilterActions.caseFilterUsersRequestFailed,
    ],
    (state) => ({
      ...state,
      doctors: [],
    }),
  )
  .handleAction(caseFilterActions.caseFilterUsersCheckBoxClicked, (state, action) => ({
    ...state,
    doctors: [...action.payload],
  }))
  .handleAction(caseFilterActions.caseFilterAllPatientsButtonClicked, (state) => ({
    ...state,
    doctors: state.doctors.map((doctor) => ({ ...doctor, checked: true })),
    isCaseFilterOpen: false,
  }))
  .handleAction(caseFilterActions.caseFilterMyPatientsButtonClicked, (state, action) => ({
    ...state,
    doctors: state.doctors.map((doctor) => ({
      ...doctor,
      checked: doctor.username === action.payload.currentUser,
    })),
    isCaseFilterOpen: false,
  }))
  .handleAction(caseFilterActions.caseFilterProductGroupSelected, (state, action) => ({
    ...state,
    productGroup: action.payload,
  }))

const caseFilterSelectors = {
  isCaseFilterOpen: () => (state: RootState) => state.caseFilter.isCaseFilterOpen,
  getCaseFilterUsers: () => (state: RootState) => state.caseFilter.doctors,
  getCaseFilterProductGroup: () => (state: RootState) => state.caseFilter.productGroup,
  getCaseFilterSelectedUsers:
    () =>
    (state: RootState): string[] =>
      state.caseFilter.doctors.filter((doctor) => doctor.checked).map((doctor) => doctor.username),
  getCaseFilterName: () => (state: RootState) => {
    const userList = state.caseFilter.doctors
    const currentUser = appSelectors.getUsername()(state)
    const selectedDoctors = caseFilterSelectors.getCaseFilterSelectedUsers()(state)
    let caseFilterName = PatientFilterNames.MY_PATIENTS

    if (selectedDoctors.length === 0) {
      caseFilterName = PatientFilterNames.NO_PATIENTS
    } else if (selectedDoctors.length === 1 && selectedDoctors.indexOf(currentUser) === 0) {
      caseFilterName = PatientFilterNames.MY_PATIENTS
    } else if (selectedDoctors.length > 0 && selectedDoctors.length === userList.length) {
      caseFilterName = PatientFilterNames.ALL_PATIENTS
    } else if (selectedDoctors.length > 0) {
      caseFilterName = PatientFilterNames.SELECTED_PATIENTS
    }
    return caseFilterName
  },
}

export {
  CaseFilterUserListReceivedPayload,
  CaseFilterState,
  CaseFilterActions,
  caseFilterActions,
  caseFilterReducer,
  caseFilterSelectors,
}
