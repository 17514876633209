import { Nullable } from 'app/core/types/utils'
import { legacyTheme } from 'app/theme/theme'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

class ScanViewer {
  private camera: Nullable<THREE.PerspectiveCamera> = null

  private scene: Nullable<THREE.Scene> = null

  private controls: Nullable<OrbitControls> = null

  private renderer: Nullable<THREE.WebGLRenderer> = null

  constructor() {
    this.animate = this.animate.bind(this)
  }

  init(url: string, container: HTMLDivElement, onLoad?: () => void) {
    this.camera = new THREE.PerspectiveCamera(35, container.offsetWidth / container.offsetHeight)

    this.scene = new THREE.Scene()
    const canvas = document.createElement('canvas')

    canvas.width = window.innerWidth
    canvas.height = window.innerHeight
    const ctx = canvas.getContext('2d')

    if (ctx) {
      const grd = ctx.createLinearGradient(0, 0, 0, window.innerHeight)

      grd.addColorStop(0, legacyTheme.colors.white)
      grd.addColorStop(0.7, legacyTheme.colors.base900)
      ctx.fillStyle = grd
      ctx.fillRect(0, 0, window.innerWidth, window.innerHeight)

      this.scene.background = new THREE.CanvasTexture(canvas)
    }

    const loader = new STLLoader()

    const material = new THREE.MeshPhongMaterial({
      color: legacyTheme.colors.primary500,
      shininess: 200,
    })

    loader.load(url, (geometry) => {
      if (!this.camera || !this.scene) return

      const { camera, scene } = this
      const mesh = new THREE.Mesh(geometry, material)
      const fov = camera.fov * (Math.PI / 180)

      mesh.castShadow = true
      mesh.receiveShadow = true

      scene.add(mesh)
      onLoad?.()

      setTimeout(() => {
        const positionZ =
          fov && geometry.boundingSphere
            ? Math.abs(geometry.boundingSphere.radius / Math.sin(fov / 2))
            : 0

        camera.position.set(0, 0, positionZ)
      }, 100)
    })

    // Lights
    this.scene.add(new THREE.HemisphereLight(0x443333, 0x111122))

    this.addShadowedLight(1, 1, 1, 0xffffff, 1.35)
    this.addShadowedLight(0.5, 1, -1, 0xffaa00, 1)
    this.addShadowedLight(0, -1, -1, 0xffffff, 1)
    this.addShadowedLight(-1, -1, -1, 0xffffff, 1)

    // renderer
    this.renderer = new THREE.WebGLRenderer({ antialias: true })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(container.offsetWidth, container.offsetHeight)
    this.renderer.outputEncoding = THREE.sRGBEncoding

    this.renderer.shadowMap.enabled = true

    this.controls = new OrbitControls(this.camera, this.renderer.domElement)

    container.appendChild(this.renderer.domElement)
  }

  animate() {
    requestAnimationFrame(this.animate)

    if (this.controls && this.renderer && this.scene && this.camera) {
      this.controls.update()
      this.renderer.render(this.scene, this.camera)
    }
  }

  clear() {
    this.scene?.clear()
    this.camera?.clear()
    this.renderer?.dispose()
    this.controls?.dispose()

    this.scene = null
    this.camera = null
    this.renderer = null
    this.controls = null
  }

  private addShadowedLight(x: number, y: number, z: number, color: number, intensity: number) {
    if (!this.scene) return

    const directionalLight = new THREE.DirectionalLight(color, intensity)

    directionalLight.position.set(x, y, z)

    directionalLight.castShadow = true

    const d = 1

    directionalLight.shadow.camera.left = -d
    directionalLight.shadow.camera.right = d
    directionalLight.shadow.camera.top = d
    directionalLight.shadow.camera.bottom = -d

    directionalLight.shadow.camera.near = 1
    directionalLight.shadow.camera.far = 4

    directionalLight.shadow.bias = -0.002

    this.scene.add(directionalLight)
  }
}

export { ScanViewer }
