import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import { ClickableTeeth } from 'app/components/routes/CaseWizard/form/Teeth/ClickableTeeth'
import {
  Arch,
  BITE_RAMPS_TEETH_DISABLED,
  getArchTeethNumbers,
  Universal,
} from 'app/core/domain/ArchTools'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { TeethImageType } from 'app/core/domain/TeethImageType'
import { addOrRemoveFromArray } from 'app/core/lang/ArrayUtils'
import styled from 'styled-components'

import { ActivitySelection } from '../ActivitySelection'
import { AuxComponent } from '../useAvailableAuxiliaries'

import { auxiliariesStylesMap } from './auxiliariesStylesMap'

interface AuxClickableTeethProps {
  auxiliaries: AuxComponent[]
  activeSelect: keyof typeof ActivitySelection
  'data-testid': string
}

const AuxClickableTeeth = (props: AuxClickableTeethProps) => {
  const { activeSelect, 'data-testid': testId } = props
  const arches = [Arch.UPPER, Arch.LOWER]
  const key = `primaryCasePreferences.${activeSelect}`
  const { setValue, watch } = useFormContext()

  const selectedTeeth: number[] = watch(key)
  const treatArches: string = watch('primaryCasePreferences.treatArches')

  const getAuxComponents = useCallback(
    (toothNumber: Universal) => {
      const images: Pick<AuxComponent, 'type' | 'Icon'>[] = []

      for (const array of props.auxiliaries) {
        if (array.arr?.includes(toothNumber)) {
          images.push({
            type: array.type,
            Icon: array.Icon,
          })
        }
      }

      return (
        <>
          {images.map(({ type, Icon }, index) => {
            const { top, left, right, size } =
              auxiliariesStylesMap[toothNumber][images.length][index]

            return (
              <IconContainer
                key={index}
                width={`${size}px`}
                top={top.toString(10) ? `${top.toString(10)}%` : '0'}
                left={left ? `${left}%` : undefined}
                right={right ? `${right}%` : undefined}
                data-type={type}
              >
                <Icon width={`${size}`} />
              </IconContainer>
            )
          })}
        </>
      )
    },
    [props.auxiliaries],
  )

  const isToothDisabled = useCallback((toothNumber: Universal, selectedActivity, archToTreat) => {
    if (
      archToTreat === TreatArches.UPPER_ONLY &&
      getArchTeethNumbers(Arch.LOWER).includes(toothNumber)
    ) {
      return true
    }
    if (
      archToTreat === TreatArches.LOWER_ONLY &&
      getArchTeethNumbers(Arch.UPPER).includes(toothNumber)
    ) {
      return true
    }
    if (selectedActivity === ActivitySelection.biteRampTeeth) {
      return BITE_RAMPS_TEETH_DISABLED.includes(toothNumber)
    }
    return false
  }, [])
  const onToothClick = useCallback(
    (teethNumber) => {
      if (!isToothDisabled(teethNumber, activeSelect, treatArches)) {
        const res = addOrRemoveFromArray([teethNumber], selectedTeeth)

        setValue(key, [...res])
      }
    },
    [selectedTeeth, setValue, key, activeSelect, treatArches, isToothDisabled],
  )
  const getToothType = useCallback(
    (toothNumber) => {
      if (isToothDisabled(toothNumber, activeSelect, treatArches)) {
        return TeethImageType.DISABLED
      }
      return TeethImageType.PLAIN
    },
    [activeSelect, treatArches, isToothDisabled],
  )

  return (
    <ClickableTeeth
      onToothClick={onToothClick}
      arches={arches}
      getAuxComponent={getAuxComponents}
      getToothType={getToothType}
      data-testid={`${testId}-ClickableTeeth`}
    />
  )
}

const IconContainer = styled.div<{ top?: string; left?: string; right?: string; width: string }>`
  position: absolute;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  left: ${(props) => props.left};

  width: ${(props) => props.width};
`

export { AuxClickableTeeth }
