import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ApRelationshipPrimary } from 'app/core/domain/ApRelationshipPrimary'
import { CaseDraft } from 'app/core/domain/CaseDraft'

const PROPERTY_KEY = 'primaryCasePreferences.apRelationshipPrimary'

interface CanineSettingsProps {
  onChange: (value: ApRelationshipPrimary) => void
}

const CanineSettings = ({ onChange }: CanineSettingsProps) => {
  const form = useFormContext<CaseDraft>()

  const apRelationshipPrimary = form.getValues(PROPERTY_KEY)

  return (
    <Box justify="flex-start">
      <OrmcoCheckbox
        onChange={(selected) =>
          onChange({
            ...apRelationshipPrimary,
            ...{
              IPR3_6: selected.target.checked,
            },
          })
        }
        checked={apRelationshipPrimary && apRelationshipPrimary.IPR3_6 === true}
        data-testid="AnteriorPosteriorRelationship-Canine-IPR3_6"
      >
        <Typography component="span">
          <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.IPR36" />
        </Typography>
      </OrmcoCheckbox>
    </Box>
  )
}

export { CanineSettings }
