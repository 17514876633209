import { CaseStatus, OrderStatus } from 'app/core/domain/CaseStatus'
import { Doctor } from 'app/core/domain/Doctor'
import { Order } from 'app/core/domain/Order'
import { Patient } from 'app/core/domain/Patient'
import { ProductTier } from 'app/core/domain/ProductTier'

export enum HoldReasonsTypes {
  Internal = 'Internal',
  External = 'External',
}

export enum HoldReasonCodes {
  MISSING_SCAN = 'MISSING_SCAN',
  MISSING_UPPER_SCAN = 'MISSING_UPPER_SCAN',
  MISSING_LOWER_SCAN = 'MISSING_LOWER_SCAN',
  DISTORTED_SCAN = 'DISTORTED_SCAN',
  DUPLICATED_SCAN = 'DUPLICATED_SCAN',
  EXCESS_SCAN = 'EXCESS_SCAN',
  INCOMPLETE_SCAN = 'INCOMPLETE_SCAN',
  CORRUPTED_SCAN = 'CORRUPTED_SCAN',
  MISMATCHED_PHOTOS_SCAN = 'MISMATCHED_PHOTOS_SCAN',
  MISSING_BITES_ON_SCAN = 'MISSING_BITES_ON_SCAN',
  MISMATCHED_PHOTOS = 'MISMATCHED_PHOTOS',
  MISSING_PHOTOS = 'MISSING_PHOTOS',
  INCOMPLETE_CBCT = 'INCOMPLETE_CBCT',
  DISTORTED_DICOM = 'DISTORTED_DICOM',
  MISMATCHED_CBCT_PATIENT = 'MISMATCHED_CBCT_PATIENT',
  PVS_SHIPMENT_IN_TRANSIT = 'PVS_SHIPMENT_IN_TRANSIT',
  PVS_IN_PROCESS = 'PVS_IN_PROCESS',
  PVS_FAILED = 'PVS_FAILED',
  PVS_NOT_SUPPORTED = 'PVS_NOT_SUPPORTED',
  ERP_ACTION_REQUIRED = 'ERP_ACTION_REQUIRED',
  MANUFACTURING_ISSUE = 'MANUFACTURING_ISSUE',
  MRDR = 'MRDR',
  DESIGN_CHECK = 'DESIGN_CHECK',
  MANUFACTURING_CHECK = 'MANUFACTURING_CHECK',
  SHIPPING_CHECK = 'SHIPPING_CHECK',
  ANATOMY_FAILED = 'ANATOMY_FAILED',
  ARCHIVED = 'ARCHIVED',
  OTHER = 'OTHER',
  BAD_IMPRESSION = 'BAD_IMPRESSION',
  DOCTOR_REQUEST = 'DOCTOR_REQUEST',
  ADDITIONAL_INFO_NEEDED = 'ADDITIONAL_INFO_NEEDED',
}

export interface HoldDetailsType {
  comment: string
  files: [
    {
      name: string
      uid: string
    },
  ]
  reasonCode: HoldReasonCodes
}

interface Case {
  caseId: string
  notes?: string
  img?: string
  operationalState: CaseStatus
  order: Order
  orderStatus: OrderStatus
  patient: Patient
  productTier?: ProductTier
  submittedDateTime?: string
  creationDateTime?: string
  shippedDateTime?: string
  pendingApprovalDays?: number
  shippingProviderUrl: string
  hold?: boolean
  holdDetails?: HoldDetailsType
  holdReasonType: HoldReasonsTypes
  archived?: boolean
  thumbnailUrl: string
  orderId: string
  awaitingApprovalCount: number
  doctor: Doctor
}

export { Case }
