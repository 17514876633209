import { useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ThemeContext } from 'app/components/ui/Theme/ThemeContext'
import { useDidMount } from 'app/core/react/CustomHooks'

import { BoxMargin } from './DisplayScanType'
import { ScanOrderId } from './ScanOrderId'
import { ScanOrderType } from './ScanOrderType'

interface ScannerInfoBoxProps {
  scannerDescription?: string
  scanOrderId?: string
}

const ScannerInfoBox = (props: ScannerInfoBoxProps) => {
  const { scannerDescription, scanOrderId } = props
  const theme = useContext(ThemeContext)
  const form = useForm<ScanOrderType>({
    mode: 'onChange',
  })

  useDidMount(() => {
    form.register('scanOrderId')

    return () => {
      form.unregister('scanOrderId')
    }
  })

  useEffect(() => {
    if (!form.formState.isDirty) {
      form.setValue('scanOrderId', scanOrderId)
    }
  }, [form, scanOrderId])

  return (
    <BoxMargin contextTheme={theme}>
      <FormProvider {...form}>
        <Box width="600px" align="center" justify="flex-start">
          <Typography component="label" margin="0 2% 0 0" textFontWeight="600">
            {scannerDescription} <FormattedMessage id="scanner.orderId" />:
          </Typography>
          <ScanOrderId />
        </Box>
      </FormProvider>
    </BoxMargin>
  )
}

export { ScannerInfoBox }
