import styled, { css } from 'styled-components'

import { TypographyProps } from './interfaces'

export const TypographyShadedStyles = css<TypographyProps>`
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};

  opacity: ${({ opacity }) => opacity};

  white-space: ${({ whiteSpace }) => whiteSpace};
  text-align: ${({ align }) => align};
  word-break: ${({ wordBreak }) => wordBreak};
  letter-spacing: ${({ letterSpacing }) => letterSpacing};
  text-transform: ${({ isUppercase = false }) => isUppercase && 'uppercase'};
  text-decoration: ${({ isUnderlined = false, isLineThrough = false }) =>
    (isUnderlined && 'underline') || (isLineThrough && 'line-through')};

  font-weight: ${({ textFontWeight }) => textFontWeight};
  font-style: ${({ isItalic = false }) => isItalic && 'italic'};

  color: ${({ theme, color = 'black' }) =>
    color === 'inherit' ? 'inherit' : theme.colors[color]};

  ${({ theme, variant = 'default' }) => {
    const { fontSize, lineHeight } = theme.fontVariants[variant]

    return css`
      font-size: ${fontSize};
      line-height: ${lineHeight};
    `
  }};

  ${({ textOverflow }) => {
    return (
      textOverflow &&
      css`
        overflow: hidden;

        width: 100%;

        text-overflow: ${textOverflow};
      `
    )
  }};
`

export const StyledTypography = styled.p<TypographyProps>`
  ${TypographyShadedStyles}

  ${({ lineClamp }) =>
    lineClamp !== undefined &&
    css`
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${lineClamp};
    `};
  ${({ component }) =>
    component === 'label' &&
    css`
      display: block;
      width: fit-content;
    `}
`
