import { DoctorCaseCount, DoctorCaseTabs } from 'app/components/routes/CaseList/DoctorCaseCount'
import { unarchiveModalActions } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/UnarchiveModal/logic'
import { generateCaseListUrlString } from 'app/components/routes/CaseList/caseListUrlGenerator'
import { caseListActions, caseListSelectors, ListType } from 'app/components/routes/CaseList/logic'
import { EnvironmentParams } from 'app/core/domain/EnvironmentParams'
import { CaseListResponse, OrderType } from 'app/core/domain/Http/CaseListResponse'
import { OperatingSystem } from 'app/core/domain/OperatingSystem'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import jstz from 'jstz'
import { combineEpics, Epic } from 'redux-observable'
import { from, merge } from 'rxjs'
import { debounceTime, filter, ignoreElements, switchMap, tap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'
import URI from 'urijs'

import { caseFilterActions, caseFilterSelectors } from './DoctorCaseList/CaseFilter/logic'

const addUrlToBrowserHistory = (url: string) => {
  if (window.location.href !== url) {
    window.history.pushState(null, '', url.toString())
  }
}

const makeCasesCountsRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(
      isActionOf([
        caseFilterActions.caseFilterUserListReceived,
        unarchiveModalActions.unarchiveRequestReceived,
        caseFilterActions.caseFilterProductGroupSelected,
        caseFilterActions.caseFilterUsersCheckBoxClicked,
        caseFilterActions.caseFilterMyPatientsButtonClicked,
        caseFilterActions.caseFilterAllPatientsButtonClicked,
      ]),
    ),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const caseFilterSelectedUsers = caseFilterSelectors
        .getCaseFilterSelectedUsers()(state$.value)
        .join(',')
      const caseFilterProductGroup = caseFilterSelectors.getCaseFilterProductGroup()(state$.value)

      return from(
        axios
          .get<DoctorCaseCount>(`/api/v1/doctors/${username}/cases/count`, {
            params: {
              users: caseFilterSelectedUsers,
              productGroup: caseFilterProductGroup,
            },
          })
          .then((res) => caseListActions.countRequestFinished(res.data))
          .catch((err) => caseListActions.countRequestFailed({ message: err.toString() })),
      )
    }),
  )

const makeCaseListRequestEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  merge(
    action$.pipe(
      filter(
        isActionOf([
          caseFilterActions.caseFilterUserListReceived,
          caseListActions.loadMoreCasesReached,
          caseListActions.clearSearchButtonClicked,
          caseListActions.doctorActiveCaseTabClicked,
          unarchiveModalActions.unarchiveRequestReceived,
          caseListActions.sortButtonClicked,
          caseFilterActions.caseFilterProductGroupSelected,
          caseFilterActions.caseFilterUsersCheckBoxClicked,
          caseFilterActions.caseFilterMyPatientsButtonClicked,
          caseFilterActions.caseFilterAllPatientsButtonClicked,
        ]),
      ),
    ),
    action$.pipe(filter(isActionOf(caseListActions.searchCasesInputChanged)), debounceTime(500)),
  ).pipe(
    tap(() => {
      let search: string | undefined
      let tab: keyof typeof DoctorCaseTabs | undefined
      const listType = caseListSelectors.getListType()(state$.value)

      if (listType === ListType.SEARCH) {
        search = caseListSelectors.getCaseListParams()(state$.value).search
      } else if (listType === ListType.TAB) {
        tab = caseListSelectors.getDoctorActiveCaseFilter()(state$.value)
      }
      const url = URI(window.location.href)
        .setQuery({
          tab,
          search,
        })
        .toString()

      addUrlToBrowserHistory(url)
    }),
    switchMap(() => {
      const listType = caseListSelectors.getListType()(state$.value)
      const username = appSelectors.getUsername()(state$.value)
      const userRole = appSelectors.getUserRole()(state$.value)
      const tab = caseListSelectors.getDoctorActiveCaseFilter()(state$.value)
      const url = generateCaseListUrlString({
        listType,
        username,
        userRole,
        tab,
      })

      const timezone = jstz.determine()
      const params = caseListSelectors.getCaseListParams()(state$.value)
      const searchParams = {
        browserTimezone: listType === ListType.SEARCH ? timezone.name() : undefined,
        locale: appSelectors.getLocale()(state$.value),
      }
      const caseSorting = caseListSelectors.getCaseSorting()(state$.value)
      const caseFilterSelectedUsers = caseFilterSelectors
        .getCaseFilterSelectedUsers()(state$.value)
        .join(',')
      const caseFilterProductGroup = caseFilterSelectors.getCaseFilterProductGroup()(state$.value)

      return from(
        axios
          .get<CaseListResponse>(url, {
            params: {
              ...params,
              ...searchParams,
              sortName: caseSorting.sortName,
              sortOrder: caseSorting.sortOrder,
              users: caseFilterSelectedUsers,
              productGroup: caseFilterProductGroup,
              browserTimezone: timezone.name(),
            },
          })
          .then((res) => caseListActions.caseListRequestFinished(res.data))
          .catch((err) => caseListActions.caseListRequestFailed({ message: err.toString() })),
      )
    }),
  )

const openApproverEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([caseListActions.openApproverButtonClicked])),
    tap((action) => {
      const {
        case: { caseId, order },
        isWeb,
      } = action.payload
      const username = appSelectors.getUsername()(state$.value)
      const stage = order.stage > 0 ? order.stage : ''

      const queryParams = URI.buildQuery({
        caseId,
        orderIndicator: `${OrderType.letter(order.name)}${stage}`,
      })

      const getSystemKey = () => {
        if (OperatingSystem.isWindows()) {
          return 'alignerapprover:'
        } else if (OperatingSystem.isMac()) {
          return 'approvermac:'
        } else {
          return ''
        }
      }

      const desktopParams = `${getSystemKey()} -d ${action.payload.case.caseId} ${OrderType.letter(
        order.name,
      )}${stage} ?${queryParams}&username=${username}`

      const approverWebURL = URI(EnvironmentParams.APPROVER_WEB).query(queryParams).toString()
      const approverDesktopURL = URI(EnvironmentParams.ALIGNER_WEB_URL)
        .path('/launchApplication')
        .escapeQuerySpace(false)
        .addQuery('url', desktopParams)
        .toString()

      if (isWeb) {
        window.open(approverWebURL)
      } else {
        window.location.assign(approverDesktopURL)
      }
    }),
    ignoreElements(),
  )

const trackCaseEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([caseListActions.trackCaseButtonClicked])),
    tap((action) => {
      window.open(action.payload.case.shippingProviderUrl, '_blank')
    }),
    ignoreElements(),
  )

const openSupportEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([caseListActions.supportButtonClicked])),
    tap(() => {
      window.open(SiteMap.supportCustomer())
    }),
    ignoreElements(),
  )

const getHoldCaseImageUrlEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([caseListActions.getHoldCaseImageUrl])),
    switchMap(({ payload: { caseId, uid } }) =>
      from(
        axios
          .get(`/api/v1/cases/${caseId}/holdRefDocs/url/${uid}`)
          .then((res) =>
            caseListActions.getHoldCaseImageUrlRequestFinished({ fileUrl: res.data?.url }),
          )
          .catch(() => caseListActions.getHoldCaseImageUrlRequestFailed()),
      ),
    ),
  )

const getScanBestPracticesPdfUrlEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([caseListActions.getScanBestPracticesPdfUrl])),
    switchMap(() =>
      from(
        axios
          .get(`/api/v1/webapi/common-file/SCANNING_BEST_PRACTICES/url`)
          .then((res) => {
            window.open(res.data.url, '_blank')
            return caseListActions.getScanBestPracticesPdfUrlRequestFinished({
              fileUrl: res.data?.url,
            })
          })
          .catch(() => caseListActions.getScanBestPracticesPdfUrlRequestFailed()),
      ),
    ),
  )

const casesListEpic = combineEpics(
  makeCasesCountsRequestEpic,
  makeCaseListRequestEpic,
  openApproverEpic,
  trackCaseEpic,
  openSupportEpic,
  getHoldCaseImageUrlEpic,
  getScanBestPracticesPdfUrlEpic,
)

export { casesListEpic }
