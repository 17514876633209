import { combineReducers } from '@reduxjs/toolkit'
import {
  DeleteAddressModalActions,
  deleteAddressModalReducer,
} from 'app/components/routes/Address/DeleteAddressModal/logic'
import { AddressActions, addressReducer } from 'app/components/routes/Address/logic'
import {
  IntegratedHooksModalActions,
  integratedHooksModalReducer,
} from 'app/components/routes/BasicPage/IntegratedHooksModal/logic'
import {
  TermsActions,
  termsReducer,
} from 'app/components/routes/BasicPage/TermsAndConditionsModal/logic'
import { BasicPageActions, basicPageReducer } from 'app/components/routes/BasicPage/logic'
import {
  CaseFilterActions,
  caseFilterReducer,
} from 'app/components/routes/CaseList/DoctorCaseList/CaseFilter/logic'
import {
  CasePerksActions,
  casePerksReducer,
} from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import {
  CaseSubmittedModalActions,
  caseSubmittedModalReducer,
} from 'app/components/routes/CaseList/DoctorCaseList/CaseSubmittedModal/logic'
import {
  UnarchiveModalActions,
  unarchiveModalReducer,
} from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/UnarchiveModal/logic'
import {
  DoctorNoteActions,
  doctorNoteReducer,
} from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorNote/logic'
import { CaseListActions, caseListReducer } from 'app/components/routes/CaseList/logic'
import { CaseWizardActions, caseWizardReducer } from 'app/components/routes/CaseWizard/logic'
import {
  TruGenXRModalActions,
  truGenXRModalReducer,
} from 'app/components/routes/CaseWizard/steps/CasePrescription/TruGenXRSelectModal/logic'
import {
  EditScansActions,
  editScansReducer,
} from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { UploadDicomActions, uploadDicomReducer } from 'app/components/routes/Dicom/logic'
import {
  ChangePasswordModalActions,
  changePasswordModalReducer,
} from 'app/components/routes/MyProfile/ChangePasswordModal/logic'
import {
  DoctorDetailsActions,
  doctorDetailsReducer,
} from 'app/components/routes/Staff/DoctorDetails/logic'
import {
  NoUsersAssociatedModalActions,
  noUsersAssociatedModalReducer,
} from 'app/components/routes/Staff/NoUsersAssociatedModal/logic'
import {
  StaffDeleteModalActions,
  staffDeleteModalReducer,
} from 'app/components/routes/Staff/StaffDeleteModal/logic'
import {
  StaffCreationActions,
  staffCreationReducer,
} from 'app/components/routes/Staff/StaffDetails/logic'
import { StaffListActions, staffListReducer } from 'app/components/routes/Staff/StaffTable/logic'
import { NotificationActions, notificationReducer } from 'app/components/ui/notification/logic'
import { AppActions, appReducer } from 'app/logic/app/logic'
import { FeatureFlagActions, featureFlagReducer } from 'app/logic/features/logic'
import { StateType } from 'typesafe-actions/dist/type-helpers'

const rootReducer = combineReducers({
  app: appReducer,
  featureFlag: featureFlagReducer,
  basicPage: basicPageReducer,
  caseList: caseListReducer,
  terms: termsReducer,
  unarchive: unarchiveModalReducer,
  doctorNote: doctorNoteReducer,
  casePerks: casePerksReducer,
  caseSubmittedModal: caseSubmittedModalReducer,
  caseWizard: caseWizardReducer,
  editScans: editScansReducer,
  uploadDicom: uploadDicomReducer,
  caseFilter: caseFilterReducer,
  staffCreation: staffCreationReducer,
  staffTable: staffListReducer,
  noUserAssociatedModal: noUsersAssociatedModalReducer,
  doctorDetails: doctorDetailsReducer,
  staffDeleteModal: staffDeleteModalReducer,
  notification: notificationReducer,
  changePasswordModal: changePasswordModalReducer,
  address: addressReducer,
  deleteAddressModal: deleteAddressModalReducer,
  integratedHooksModal: integratedHooksModalReducer,
  truGenXRModal: truGenXRModalReducer,
})

type RootState = StateType<typeof rootReducer>

type RootAction =
  | AppActions
  | FeatureFlagActions
  | CaseListActions
  | TermsActions
  | UnarchiveModalActions
  | DoctorNoteActions
  | CasePerksActions
  | CaseSubmittedModalActions
  | CaseWizardActions
  | EditScansActions
  | UploadDicomActions
  | CaseFilterActions
  | StaffCreationActions
  | StaffListActions
  | NoUsersAssociatedModalActions
  | DoctorDetailsActions
  | StaffDeleteModalActions
  | NotificationActions
  | ChangePasswordModalActions
  | AddressActions
  | BasicPageActions
  | DeleteAddressModalActions
  | IntegratedHooksModalActions
  | TruGenXRModalActions

export { rootReducer, RootState, RootAction }
