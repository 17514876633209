import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { termsSelectors } from 'app/components/routes/BasicPage/TermsAndConditionsModal/logic'
import { appSelectors } from 'app/logic/app/logic'
import styled from 'styled-components'

import { LanguageDropdown } from '../LanguageDropdown'
import { Box } from '../common/Box'
import { Typography } from '../common/Typography'

import { FooterLink } from './components'

const OrmcoLink = () => (
  <FooterLink data-testid="Footer-OrmcoLink" href="https://ormco.com">
    <FormattedMessage id="footer.ormcoLinkTitle" />
  </FooterLink>
)

const TermsOfUseLink = () => {
  const { link } = useSelector(termsSelectors.getTermsState())

  return (
    <FooterLink margin="0 16px" data-testid="Footer-TermsOfUseLink" href={link}>
      <FormattedMessage id="footer.termsofUseLinkTitle" />
    </FooterLink>
  )
}

const PrivacyLink = () => (
  <FooterLink
    margin="0 16px"
    data-testid="Footer-PrivacyLink"
    href="https://ormcoeurope.com/sybron-dental-specialties-on-line-privacy-policy/"
  >
    <FormattedMessage id="footer.privacyLinkTitle" />
  </FooterLink>
)

const Version = () => {
  const version = useSelector(appSelectors.getVersion())

  return (
    <Typography data-testid="Footer-Version" component="span" opacity="0">
      <FormattedMessage id="footer.version" /> : {version}
    </Typography>
  )
}

export const Footer = () => {
  const copyrightYear = useSelector(appSelectors.getCopyrightYear())
  const isImpersonated = useSelector(appSelectors.isImpersonated())

  return (
    <FooterContainer
      as="footer"
      padding="16px"
      maxHeight="52px"
      justify="space-between"
      data-testid="Footer"
    >
      <Typography data-testid="Footer-Copyright" component="span" variant="small" color="base400">
        <FormattedMessage
          id="footer.message"
          values={{
            copyrightYear,
            ormcoLink: <OrmcoLink />,
          }}
        />
      </Typography>
      <Version />
      <Box justify="space-around" width="auto" data-testid="Footer-RightContainer">
        <TermsOfUseLink />
        <PrivacyLink />
        {!isImpersonated && (
          <Box marginLeft="32px" width="auto">
            <LanguageDropdown data-testid="Footer-LanguageDropdown" />
          </Box>
        )}
      </Box>
    </FooterContainer>
  )
}

const FooterContainer = styled(Box)`
  a {
    color: ${({ theme }) => theme.colors.primary500};
  }
`
