import { FormattedMessage } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'

import {
  TooltipLinkBox,
  ButtonLink,
} from 'app/components/routes/CaseWizard/steps/CasePrescription/TruGenTooltip'
import { uploadDicomActions, uploadDicomSelectors } from 'app/components/routes/Dicom/logic'
import { HelpIcon } from 'app/components/ui/Form/HelpIcon'
import { OrmcoTooltip } from 'app/components/ui/Form/OrmcoTooltip'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { useDidMount } from 'app/core/react/CustomHooks'
import { appSelectors } from 'app/logic/app/logic'

import { editScansSelectors } from './logic'

const DicomTooltip = () => {
  const dispatch = useDispatch()
  const language = useSelector(appSelectors.getLanguage())
  const cloudFrontScans = useSelector(editScansSelectors.getCloudFrontScansByLanguage(language))
  const dicomPdfLink = useSelector(uploadDicomSelectors.getDicomPdfLink())
  const getPresignedLink = cloudFrontScans?.scanner__dicom__link ?? ''
  const regex = new RegExp(/^(http|https):\/\//g)
  const isUrl = regex.test(getPresignedLink)
  const openLinkUrl = isUrl ? getPresignedLink : dicomPdfLink

  useDidMount(() => {
    if (!isUrl) {
      dispatch(uploadDicomActions.getDicomPdfRequested({ getPresignedLink }))
    }

    return () => {
      dispatch(uploadDicomActions.dicomPdfUnmounted())
    }
  })

  return cloudFrontScans?.scanner_description__dicom__popup ?? getPresignedLink ? (
    <OrmcoTooltip
      data-testid="DicomTooltip-OrmcoTooltip"
      title={
        <>
          <Typography
            data-testid="DicomTooltip-TooltipText"
            margin="0 0 10px"
            variant="small"
            color="white"
          >
            {cloudFrontScans.scanner_description__dicom__popup}
          </Typography>

          {getPresignedLink && (
            <TooltipLinkBox data-testid="DicomTooltip-TooltipLinkBox">
              <ButtonLink
                type="link"
                href={openLinkUrl}
                target="_blank"
                data-testid="DicomTooltip-ButtonLink"
              >
                <FormattedMessage id="truGen.learnMore" />
              </ButtonLink>
            </TooltipLinkBox>
          )}
        </>
      }
      overlayInnerStyle={{
        textAlign: 'justify',
        padding: '11px',
      }}
    >
      <HelpIcon data-testid="DicomTooltip-HelpIcon" />
    </OrmcoTooltip>
  ) : null
}

export { DicomTooltip }
