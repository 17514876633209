import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Popover, Button } from 'antd'
import { PopoverProps } from 'antd/es/popover'
import { PopoverContent } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/Popover/PopoverContent'
import { ViewFileModal } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/ViewFileModal/ViewFileModal'
import { caseListActions, caseListSelectors } from 'app/components/routes/CaseList/logic'
import { SdsCloseOutlined } from 'app/components/ui/Icons/sds'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { HoldDetailsType } from 'app/core/domain/Case'
import styled from 'styled-components'

export interface HoldDetailsProps {
  caseId: string
  orderId: string
  holdDetails: HoldDetailsType
}

export interface HoldReasonPopoverPropsType extends HoldDetailsProps {
  statusText: string
}

const HoldReasonPopover: React.FC<PopoverProps & HoldReasonPopoverPropsType> = (props) => {
  const { children, className, caseId, orderId, statusText, holdDetails } = props
  const isModalOpen = useSelector(caseListSelectors.isHoldCaseImageModalOpen())
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  useEffect(() => {
    if (isPopoverOpen) {
      document.body.click()
      document.body.addEventListener('click', closePopover)
    }
    return () => {
      document.body.removeEventListener('click', closePopover)
    }
  }, [isPopoverOpen])

  const dispatch = useDispatch()

  const handleCloseModal = () => {
    dispatch(caseListActions.closeHoldCaseModal())
  }

  const handleHoldReasonPopoverClick = (event: React.MouseEvent) => event.stopPropagation()

  const togglePopoverVisible = () => setIsPopoverOpen(!isPopoverOpen)
  const closePopover = () => setIsPopoverOpen(false)

  return (
    <div onClick={handleHoldReasonPopoverClick}>
      <Popover
        open={isPopoverOpen}
        overlayClassName={className}
        placement="rightTop"
        title={
          <Box justify="flex-start">
            <Box justify="flex-start" flexGrow="1">
              <Typography component="span" color="base700" textFontWeight="600">
                {statusText}
              </Typography>
            </Box>
            <CloseBtn type="text" onClick={closePopover}>
              <SdsCloseOutlined width="15px" color="base600" />
            </CloseBtn>
          </Box>
        }
        content={<PopoverContent caseId={caseId} orderId={orderId} holdDetails={holdDetails} />}
        trigger="click"
      >
        <span onClick={togglePopoverVisible}>{children}</span>
      </Popover>
      <ViewFileModal
        isOpen={isPopoverOpen && isModalOpen}
        onClose={handleCloseModal}
        afterClose={handleCloseModal}
        data-testid="HoldReasonPopover-ViewFileModal"
      />
    </div>
  )
}

const HoldReasonPopoverStyled = styled(HoldReasonPopover)`
  .ant-popover-content {
    margin-top: -12px;
    margin-left: -10px;

    font-size: 13px;
    line-height: 18px;
  }

  .ant-popover-inner-content {
    padding: 0 8px 8px 8px;

    width: 266px;
  }

  .ant-popover-title {
    border: 0;
  }

  .ant-popover-title {
    padding: 8px 8px 5px 8px;
  }
`

const CloseBtn = styled(Button)`
  padding: 0;

  height: 15px;
  border: 0;
`

export { HoldReasonPopoverStyled as HoldReasonPopover }
