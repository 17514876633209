function createForm(formValues: { [index: string]: string | undefined }): FormData {
  const formData = new FormData()

  Object.keys(formValues).forEach((key) => {
    const fieldValue = formValues[key]

    if (fieldValue) {
      formData.set(key, fieldValue)
    }
  })
  return formData
}

export { createForm }
