import { Nullable } from 'app/core/types/utils'

interface GetMappedClinicalPreferencesHooksProps {
  buttons: string
  distalHooks?: Nullable<string>
  mesialHooks?: Nullable<string>
  integratedHooks?: Nullable<string>
}

const getMappedClinicalPreferencesHooks = ({
  buttons,
  distalHooks,
  mesialHooks,
  integratedHooks,
}: GetMappedClinicalPreferencesHooksProps) => {
  let mappedButtons: number[] = []
  let mappedHooks: number[] = []
  let mappedIntegratedHooks: number[] = []

  if (distalHooks) {
    distalHooks
      .split(',')
      .forEach((distalHook) => distalHook && mappedHooks.push(Number(distalHook)))
  }

  if (mesialHooks) {
    mesialHooks
      .split(',')
      .forEach((mesialHook) => mesialHook && mappedHooks.push(Number(mesialHook)))
  }

  if (integratedHooks) {
    integratedHooks
      .split(',')
      .forEach(
        (integratedHook) => integratedHook && mappedIntegratedHooks.push(Number(integratedHook)),
      )
  }

  buttons.split(',').forEach((button) => button && mappedButtons.push(Number(button)))

  return {
    buttons: mappedButtons,
    hooks: mappedHooks,
    integratedHooks: mappedIntegratedHooks,
  }
}

export { getMappedClinicalPreferencesHooks }
