import { TreatmentAction } from './TreatmentAction'

interface MidlinePreferencesPrimary {
  actionPrimary: TreatmentAction
  directionPrimary: DirectionPrimary
  distancePrimaryLower: MidlineDistance
  distancePrimaryUpper: MidlineDistance
  moveSpecificAmounts: boolean
}

enum DirectionPrimary {
  'UPPER_TO_LOWER' = 'UPPER_TO_LOWER',
  'LOWER_TO_UPPER' = 'LOWER_TO_UPPER',
}

enum MidlineDistance {
  'RIGHT_ONE_TO_TWO_MM' = 'RIGHT_ONE_TO_TWO_MM',
  'LEFT_ONE_TO_TWO_MM' = 'LEFT_ONE_TO_TWO_MM',
  'RIGHT_TWO_PLUS_MM' = 'RIGHT_TWO_PLUS_MM',
  'LEFT_TWO_PLUS_MM' = 'LEFT_TWO_PLUS_MM',
  'MAINTAIN' = 'MAINTAIN',
}

export { MidlinePreferencesPrimary, MidlineDistance, DirectionPrimary }
