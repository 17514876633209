import { DoctorCaseListQueryUrlParams } from 'app/components/routes/CaseList/CaseList'
import { DoctorCaseCount, DoctorCaseTabs } from 'app/components/routes/CaseList/DoctorCaseCount'
import { unarchiveModalActions } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/UnarchiveModal/logic'
import { doctorNoteActions } from 'app/components/routes/CaseList/DoctorCaseList/DoctorCards/DoctorNote/logic'
import { Case } from 'app/core/domain/Case'
import { CaseField } from 'app/core/domain/CaseField'
import { CaseSorting } from 'app/core/domain/CaseSorting'
import { CaseListParams } from 'app/core/domain/Http/CaseListParams'
import { CaseListResponse } from 'app/core/domain/Http/CaseListResponse'
import { oppositeSortOrder, SortOrder } from 'app/core/domain/SortOrder'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

import { caseFilterActions } from './DoctorCaseList/CaseFilter/logic'

const emptyCaseFilterCounters: DoctorCaseCount = {
  [DoctorCaseTabs.actionRequired]: 0,
  [DoctorCaseTabs.withOrmco]: 0,
  [DoctorCaseTabs.shipped]: 0,
  [DoctorCaseTabs.archived]: 0,
}

enum ListType {
  'SEARCH' = 'SEARCH',
  'TAB' = 'TAB',
  'QUEUE' = 'QUEUE',
}

interface CaseListState {
  listType: ListType
  // case list counters
  doctorCaseFiltersCounters: DoctorCaseCount
  doctorActiveCaseTab: keyof typeof DoctorCaseTabs
  isCaseFiltersCountLoading: boolean
  caseFiltersCountLoadingError?: boolean
  // case list
  caseList: Case[]
  caseListRequestParams: CaseListParams
  isCaseListLoading: boolean
  hasMore: boolean
  caseSorting: CaseSorting
  holdCaseImageUrl: string
  holdCaseFileName: string
  isHoldCaseImageUrlLoading: boolean
  isHoldCaseImageModalOpen: boolean
  isScanBestPracticesPdfUrlLoading: boolean
}

const defaultListElementHeight = 62
const defaultCaseListParams = {
  start: 0,
  size: Math.round(window.innerHeight / defaultListElementHeight),
}
const INITIAL_STATE: CaseListState = {
  doctorActiveCaseTab: DoctorCaseTabs.actionRequired,
  doctorCaseFiltersCounters: emptyCaseFilterCounters,
  isCaseFiltersCountLoading: false,
  caseList: [],
  isCaseListLoading: false,
  caseListRequestParams: { ...defaultCaseListParams },
  listType: ListType.TAB,
  hasMore: true,
  caseSorting: new CaseSorting(),
  holdCaseImageUrl: '',
  holdCaseFileName: '',
  isHoldCaseImageUrlLoading: false,
  isHoldCaseImageModalOpen: false,
  isScanBestPracticesPdfUrlLoading: false,
}

const caseListActions = {
  // counters actions
  countRequestFinished: createAction('@CASE_LIST/COUNT_REQUEST_FINISHED')<DoctorCaseCount>(),
  countRequestFailed: createAction('@CASE_LIST/COUNT_REQUEST_FAILED')<{ message: string }>(),
  doctorActiveCaseTabClicked: createAction('@CASE_LIST/DOCTOR_ACTIVE_CASE_TAB_REQUESTED')<
    keyof typeof DoctorCaseTabs
  >(),
  // list actions
  caseListMounted: createAction('@CASE_LIST/CASE_LIST_MOUNTED')<DoctorCaseListQueryUrlParams>(),
  caseListRequestFinished: createAction(
    '@CASE_LIST/CASE_LIST_REQUEST_FINISHED',
  )<CaseListResponse>(),
  caseListRequestFailed: createAction('@CASE_LIST/CASE_LIST_REQUEST_FAILED')<{ message: string }>(),
  loadMoreCasesReached: createAction('@CASE_LIST/LOAD_MORE_CASES_REACHED')(),
  searchCasesInputChanged: createAction('@CASE_LIST/SEARCH_CASE_INPUT_CHANGED')<string>(),
  clearSearchButtonClicked: createAction('@CASE_LIST/SEARCH_CLEAR_REQUESTED')(),
  openApproverButtonClicked: createAction('@CASE_LIST/OPEN_APPROVER_BUTTON_CLICKED')<{
    case: Case
    isWeb: boolean
  }>(),
  trackCaseButtonClicked: createAction('@CASE_LIST/TRACK_CASE_BUTTON_CLICKED')<{ case: Case }>(),
  sortButtonClicked: createAction('@CASE_LIST/SORT_BUTTON_CLICKED')<{ headerName: CaseField }>(),
  supportButtonClicked: createAction('@CASE_LIST/SUPPORT_BUTTON_CLICKED')(),
  getHoldCaseImageUrl: createAction('@CASE_LIST/GET_HOLD_CASE_IMAGE_URL')<{
    caseId: Case['caseId']
    uid: string
  }>(),
  getHoldCaseImageUrlRequestStarted: createAction(
    '@CASE_LIST/GET_HOLD_CASE_IMAGE_URL_REQUEST_STARTED',
  )<{ fileName: string }>(),
  getHoldCaseImageUrlRequestFinished: createAction(
    '@CASE_LIST/GET_HOLD_CASE_IMAGE_URL_REQUEST_FINISHED',
  )<{ fileUrl: string }>(),
  getHoldCaseImageUrlRequestFailed: createAction(
    '@CASE_LIST/GET_HOLD_CASE_IMAGE_URL_REQUEST_FAILED',
  )(),
  closeHoldCaseModal: createAction('@CASE_LIST/CLOSE_HOLD_CASE_MODAL')(),
  getScanBestPracticesPdfUrl: createAction('@CASE_LIST/GET_SCAN_BEST_PRACTICES_PDF_URL')(),
  getScanBestPracticesPdfUrlRequestStarted: createAction(
    '@CASE_LIST/GET_SCAN_BEST_PRACTICES_PDF_URL_STARTED',
  )(),
  getScanBestPracticesPdfUrlRequestFinished: createAction(
    '@CASE_LIST/GET_SCAN_BEST_PRACTICES_PDF_URL_FINISHED',
  )<{ fileUrl: string }>(),
  getScanBestPracticesPdfUrlRequestFailed: createAction(
    '@CASE_LIST/GET_SCAN_BEST_PRACTICES_PDF_URL_FAILED',
  )(),
}

type CaseListActions = ActionType<
  | typeof caseListActions
  | Pick<typeof unarchiveModalActions, 'unarchiveRequestReceived'>
  | Pick<typeof doctorNoteActions, 'submitRequestComplete'>
  | Pick<typeof caseFilterActions, 'caseFilterUsersCheckBoxClicked'>
  | Pick<typeof caseFilterActions, 'caseFilterAllPatientsButtonClicked'>
  | Pick<typeof caseFilterActions, 'caseFilterMyPatientsButtonClicked'>
  | Pick<typeof caseFilterActions, 'caseFilterProductGroupSelected'>
>
const caseListReducer = createReducer<CaseListState, CaseListActions>(INITIAL_STATE)
  // counters
  .handleAction(caseListActions.countRequestFinished, (state, action) => ({
    ...state,
    doctorCaseFiltersCounters: action.payload,
    isCaseFiltersCountLoading: false,
    caseFiltersCountLoadingError: false,
  }))
  .handleAction(caseListActions.countRequestFailed, (state) => ({
    ...state,
    caseFiltersCountLoadingError: true,
    isCaseFiltersCountLoading: false,
  }))
  .handleAction(caseListActions.doctorActiveCaseTabClicked, (state, action) => ({
    ...state,
    caseListRequestParams: { ...defaultCaseListParams },
    doctorActiveCaseTab: action.payload,
    isCaseListLoading: true,
    listType: ListType.TAB,
    caseList: [],
    caseSorting: CaseSorting.getDefaultSortNameForTab(action.payload),
  }))
  // case list
  .handleAction(caseListActions.caseListMounted, (state, action) => {
    let listType: ListType | undefined

    if (action.payload && action.payload.tab) listType = ListType.TAB
    else if (action.payload && action.payload.search) listType = ListType.SEARCH

    return {
      ...INITIAL_STATE,
      isCaseFiltersCountLoading: true,
      isCaseListLoading: true,
      doctorActiveCaseTab:
        (action.payload && action.payload.tab) ||
        state.doctorActiveCaseTab ||
        DoctorCaseTabs.actionRequired,
      listType: listType || state.listType,
      caseListRequestParams: {
        ...defaultCaseListParams,
        ...(listType === ListType.SEARCH ? { search: action.payload.search } : {}),
      },
    }
  })
  .handleAction(caseListActions.caseListRequestFinished, (state, action) => ({
    ...state,
    caseList: state.caseList.concat(action.payload.cases),
    isCaseListLoading: false,
    hasMore: action.payload.cases.length > 0,
  }))
  .handleAction(caseListActions.caseListRequestFailed, (state) => ({
    ...state,
    isCaseListLoading: false,
    hasMore: false,
  }))
  .handleAction(caseListActions.searchCasesInputChanged, (state, action?) => ({
    ...state,
    caseListRequestParams: { ...defaultCaseListParams, search: action.payload },
    listType: ListType.SEARCH,
    caseList: [],
    isCaseListLoading: true,
    caseSorting: new CaseSorting(CaseField.CREATION_DATE),
  }))
  .handleAction(caseListActions.clearSearchButtonClicked, (state) => ({
    ...state,
    caseListRequestParams: { ...defaultCaseListParams },
    listType: ListType.TAB,
    caseList: [],
    isCaseListLoading: true,
    caseSorting: CaseSorting.getDefaultSortNameForTab(state.doctorActiveCaseTab),
  }))
  .handleAction(caseListActions.loadMoreCasesReached, (state) => ({
    ...state,
    isCaseListLoading: true,
    caseListRequestParams: {
      ...state.caseListRequestParams,
      start: (state.caseListRequestParams.start || 0) + (state.caseListRequestParams.size || 10),
    },
  }))
  .handleAction(doctorNoteActions.submitRequestComplete, (state, action) => {
    const caseList = state.caseList.map((caseElement) => {
      const res = caseElement

      if (caseElement.caseId === action.payload.caseId) {
        res.notes = action.payload.notes
      }
      return res
    })

    return {
      ...state,
      caseList,
    }
  })
  .handleAction(unarchiveModalActions.unarchiveRequestReceived, (state) => ({
    ...state,
    caseList: [],
    isCaseListLoading: true,
  }))
  .handleAction(caseListActions.sortButtonClicked, (state, action) => {
    const newSortName = action.payload.headerName
    const newSortOrder =
      newSortName === state.caseSorting.sortName
        ? oppositeSortOrder(state.caseSorting.sortOrder)
        : SortOrder.DESC

    return {
      ...state,
      isCaseListLoading: true,
      caseList: [],
      caseSorting: new CaseSorting(newSortName, newSortOrder),
      caseListRequestParams: { ...state.caseListRequestParams, ...defaultCaseListParams },
    }
  })
  .handleAction([caseListActions.getHoldCaseImageUrlRequestStarted], (state, action) => ({
    ...state,
    holdCaseImageUrl: '',
    holdCaseFileName: action.payload.fileName,
    isHoldCaseImageUrlLoading: true,
    isHoldCaseImageModalOpen: true,
  }))
  .handleAction([caseListActions.getHoldCaseImageUrlRequestFinished], (state, action) => ({
    ...state,
    holdCaseImageUrl: action.payload.fileUrl,
    isHoldCaseImageUrlLoading: false,
  }))
  .handleAction([caseListActions.getHoldCaseImageUrlRequestFailed], (state) => ({
    ...state,
    holdCaseImageUrl: '',
    isHoldCaseImageUrlLoading: false,
  }))
  .handleAction([caseListActions.closeHoldCaseModal], (state) => ({
    ...state,
    holdCaseImageUrl: '',
    holdCaseFileName: '',
    isHoldCaseImageModalOpen: false,
  }))
  .handleAction([caseListActions.getScanBestPracticesPdfUrlRequestStarted], (state) => ({
    ...state,
    isScanBestPracticesPdfUrlLoading: true,
  }))
  .handleAction([caseListActions.getScanBestPracticesPdfUrlRequestFinished], (state) => ({
    ...state,
    isScanBestPracticesPdfUrlLoading: false,
  }))
  .handleAction([caseListActions.getScanBestPracticesPdfUrlRequestFailed], (state) => ({
    ...state,
    isScanBestPracticesPdfUrlLoading: false,
  }))
  .handleAction(
    [
      caseFilterActions.caseFilterProductGroupSelected,
      caseFilterActions.caseFilterUsersCheckBoxClicked,
      caseFilterActions.caseFilterAllPatientsButtonClicked,
      caseFilterActions.caseFilterMyPatientsButtonClicked,
    ],
    (state) => ({
      ...state,
      caseList: [],
      isCaseListLoading: true,
      caseListRequestParams: { ...state.caseListRequestParams, ...defaultCaseListParams },
    }),
  )

const caseListSelectors = {
  getDoctorCaseFiltersCounters: () => (state: RootState) =>
    state.caseList.doctorCaseFiltersCounters,
  getCaseList: () => (state: RootState) => state.caseList.caseList,
  getCaseListParams:
    () =>
    (state: RootState): CaseListParams =>
      state.caseList.caseListRequestParams,
  getDoctorActiveCaseFilter: () => (state: RootState) => state.caseList.doctorActiveCaseTab,
  getSearchText: () => (state: RootState) => state.caseList.caseListRequestParams.search,
  getListType:
    () =>
    (state: RootState): ListType =>
      state.caseList.listType,
  getCaseSorting: () => (state: RootState) => state.caseList.caseSorting,
  isCaseListLoading: () => (state: RootState) => state.caseList.isCaseListLoading,
  hasMoreCaseList: () => (state: RootState) => state.caseList.hasMore,
  holdCaseImageUrl: () => (state: RootState) => state.caseList.holdCaseImageUrl,
  holdCaseFileName: () => (state: RootState) => state.caseList.holdCaseFileName,
  isHoldCaseImageUrlLoading: () => (state: RootState) => state.caseList.isHoldCaseImageUrlLoading,
  isHoldCaseImageModalOpen: () => (state: RootState) => state.caseList.isHoldCaseImageModalOpen,
  isScanBestPracticesPdfUrlLoading: () => (state: RootState) =>
    state.caseList.isScanBestPracticesPdfUrlLoading,
}

export {
  CaseListState,
  CaseListActions,
  caseListActions,
  caseListReducer,
  caseListSelectors,
  ListType,
  emptyCaseFilterCounters,
}
