import React, { useCallback, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { Divider, Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { TreatmentAction } from 'app/components/routes/CaseWizard/steps/CasePrescription/TreatmentAction'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { OrmcoSelect } from 'app/components/ui/Form/OrmcoSelect'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ContextThemeProps, ThemeContext, ThemeType } from 'app/components/ui/Theme/ThemeContext'
import { Vertical } from 'app/components/ui/Vertical'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import {
  DirectionPrimary,
  MidlineDistance,
  MidlinePreferencesPrimary,
} from 'app/core/domain/MidlinePreferencesPrimary'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import styled, { useTheme } from 'styled-components'

import { ThemedBox } from './ResolveCrowding/ResolveCrowding'

const PROPERTY_KEY = 'primaryCasePreferences.midlinePreferencesPrimary'

const Midline = () => {
  const form = useFormContext<CaseDraft>()
  const theme = useTheme()
  const treatArches = form.getValues('primaryCasePreferences.treatArches')
  const midlinePreferences = form.getValues(PROPERTY_KEY)
  const { Option } = Select
  const changeRadio = useCallback(
    (res: MidlinePreferencesPrimary) => {
      form.setValue(PROPERTY_KEY, res)
    },
    [form],
  )
  const contextTheme = useContext(ThemeContext)

  return (
    <div>
      <OrmcoFormItemVertical
        label={
          <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.midline.label" />
        }
        hasFeedback
        data-testid="Midline"
      >
        <Vertical>
          <OrmcoRadio
            onChange={() =>
              changeRadio({
                actionPrimary: TreatmentAction.MAINTAIN,
                directionPrimary:
                  treatArches === TreatArches.UPPER_ONLY
                    ? DirectionPrimary.UPPER_TO_LOWER
                    : DirectionPrimary.LOWER_TO_UPPER,
                distancePrimaryLower: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
                distancePrimaryUpper: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
                moveSpecificAmounts: false,
              })
            }
            checked={
              !midlinePreferences || midlinePreferences.actionPrimary === TreatmentAction.MAINTAIN
            }
            data-testid="Midline-Maintain"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.maintain" />
            </Typography>
          </OrmcoRadio>
          <OrmcoRadio
            onChange={() =>
              changeRadio({
                actionPrimary: TreatmentAction.CORRECT,
                directionPrimary:
                  treatArches === TreatArches.UPPER_ONLY
                    ? DirectionPrimary.UPPER_TO_LOWER
                    : DirectionPrimary.LOWER_TO_UPPER,
                distancePrimaryLower: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
                distancePrimaryUpper: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
                moveSpecificAmounts: false,
              })
            }
            checked={midlinePreferences?.actionPrimary === TreatmentAction.CORRECT}
            data-testid="Midline-Correct"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.midline.correct" />
            </Typography>
          </OrmcoRadio>
          {midlinePreferences?.actionPrimary === TreatmentAction.CORRECT && (
            <ThemedBox data-testid="MidlineCorrectSection" contextTheme={contextTheme}>
              <Vertical>
                <OrmcoRadio
                  onChange={() =>
                    changeRadio({
                      ...midlinePreferences,
                      ...{
                        directionPrimary: DirectionPrimary.UPPER_TO_LOWER,
                        distancePrimaryLower: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
                      },
                    })
                  }
                  checked={
                    (midlinePreferences?.directionPrimary === DirectionPrimary.UPPER_TO_LOWER &&
                      treatArches !== TreatArches.LOWER_ONLY) ||
                    treatArches === TreatArches.UPPER_ONLY
                  }
                  disabled={!midlinePreferences || treatArches === TreatArches.LOWER_ONLY}
                  data-testid="Midline-UpperToLower"
                >
                  <Typography component="span">
                    <FormattedMessage id="caseWizard.casePrescription.midline.matchUpperToLower" />
                  </Typography>
                </OrmcoRadio>

                <OrmcoRadio
                  onChange={() =>
                    changeRadio({
                      ...midlinePreferences,
                      ...{
                        directionPrimary: DirectionPrimary.LOWER_TO_UPPER,
                        distancePrimaryUpper: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
                      },
                    })
                  }
                  checked={
                    (midlinePreferences?.directionPrimary === DirectionPrimary.LOWER_TO_UPPER &&
                      treatArches !== TreatArches.UPPER_ONLY) ||
                    treatArches === TreatArches.LOWER_ONLY
                  }
                  disabled={!midlinePreferences || treatArches === TreatArches.UPPER_ONLY}
                  data-testid="Midline-LowerToUpper"
                >
                  <Typography component="span">
                    <FormattedMessage id="caseWizard.casePrescription.midline.matchLowerToUpper" />
                  </Typography>
                </OrmcoRadio>
              </Vertical>
              <Divider />
              <Box justify="flex-start">
                <OrmcoCheckbox
                  onChange={(selected) => {
                    if (selected.target.checked) {
                      changeRadio({
                        ...midlinePreferences,
                        ...{
                          moveSpecificAmounts: selected.target.checked,
                        },
                      })
                    } else {
                      changeRadio({
                        ...midlinePreferences,
                        ...{
                          distancePrimaryLower: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
                          distancePrimaryUpper: MidlineDistance.RIGHT_ONE_TO_TWO_MM,
                          moveSpecificAmounts: selected.target.checked,
                        },
                      })
                    }
                  }}
                  checked={midlinePreferences?.moveSpecificAmounts === true}
                  data-testid="Midline-MoveSpecificAmounts"
                >
                  <Typography component="span">
                    <FormattedMessage id="caseWizard.casePrescription.midline.moveSpecAmmount" />
                  </Typography>
                </OrmcoCheckbox>
              </Box>

              <Vertical>
                <Typography
                  component="label"
                  variant="small"
                  textFontWeight="600"
                  color="base900"
                  padding="15px 0 3px 15px"
                  data-testid="Midline-Heading-Upper"
                >
                  <FormattedMessage id="caseWizard.casePrescription.midline.upper" />
                </Typography>
                <MidlineSelect
                  contextTheme={contextTheme}
                  defaultValue={midlinePreferences.distancePrimaryUpper}
                  value={
                    treatArches === TreatArches.LOWER_ONLY
                      ? MidlineDistance.RIGHT_ONE_TO_TWO_MM
                      : midlinePreferences.distancePrimaryUpper
                  }
                  className="selectDistance"
                  disabled={
                    treatArches === TreatArches.LOWER_ONLY ||
                    midlinePreferences?.moveSpecificAmounts === false
                  }
                  style={{ width: 300 }}
                  onChange={(selected: any) =>
                    changeRadio({
                      ...midlinePreferences,
                      ...{
                        distancePrimaryUpper: selected,
                      },
                    })
                  }
                  data-testid="Midline-Select-Upper"
                >
                  <Option value={MidlineDistance.RIGHT_ONE_TO_TWO_MM}>
                    <FormattedMessage id="caseWizard.casePrescription.midline.right12mm" />
                  </Option>
                  <Option value={MidlineDistance.LEFT_ONE_TO_TWO_MM}>
                    <FormattedMessage id="caseWizard.casePrescription.midline.left12mm" />
                  </Option>
                  <Option value={MidlineDistance.RIGHT_TWO_PLUS_MM}>
                    <FormattedMessage id="caseWizard.casePrescription.midline.right2pmm" />
                  </Option>
                  <Option value={MidlineDistance.LEFT_TWO_PLUS_MM}>
                    <FormattedMessage id="caseWizard.casePrescription.midline.left2pmm" />
                  </Option>
                  <Option value={MidlineDistance.MAINTAIN}>
                    <FormattedMessage id="caseWizard.casePrescription.midline.maintain" />
                  </Option>
                </MidlineSelect>
              </Vertical>

              <Vertical>
                <Typography
                  component="label"
                  variant="small"
                  textFontWeight="600"
                  color="base900"
                  padding="15px 0 3px 15px"
                  data-testid="Midline-Heading-Lower"
                >
                  <FormattedMessage id="caseWizard.casePrescription.midline.lower" />
                </Typography>

                <MidlineSelect
                  contextTheme={contextTheme}
                  disabled={
                    treatArches === TreatArches.UPPER_ONLY ||
                    midlinePreferences?.moveSpecificAmounts === false
                  }
                  defaultValue={midlinePreferences.distancePrimaryLower}
                  value={
                    treatArches === TreatArches.UPPER_ONLY
                      ? MidlineDistance.RIGHT_ONE_TO_TWO_MM
                      : midlinePreferences.distancePrimaryLower
                  }
                  style={{
                    width: 300,
                    backgroundColor: theme.colors.primary10,
                  }}
                  onChange={(selected: any) =>
                    changeRadio({
                      ...midlinePreferences,
                      ...{
                        distancePrimaryLower: selected,
                      },
                    })
                  }
                  data-testid="Midline-Select-Lower"
                >
                  <Option value={MidlineDistance.RIGHT_ONE_TO_TWO_MM}>
                    <FormattedMessage id="caseWizard.casePrescription.midline.right12mm" />
                  </Option>
                  <Option value={MidlineDistance.LEFT_ONE_TO_TWO_MM}>
                    <FormattedMessage id="caseWizard.casePrescription.midline.left12mm" />
                  </Option>
                  <Option value={MidlineDistance.RIGHT_TWO_PLUS_MM}>
                    <FormattedMessage id="caseWizard.casePrescription.midline.right2pmm" />
                  </Option>
                  <Option value={MidlineDistance.LEFT_TWO_PLUS_MM}>
                    <FormattedMessage id="caseWizard.casePrescription.midline.left2pmm" />
                  </Option>
                  <Option value={MidlineDistance.MAINTAIN}>
                    <FormattedMessage id="caseWizard.casePrescription.midline.maintain" />
                  </Option>
                </MidlineSelect>
              </Vertical>
            </ThemedBox>
          )}
        </Vertical>
      </OrmcoFormItemVertical>
    </div>
  )
}

const MidlineSelect = styled(OrmcoSelect)<SelectProps<any> & ContextThemeProps>`
  &&& {
    width: 300px;

    &.ant-select-open {
      z-index: ${({ theme: styledTheme }) => styledTheme.zIndex.dropdown};
    }

    .ant-select-selection-item {
      padding-left: 20px;
    }

    .ant-select-selector {
      background-color: ${({ theme: styledTheme, contextTheme }) =>
        contextTheme === ThemeType.BOOTSTRAP
          ? styledTheme.colors.base100
          : styledTheme.colors.primary10};
    }
  }
`

export { Midline }
