import { S3UploadCredentials } from 'app/core/domain/S3UploadCredentials'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface UploadDicomState {
  selectedFile: File | undefined
  errorFile: string
  dicomCredentials: S3UploadCredentials
  dicomPdfLink: string
  dicomUploadInProgress: string
}

const initialDicomCredentials: S3UploadCredentials = {
  creds: {
    accessKeyId: '',
    secretAccessKey: '',
    sessionToken: '',
    expiration: '',
  },
  path: '',
  bucketName: '',
  bucketEndpoint: '',
  encryptionKey: '',
}

const INITIAL_STATE: UploadDicomState = {
  selectedFile: undefined,
  errorFile: '',
  dicomCredentials: initialDicomCredentials,
  dicomPdfLink: '',
  dicomUploadInProgress: '',
}

const uploadDicomActions = {
  saveDicomZipFile: createAction('@UPLOAD_DICOM/SAVE_SELECTED_ZIP_FILE')<File | undefined>(),
  saveErrorFileName: createAction('@UPLOAD_DICOM/SAVE_ERROR_FILE_NAME')<string>(),
  getTempCredentialsRequest: createAction('@UPLOAD_DICOM/GET_TEMP_CREDENTIALS_REQUEST')<{
    caseId: string
  }>(),
  getTempCredentialsReceived: createAction(
    '@UPLOAD_DICOM/GET_TEMP_CREDENTIALS_RECEIVED',
  )<S3UploadCredentials>(),
  getTempCredentialsFailed: createAction('@UPLOAD_DICOM/GET_TEMP_CREDENTIALS_FAILED')<{
    message: string
  }>(),
  getDicomPdfRequested: createAction('@UPLOAD_DICOM/GET_DICOM_PDF_REQUESTED')<{
    getPresignedLink: string
  }>(),
  getDicomPdfReceived: createAction('@UPLOAD_DICOM/GET_DICOM_PDF_RECEIVED')<string>(),
  dicomPdfUnmounted: createAction('@UPLOAD_DICOM/DICOM_TERMS_UNMOUNTED')(),
  getDicomPdfFailed: createAction('@UPLOAD_DICOM/GET_DICOM_PDF_FAILED')<{ message: string }>(),
  setDicomFileUploadStatusReceived: createAction(
    '@UPLOAD_DICOM/SET_DICOM_FILE_UPLOAD_STATUS_RECEIVED',
  )<string>(),
  dicomAuditRequested: createAction('@UPLOAD_DICOM/DICOM_AUDIT_REQUESTED')<{ caseId: string }>(),
  dicomAuditFailed: createAction('@UPLOAD_DICOM/DICOM_AUDIT_FAILED')(),
  dicomAuditReceived: createAction('@UPLOAD_DICOM/DICOM_AUDIT_RECEIVED')(),
}

type UploadDicomActions = ActionType<typeof uploadDicomActions>
const uploadDicomReducer = createReducer<UploadDicomState, UploadDicomActions>(INITIAL_STATE)
  .handleAction(uploadDicomActions.saveDicomZipFile, (state, action) => ({
    ...state,
    selectedFile: action.payload,
  }))
  .handleAction(uploadDicomActions.saveErrorFileName, (state, action) => ({
    ...state,
    errorFile: action.payload,
  }))
  .handleAction(uploadDicomActions.getTempCredentialsReceived, (state, action) => ({
    ...state,
    dicomCredentials: action.payload,
  }))
  .handleAction(uploadDicomActions.getTempCredentialsFailed, (state) => ({
    ...state,
    dicomCredentials: initialDicomCredentials,
  }))
  .handleAction(
    [uploadDicomActions.getDicomPdfFailed, uploadDicomActions.dicomPdfUnmounted],
    (state) => ({
      ...state,
      dicomPdfLink: '',
    }),
  )
  .handleAction(uploadDicomActions.getDicomPdfReceived, (state, action) => ({
    ...state,
    dicomPdfLink: action.payload,
  }))
  .handleAction([uploadDicomActions.setDicomFileUploadStatusReceived], (state, action) => ({
    ...state,
    dicomUploadInProgress: action.payload,
  }))

const uploadDicomSelectors = {
  getDicomSelectedFile: () => (state: RootState) => state.uploadDicom.selectedFile,
  getDicomErrorFile: () => (state: RootState) => state.uploadDicom.errorFile,
  getDicomTempCredentials: () => (state: RootState) => state.uploadDicom.dicomCredentials,
  getDicomPdfLink: () => (state: RootState) => state.uploadDicom.dicomPdfLink,
  getDicomFileUploadStatus: () => (state: RootState) => state.uploadDicom.dicomUploadInProgress,
}

export {
  uploadDicomActions,
  uploadDicomReducer,
  uploadDicomSelectors,
  UploadDicomActions,
  UploadDicomState,
}
