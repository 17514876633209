import { EnvironmentParams } from 'app/core/domain/EnvironmentParams'

const { ALIGNER_WEB_URL, SSO_URL } = EnvironmentParams

const SiteMap = {
  homePage: () => '/homePage',
  legacyHomePage: () => `${ALIGNER_WEB_URL}/homePage`,
  doctors: () => `${ALIGNER_WEB_URL}/doctorListPage`,
  profile: () => `${ALIGNER_WEB_URL}/userProfileDetailsPage`,
  adminReport: () => `${ALIGNER_WEB_URL}/adminReport`,
  qualityRatingReport: () => `${ALIGNER_WEB_URL}/qualityRatingReport`,
  employees: () => `${ALIGNER_WEB_URL}/userPage`,
  settings: () => `${ALIGNER_WEB_URL}/settings`,
  tShapeConfig: () => `${ALIGNER_WEB_URL}/tShapeConfiguration`,
  webContent: () => `${ALIGNER_WEB_URL}/webContent`,
  support: () => `${ALIGNER_WEB_URL}/dynamicPageSupport`,
  supportCustomer: () => `${ALIGNER_WEB_URL}/support`,
  logout: () => `${SSO_URL}/sso/logout`,
  clearImpersonation: () => `${SSO_URL}/sso/logout/impersonate`,
  patiensDetails: (caseId: string) => `${ALIGNER_WEB_URL}/getPatientDetails?CaseId=${caseId}`,
  DOCTORS: {
    addPatientDetails: () => `${ALIGNER_WEB_URL}/addPatientDetails`,
    support: () => `${ALIGNER_WEB_URL}/support`,
    downloadApprover: () => `${ALIGNER_WEB_URL}/downloadApprover`,
    education: () => `${ALIGNER_WEB_URL}/webContent`,
    profile: () => `${ALIGNER_WEB_URL}/myPreferences`,
    clinicalPreferences: () => `${ALIGNER_WEB_URL}/clinicalPreferences`,
  },
  OPERATORS: {
    clinicalPreferences: () => `${ALIGNER_WEB_URL}/doctorClinicalPreferencePage`,
  },
} as const

export { SiteMap, ALIGNER_WEB_URL }
