import React from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Tooltip } from 'antd'
import { caseListActions, caseListSelectors } from 'app/components/routes/CaseList/logic'
import { SdsEyeOutlined, SdsPaperClipOutlined, SdsNoteOutlined } from 'app/components/ui/Icons/sds'
import { Box } from 'app/components/ui/SDS/common/Box'
import { OverflowTooltip } from 'app/components/ui/SDS/common/OverflowTooltip'
import { HoldReasonCodes } from 'app/core/domain/Case'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'

import { HoldDetailsProps } from './HoldReasonPopover'
import {
  HoldReasonComment,
  HoldReasonPhotosArea,
  PhotoName,
  PhotoNameLabel,
  PhotoItem,
  LinkButton,
  LinkButtonText,
  EditButton,
} from './styles'

const PopoverContent: React.FC<HoldDetailsProps> = (props) => {
  const {
    caseId,
    orderId,
    holdDetails: { reasonCode, comment, files },
  } = props

  const dispatch = useDispatch()
  const locale = useSelector(appSelectors.getLocale())
  const isLinkToPdfVisible = locale && !['zh', 'ja', 'ru'].includes(locale.split('_')[0])
  const intl = useIntl()
  const editBtnText = intl.formatMessage({ id: 'cases.list.doctor.onHoldPopover.editBtnText' })
  const linkToPdfText = intl.formatMessage({ id: 'cases.list.doctor.onHoldPopover.linkToPdfText' })
  const viewFileTooltipText = intl.formatMessage({
    id: 'cases.list.doctor.onHoldPopover.viewFileTooltipText',
  })
  const reasonCodeTitle = intl.formatMessage(
    { id: 'cases.list.doctor.onHoldPopover.holdReasonCodes' },
    { holdReasonCode: HoldReasonCodes[reasonCode] },
  )

  const isScanBestPracticesPdfUrlLoading = useSelector(
    caseListSelectors.isScanBestPracticesPdfUrlLoading(),
  )

  const patientDetailsPagePhotosScansTabLink =
    SiteMap.patiensDetails(orderId) + '&tab=#ImagesLoadTab'

  const handleEyeImgClick = (uid: string, fileName: string) => () => {
    dispatch(caseListActions.getHoldCaseImageUrlRequestStarted({ fileName }))
    dispatch(caseListActions.getHoldCaseImageUrl({ caseId, uid }))
  }

  const handleLinkToPdfClick = () => {
    if (!isScanBestPracticesPdfUrlLoading) {
      dispatch(caseListActions.getScanBestPracticesPdfUrlRequestStarted())
      dispatch(caseListActions.getScanBestPracticesPdfUrl())
    }
  }

  const tooltipOverlayInnerStyle = { marginBottom: '-8px' }

  return (
    <div>
      <div>{reasonCodeTitle}</div>
      {comment && (
        <OverflowTooltip
          placement="top"
          overlayInnerStyle={tooltipOverlayInnerStyle}
          title={comment}
        >
          <HoldReasonComment>{comment}</HoldReasonComment>
        </OverflowTooltip>
      )}
      {!!files.length && (
        <HoldReasonPhotosArea>
          {files.map(({ uid, name }) => (
            <PhotoItem key={uid}>
              <SdsPaperClipOutlined width="15px" color="base500" />
              <PhotoName>
                <OverflowTooltip
                  placement="top"
                  overlayInnerStyle={tooltipOverlayInnerStyle}
                  title={name}
                >
                  <PhotoNameLabel>{name}</PhotoNameLabel>
                </OverflowTooltip>
              </PhotoName>
              <Tooltip
                placement="top"
                overlayInnerStyle={tooltipOverlayInnerStyle}
                title={viewFileTooltipText}
              >
                <SdsEyeOutlined
                  onClick={handleEyeImgClick(uid, name)}
                  cursor="pointer"
                  width="15px"
                  color="primary500"
                />
              </Tooltip>
            </PhotoItem>
          ))}
        </HoldReasonPhotosArea>
      )}
      <div>
        <EditButton
          data-testid="edit-photo-link-btn"
          type="primary"
          href={patientDetailsPagePhotosScansTabLink}
        >
          <SdsNoteOutlined color="white" width="15px" height="15px" />
          <span>{editBtnText}</span>
        </EditButton>
      </div>
      {isLinkToPdfVisible && (
        <Box marginTop="8px">
          <LinkButton
            data-testid="scan-best-practices-link-btn"
            type="link"
            onClick={handleLinkToPdfClick}
          >
            <OverflowTooltip
              placement="top"
              overlayInnerStyle={tooltipOverlayInnerStyle}
              title={linkToPdfText}
            >
              <LinkButtonText>{linkToPdfText}</LinkButtonText>
            </OverflowTooltip>
          </LinkButton>
        </Box>
      )}
    </div>
  )
}

export { PopoverContent }
