import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Col, Row } from 'antd'
import { Entry } from 'app/components/routes/CaseWizard/CaseWizard.style'
import { ClickableTeeth } from 'app/components/routes/CaseWizard/form/Teeth/ClickableTeeth'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { TeethNums } from 'app/components/routes/CaseWizard/steps/CasePrescription/Auxiliaries/Summary/TeethNums'
import { HorizontalLine } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/HorizontalLine'
import { AuxButton, AuxIntegratedHook } from 'app/components/ui/Icons/common'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Arch, Universal } from 'app/core/domain/ArchTools'
import { CutoutType } from 'app/core/domain/CutoutType'
import { TeethImageType } from 'app/core/domain/TeethImageType'
import { Nullable } from 'app/core/types/utils'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled from 'styled-components'

import { ClinicalPreferencesPropertyCaption } from './ClinicalPreferencesPropertyCaption'
import { ClinicalPreferencesPropertyValue } from './ClinicalPreferencesPropertyValue'
import { ClinicalPreferencesTeethNumbers } from './ClinicalPreferencesTeethNumbers'
import { getIntegratedHooksOrientation, IntegratedHooksOrientationType } from './utils'

interface FeaturesCutoutPlacementsProps {
  cutoutType: CutoutType
}

const cutoutsSpecificIdsMap = {
  CLASSII: {
    propertyCaptionTestId: 'Features-PropertyCaption-Class2CutoutPlacement',
    propertyCaptionTranslationId: 'clinicalPreferences.features.class2CutoutPlacement',
    clickableTeethTestId: 'Features-Class2CutoutPlacement',
    teethNumberContainerButtonsTestId: 'caseWizard-Class2CutoutButtonsContainer',
    teethNumberButtonsTestId: 'CaseWizard-preferences-Class2CutoutButton',
    teethNumberContainerHooksTestId: 'caseWizard-Class2CutoutHooksContainer',
    teethNumberHooksTestId: 'CaseWizard-preferences-Class2CutoutHook',
    teethNumberIntegratedHooksContainerTestId: 'caseWizard-Class2CutoutIntegratedHooksContainer',
    teethNumberIntegratedHooksTestId: 'caseWizard-Class2CutoutIntegratedHooks',
  },
  CLASSIII: {
    propertyCaptionTestId: 'Features-PropertyCaption-Class3CutoutPlacement',
    propertyCaptionTranslationId: 'clinicalPreferences.features.class3CutoutPlacement',
    clickableTeethTestId: 'Features-Class3CutoutPlacement',
    teethNumberContainerButtonsTestId: 'caseWizard-Class3CutoutButtonsContainer',
    teethNumberButtonsTestId: 'CaseWizard-preferences-Class3CutoutButton',
    teethNumberContainerHooksTestId: 'caseWizard-Class3CutoutHooksContainer',
    teethNumberHooksTestId: 'CaseWizard-preferences-Class3CutoutHook',
    teethNumberIntegratedHooksContainerTestId: 'caseWizard-Class3CutoutIntegratedHooksContainer',
    teethNumberIntegratedHooksTestId: 'caseWizard-Class3CutoutIntegratedHooks',
  },
} as const

const FeaturesCutoutPlacements = ({ cutoutType }: FeaturesCutoutPlacementsProps) => {
  const {
    propertyCaptionTestId,
    propertyCaptionTranslationId,
    clickableTeethTestId,
    teethNumberContainerButtonsTestId,
    teethNumberButtonsTestId,
    teethNumberContainerHooksTestId,
    teethNumberHooksTestId,
    teethNumberIntegratedHooksContainerTestId,
    teethNumberIntegratedHooksTestId,
  } = cutoutsSpecificIdsMap[cutoutType]
  const { buttons, hooks, integratedHooks } = useSelector(
    caseWizardSelectors.getClinicalPreferencesHooks(cutoutType),
  )
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())

  const getToothType = (toothNumber: Universal) => {
    if (hooks.includes(toothNumber)) {
      return TeethImageType.CLASSII_HOOK
    }
    return TeethImageType.PLAIN
  }

  const getAuxComponent = (toothNumber: Universal) => {
    if (buttons.includes(toothNumber)) {
      return (
        <AuxComponentImageContainer>
          <AuxButton />
        </AuxComponentImageContainer>
      )
    }

    if (integratedHooks.includes(toothNumber) && featurePermissions?.integratedHooks) {
      const orientation = getIntegratedHooksOrientation({ toothNumber, cutoutType })

      return (
        <AuxComponentImageContainer orientation={orientation}>
          <AuxIntegratedHook />
        </AuxComponentImageContainer>
      )
    }

    return null
  }

  return (
    <Entry>
      <ClinicalPreferencesPropertyCaption
        data-testid={propertyCaptionTestId}
        captionTextId={propertyCaptionTranslationId}
      />
      <ClinicalPreferencesPropertyValue>
        <Row>
          <Col>
            <Row>
              <ClickableTeeth
                data-testid={clickableTeethTestId}
                arches={[Arch.UPPER, Arch.LOWER]}
                getToothType={getToothType}
                getAuxComponent={getAuxComponent}
              />
            </Row>
            <Box marginTop="14px" justify="space-between">
              <Typography component="span" color="base900">
                <FormattedMessage id="caseWizard.casePrescription.resolveCrowdingBy.extraction.right" />
              </Typography>
              <HorizontalLine />
              <Typography component="span" color="base900">
                <FormattedMessage id="caseWizard.casePrescription.resolveCrowdingBy.extraction.left" />
              </Typography>
            </Box>
          </Col>
          <Col>
            <Row>
              <ClinicalPreferencesTeethNumbers
                data-testid={teethNumberContainerButtonsTestId}
                caption={<span>buttons</span>}
              >
                <TeethNums data-testid={teethNumberButtonsTestId} arr={buttons} divider="," />
              </ClinicalPreferencesTeethNumbers>
            </Row>
            <Row>
              <ClinicalPreferencesTeethNumbers
                data-testid={teethNumberContainerHooksTestId}
                caption={<span>hooks</span>}
              >
                <TeethNums data-testid={teethNumberHooksTestId} arr={hooks} divider="," />
              </ClinicalPreferencesTeethNumbers>
            </Row>
            {featurePermissions?.integratedHooks && (
              <Row>
                <ClinicalPreferencesTeethNumbers
                  data-testid={teethNumberIntegratedHooksContainerTestId}
                  caption={<span>integrated hooks</span>}
                >
                  <TeethNums
                    data-testid={teethNumberIntegratedHooksTestId}
                    arr={integratedHooks}
                    divider=","
                  />
                </ClinicalPreferencesTeethNumbers>
              </Row>
            )}
          </Col>
        </Row>
      </ClinicalPreferencesPropertyValue>
    </Entry>
  )
}

interface AuxComponentImageContainerProps {
  orientation?: Nullable<IntegratedHooksOrientationType>
}

const AuxComponentImageContainer = styled.div<AuxComponentImageContainerProps>`
  position: absolute;
  top: 30%;
  left: 50%;

  transform: translateX(-50%)
    ${({ orientation }) => {
      switch (orientation) {
        case 'reverseY':
          return 'scaleY(-1)'

        case 'reverseX':
          return 'scaleX(-1)'

        case 'reverseXY':
          return 'scale(-1, -1)'

        default:
          return null
      }
    }};
`

export { FeaturesCutoutPlacements }
