import React from 'react'
import { FormattedDate, FormattedMessage } from 'react-intl'

import { Badge } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/Badge'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

enum Position {
  top,
  bottom,
}

interface ProgressBarProps {
  value: number
  start: number
  gradations?: Array<{ value: number; badge: Badge }>
  dates: {
    leftDateThreshold: Date
    rightDateThreshold: Date
  }
  'data-testid': string
}

const ProgressBar: React.FC<ProgressBarProps> = (props) => {
  const roundToHalf = Math.round(props.value * 2) / 2
  const integerPart = Math.floor(roundToHalf)
  const decimalPart = roundToHalf - integerPart
  const scaledValue = props.value - props.start

  return (
    <ProgressBarContainer data-testid={props['data-testid']}>
      <TotalPoints width={scaledValue > 4 ? `${scaledValue - 5}%` : '5px'}>
        <Typography component="span" color="base900" textFontWeight="600">
          <span>{integerPart > 0 && integerPart}</span>
          <span>{decimalPart > 0 && '½'}</span>
          &nbsp;
          <FormattedMessage
            id="perks.progress.shippedCases"
            values={{ count: Math.ceil(roundToHalf) }}
          />
          &nbsp;
        </Typography>
        <Typography component="span" color="base900">
          <FormattedMessage id="perks.progress.thisTerm" />
          &nbsp; (
          <FormattedDate value={props.dates.leftDateThreshold} month="short" />
          &nbsp;-&nbsp;
          <FormattedDate value={props.dates.rightDateThreshold} month="short" />)
        </Typography>
      </TotalPoints>
      <ProgressBarFilling width={`${scaledValue}%`} />
      {scaledValue > 0 && (
        <Step position={Position.top} width={`${scaledValue - 0.1}%`}>
          |
        </Step>
      )}
      {props.gradations &&
        props.gradations.map((grad) => {
          const paddingThreshold = 1

          if (grad.value > props.start + paddingThreshold) {
            return (
              <div key={grad.badge}>
                <Step position={Position.bottom} width={`${grad.value - props.start - 0.1}%`}>
                  |
                </Step>
                <StepCaption
                  position={Position.bottom}
                  width={`${grad.value - props.start - 1}%`}
                  component="span"
                  variant="extra-small"
                  color="base900"
                  textFontWeight="600"
                  whiteSpace="nowrap"
                >
                  <FormattedMessage id="perks.badge" values={{ badge: grad.badge }} />({grad.value}+{' '}
                  <FormattedMessage id="perks.progress.cases" />)
                </StepCaption>
              </div>
            )
          }

          return ''
        })}
    </ProgressBarContainer>
  )
}

const ProgressBarContainer = styled.div`
  margin: 42px 0 0 -2px;

  position: relative;

  width: 101%;
  height: 10px;
  border: 1px solid ${({ theme }) => theme.colors.base800};
`

const ProgressBarFilling = styled.div<{ width: string }>`
  position: absolute;
  left: 0;

  width: ${(props) => props.width};
  height: 100%;

  background-color: ${({ theme }) => theme.colors.primary300};
`

const Step = styled.div<{ width: string; position: Position }>`
  position: absolute;
  top: ${(props) => (props.position === Position.top ? '-7px' : 'unset')};
  bottom: ${(props) => (props.position === Position.bottom ? '-6px' : 'unset')};
  left: ${(props) => props.width};

  font-size: 6px;
`

const StepCaption = styled(Typography)<{ width: string; position: Position }>`
  position: absolute;
  top: ${(props) => (props.position === Position.top ? '-1.98rem' : 'unset')};
  bottom: ${(props) => (props.position === Position.bottom ? '-22px' : 'unset')};
  left: ${(props) => props.width};
`

const TotalPoints = styled.div<{ width: string }>`
  position: absolute;
  top: -22px;
  left: ${(props) => props.width};
`

export { ProgressBar }
