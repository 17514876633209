import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Button } from 'antd'
import { editScansSelectors } from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { HelpIcon } from 'app/components/ui/Form/HelpIcon'
import { PDF } from 'app/components/ui/Icons/common'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { LabeledTooltip } from 'app/components/ui/Tooltip/LabeledTooltip'
import { appSelectors } from 'app/logic/app/logic'
import styled from 'styled-components'

import { Scan } from './Scan'
import { ScanPdfModal } from './ScanPdfModal'

interface ScanLabelProps {
  scan: Scan
  isSelected: boolean
}

const ScanTooltip = (props: ScanLabelProps) => {
  const [isModalOpen, setModalOpen] = useState(false)
  const selectedScanner = useSelector(editScansSelectors.getSelectedScanner())
  const selectedScanType = selectedScanner?.name ?? ''
  const language = useSelector(appSelectors.getLanguage())
  const cloudFrontScans = useSelector(editScansSelectors.getCloudFrontScansByLanguage(language))
  const webContentPropertyName = `scanner_description__${selectedScanType}`
  const webContentPDFLink = `scanner_pdf_link__${selectedScanType}`
  const toolTipText =
    selectedScanType && selectedScanType.length > 0
      ? Object.entries(cloudFrontScans).find(([key]) => key === webContentPropertyName)
      : []
  const textToDisplay = toolTipText && toolTipText.length > 1 ? toolTipText[1] : ''
  const scanPdfURLArray =
    selectedScanType && selectedScanType.length > 0
      ? Object.entries(cloudFrontScans).find(([key]) => key === webContentPDFLink)
      : []
  const scanPdfUrl = scanPdfURLArray && scanPdfURLArray.length > 1 ? scanPdfURLArray[1] : ''
  const isTooltipDisabled = !textToDisplay || !props.isSelected

  const text = props.scan.description ? props.scan.description : props.scan.name

  return (
    <>
      <LabeledTooltip
        title={
          <>
            <Typography data-testid="ScanTooltip-Text" variant="small" color="white">
              <FormattedMessage id={textToDisplay} />
            </Typography>
            {scanPdfUrl && (
              <div data-testid="ScanTooltip-AdditionalDetails">
                <ToolTipButton
                  onClick={() => setModalOpen(true)}
                  type="link"
                  data-testid="ScanTooltip-AdditionalDetailsButton"
                >
                  <Typography component="span" variant="small" color="white" isUnderlined>
                    <FormattedMessage id="scanner.get_additional_details" />
                  </Typography>
                  <PDF width="20px" />
                </ToolTipButton>
              </div>
            )}
          </>
        }
        text={text}
        disabled={isTooltipDisabled}
        placement="top"
        overlayInnerStyle={{
          padding: '11px',
          textAlign: 'justify',
          marginLeft: '12px',
        }}
      >
        <HelpIcon data-testid="ScanTooltip-HelpIcon" />
      </LabeledTooltip>

      {isModalOpen && (
        <ScanPdfModal
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          scanPdfUrl={scanPdfUrl}
          selectedScanType={selectedScanType}
        />
      )}
    </>
  )
}

export { ScanTooltip }

const ToolTipButton = styled(Button)`
  margin-top: 15px;
  padding: 0;

  height: auto;
`
