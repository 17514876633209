import { Badge } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/CasePerks'
import { Perks } from 'app/core/domain/Http/Perks'
import { Region } from 'app/core/domain/Region'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

interface CasePerksState {
  badge?: Badge
  totalCases?: number
  totalPoints?: number
  region?: Region
  showShippedCasesPerks?: boolean
}

const INITIAL_STATE: CasePerksState = {}

const casePerksActions = {
  casePerksMounted: createAction('@CASE_PERKS/CASE_PERKS_MOUNTED')(),
  perksRequestComplete: createAction('@CASE_PERKS/PERKS_REQUEST_COMPLETED')<Perks>(),
  perksRequestFailed: createAction('@CASE_PERKS/PERKS_REQUEST_FAILED')<{ message: string }>(),
}

type CasePerksActions = ActionType<typeof casePerksActions>

// TODO: maybe it is worth cleaning up the state when perks request failed and show a warning.
const casePerksReducer = createReducer<CasePerksState, CasePerksActions>(
  INITIAL_STATE,
).handleAction(casePerksActions.perksRequestComplete, (state, action) => ({
  ...state,
  badge: action.payload.badge,
  region: action.payload.region,
  totalCases: action.payload.totalCases,
  totalPoints: action.payload.totalPoints,
  showShippedCasesPerks: action.payload.showShippedCasesPerks,
}))

const casePerksSelectors = {
  getRegion: () => (state: RootState) => state.casePerks.region,
  getBadge: () => (state: RootState) => state.casePerks.badge,
  getTotalPoints: () => (state: RootState) => state.casePerks.totalPoints,
  getTotalCases: () => (state: RootState) => state.casePerks.totalCases,
  getShowShippedCasesPerks: () => (state: RootState) => state.casePerks.showShippedCasesPerks,
}

export { CasePerksState, CasePerksActions, casePerksActions, casePerksReducer, casePerksSelectors }
