import { useState, useEffect, useCallback } from 'react'
import { IntlProvider } from 'react-intl'
import { Provider, useSelector, useDispatch } from 'react-redux'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { BasicPage } from 'app/components/routes/BasicPage/BasicPage'
import { UploadDicom } from 'app/components/routes/Dicom/UploadDicom'
import { EnvironmentParams } from 'app/core/domain/EnvironmentParams'
import { messageBundle } from 'app/core/i18n/localization'
import { appActions, appSelectors } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'
import { store } from 'app/logic/store'
import axios from 'axios'
import { ThemeProvider } from 'styled-components'

import { sdsTheme } from './components/ui/SDS'
import { useLogoutBroadcast } from './core/auth/AuthenticationBroadcast'
import { flattenObject } from './core/flattenObject'
import { useDidMount } from './core/react/CustomHooks'
import { SiteMap } from './core/react/SiteMap'
import { GlobalStyle } from './global.styles'
import { legacyTheme } from './theme/theme'

import 'app/resources/css/global.css'

const App = () => {
  const dispatch = useDispatch()
  const intlLocale = (useSelector(appSelectors.getLocale()) || '').replace('_', '-')
  const language = useSelector(appSelectors.getLanguage())
  const isSDS = useSelector(featureFlagSelectors.isSDS())

  const [currentTheme, setCurrentTheme] = useState(legacyTheme)
  const [messages, setMessages] = useState({})

  useEffect(() => {
    axios
      .get(`${EnvironmentParams.CONTENT_MANAGEMENT_URL}/localization.json`, {
        params: {
          timestamp: new Date().getTime(),
        },
      })
      .then((res) => {
        if (language && res.data[language]) {
          setMessages({ ...messageBundle.en, ...res.data[language] })
        }
      })
      .catch((err) => {
        setMessages({ ...messageBundle.en })
        console.error(err, ' localization load failed')
      })
  }, [language])

  useDidMount(() => {
    dispatch(appActions.webContentRequested())
  })

  useEffect(() => {
    if (isSDS) {
      setCurrentTheme(sdsTheme)
    }
  }, [isSDS])

  const onLogout = useCallback(() => {
    window.location.href = SiteMap.homePage()
  }, [])

  useLogoutBroadcast(onLogout)

  if (Object.keys(messages).length === 0 || !intlLocale) {
    return null
  }

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyle />
      <IntlProvider
        defaultLocale="en-US"
        fallbackOnEmptyString
        locale={intlLocale}
        messages={flattenObject(messages)}
      >
        <BrowserRouter basename="/ui">
          <Switch>
            <Route path="/uploadDicom/:id">
              <UploadDicom />
            </Route>
            <Route path="*">
              <BasicPage />
            </Route>
          </Switch>
        </BrowserRouter>
      </IntlProvider>
    </ThemeProvider>
  )
}

const AppWithProvider = () => (
  <Provider store={store}>
    <App />
  </Provider>
)

export { AppWithProvider as App }
