import React from 'react'
import { useSelector } from 'react-redux'

import { Badge } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/Badge'
import { ProgressBar } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/ProgressBar'
import { QuestionMark } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/QuestionMark/QuestionMark'
import { casePerksSelectors } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import { getYear, isWithinInterval } from 'date-fns'
import styled from 'styled-components'

const americaGradations = [
  { value: 30, badge: Badge.INSIDER },
  { value: 60, badge: Badge.PINNACLE },
]

const AmericaCasePerksProgress = () => {
  const points = useSelector(casePerksSelectors.getTotalPoints()) || 0
  const start = points < 70 ? 0 : points - 70

  const now = new Date()
  const firstHalfOfYear = {
    leftDateThreshold: new Date(getYear(now), 0, 1),
    rightDateThreshold: new Date(getYear(now), 5, 30),
  }
  const lastHalfOfYear = {
    leftDateThreshold: new Date(getYear(now), 6, 1),
    rightDateThreshold: new Date(getYear(now), 11, 31),
  }
  const dates = isWithinInterval(now, {
    start: firstHalfOfYear.leftDateThreshold,
    end: firstHalfOfYear.rightDateThreshold,
  })
    ? firstHalfOfYear
    : lastHalfOfYear

  return (
    <FullContainer data-testid="AmericaCasePerksProgress">
      <StandartProgressContainer>
        <ProgressBar
          value={points}
          start={start}
          gradations={americaGradations}
          dates={dates}
          data-testid="AmericaCasePerksProgress-ProgressBar"
        />
      </StandartProgressContainer>
      <HelpContainer>
        <QuestionMark data-testid="AmericaCasePerksProgress-Help" />
      </HelpContainer>
    </FullContainer>
  )
}

const FullContainer = styled.div`
  position: relative;

  width: 100%;
`
const StandartProgressContainer = styled.div`
  position: relative;

  overflow: hidden;

  width: 100%;
  height: 100%;
`
const HelpContainer = styled.div`
  position: absolute;
  top: 17px;
  right: 27px;
`

export { AmericaCasePerksProgress }
