import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { SupportText } from 'app/components/routes/CaseList/DoctorCaseList/CaseSubmittedModal/SupportText'
import {
  caseSubmittedModalActions,
  caseSubmittedModalSelectors,
} from 'app/components/routes/CaseList/DoctorCaseList/CaseSubmittedModal/logic'
import { Button } from 'app/components/ui/Button'
import { LightInfoButton } from 'app/components/ui/Buttons'
import { OrmcoModal } from 'app/components/ui/Modal/OrmcoModal'
import { OrmcoModalBody } from 'app/components/ui/Modal/OrmcoModalBody'
import { OrmcoModalFooter } from 'app/components/ui/Modal/OrmcoModalFooter'
import { OrmcoModalHeader } from 'app/components/ui/Modal/OrmcoModalHeader'
import { OrmcoModalHeaderText } from 'app/components/ui/Modal/OrmcoModalHeaderText'
import { Padder } from 'app/components/ui/Padder'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

const CaseSubmittedModal: React.FC = () => {
  const dispatch = useDispatch()
  const isOpen = useSelector(caseSubmittedModalSelectors.isOpen())

  const close = useCallback(() => {
    dispatch(caseSubmittedModalActions.closeModalClicked())
  }, [dispatch])
  const goToPrintPage = useCallback(() => {
    dispatch(caseSubmittedModalActions.goToPrintPageClicked())
  }, [dispatch])

  useDidMount(() => {
    dispatch(caseSubmittedModalActions.caseSubmittedModalMounted())
  })

  return (
    <OrmcoModal isOpen={isOpen} onRequestClose={close} data-testid="CaseSubmittedModal">
      <OrmcoModalHeader data-testid="CaseSubmittedModal-Header">
        <OrmcoModalHeaderText variant="large" headerId="caseSubmitted.modal.header" />
      </OrmcoModalHeader>
      <OrmcoModalBody data-testid="CaseSubmittedModal-Body">
        <Typography margin="10px 0">
          <FormattedMessage id="caseSubmitted.modal.body1" />
        </Typography>
        <Typography margin="10px 0">
          <FormattedMessage id="caseSubmitted.modal.body2" />
        </Typography>
        <Typography margin="10px 0">
          <FormattedMessage id="caseSubmitted.modal.body3" />
        </Typography>
        <SupportText />
      </OrmcoModalBody>
      <CaseSubmittedModalFooter data-testid="CaseSubmittedModal-Footer">
        <Button variant="white" onClick={close} data-testid="CaseSubmittedModal-Close">
          <FormattedMessage id="caseSubmitted.modal.close" />
        </Button>
        <Padder width="10px" />
        <LightInfoButton onClick={goToPrintPage} data-testid="CaseSubmittedModal-Print">
          <FormattedMessage id="caseSubmitted.modal.print" />
        </LightInfoButton>
      </CaseSubmittedModalFooter>
    </OrmcoModal>
  )
}

export { CaseSubmittedModal }

const CaseSubmittedModalFooter = styled(OrmcoModalFooter)`
  justify-content: flex-end;

  height: 60px;
`
