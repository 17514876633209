import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'

import { IconsProps } from 'app/components/ui/Icons/misc'
import { Unmovable, NoAttachments, Biteramps } from 'app/components/ui/Icons/teeth-auxiliaries'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { DefaultTheme, StyledComponent } from 'styled-components'

import { ActivitySelection } from './ActivitySelection'

interface AuxComponent {
  type: ActivitySelection
  name: string
  arr: number[]
  Icon: StyledComponent<React.FunctionComponent, DefaultTheme, IconsProps, never>
}

const useAvailableAuxiliaries = () => {
  const form = useFormContext<CaseDraft>()

  const {
    primaryCasePreferences: { dontMoveTeeth, dontPlaceTeeth, biteRampTeeth },
  } = form.getValues()

  const availableAuxiliaries: AuxComponent[] = useMemo(
    () => [
      {
        arr: dontMoveTeeth,
        type: ActivitySelection.dontMoveTeeth,
        name: 'dontMove',
        Icon: Unmovable,
      },
      {
        arr: dontPlaceTeeth,
        type: ActivitySelection.dontPlaceTeeth,
        name: 'dontPlace',
        Icon: NoAttachments,
      },
      {
        arr: biteRampTeeth,
        type: ActivitySelection.biteRampTeeth,
        name: 'biteRamp',
        Icon: Biteramps,
      },
    ],
    [dontMoveTeeth, dontPlaceTeeth, biteRampTeeth],
  )

  return availableAuxiliaries
}

export { AuxComponent, useAvailableAuxiliaries }
