import styled from 'styled-components'

import { StyledNavigationLinkProps } from './interfaces'

export const StyledNavigationLink = styled.a<StyledNavigationLinkProps>`
  margin: 0 12px;

  display: inline-flex;
  align-items: center;

  height: 100%;
  border-bottom: 2px solid
    ${({ theme, isActive }) => (isActive ? theme.colors.white : 'transparent')};

  & svg {
    margin: 0 8px;

    width: 16px;
    height: auto;
  }

  & span,
  & svg {
    color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.primary300)};
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    span,
    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`
