import React, { useCallback } from 'react'
import { Button, ButtonProps } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { Row, Col } from 'antd'
import { caseWizardActions, caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { hybridWizardSteps } from 'app/components/routes/CaseWizard/wizardSteps'
import { Edit } from 'app/components/ui/Icons/common'
import { Padder } from 'app/components/ui/Padder'
import { Box } from 'app/components/ui/SDS/common/Box'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import styled from 'styled-components'

interface HybridCaseWizardProgressProps {
  isSubmittedCase?: boolean
}

const PATIENT_PRESCRIPTION_INDEX = 3

const HybridCaseWizardProgress: React.FC<HybridCaseWizardProgressProps> = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const history = useHistory()
  const steps = hybridWizardSteps
  const form = useFormContext<CaseDraft>()
  const draft = useSelector(caseWizardSelectors.getDraftData())
  const openOfficeModal = () => {
    dispatch(caseWizardActions.officeNotesButtonClicked(true))
  }
  const activeFormIndex = useSelector(caseWizardSelectors.getActiveFormIndex())
  const caseId = useSelector(caseWizardSelectors.getDraftId())
  const { isSubmittedCase } = props
  const officeNotesButtonText = intl.formatMessage({
    id: 'caseWizard.casePrescription.officeNotes',
  })
  const goToStep = useCallback(
    (index: number) => {
      const { link } = steps[index]

      if (link) {
        if (activeFormIndex !== PATIENT_PRESCRIPTION_INDEX) {
          window.location.href = link + caseId
          return
        }

        if (!caseId) {
          dispatch(caseWizardActions.createCaseButtonClicked(draft.patientDetails))
        } else {
          dispatch(caseWizardActions.updateCaseDraftRequested(draft))
        }
      } else {
        dispatch(caseWizardActions.instantCaseStepChosen(index))
        history.push(`caseWizard?id=${caseId}&step=${index}`)
      }

      return dispatch(caseWizardActions.caseStepChosen(index))
    },
    [dispatch, draft, caseId, history, steps, activeFormIndex],
  )

  return (
    <Row justify="space-between">
      {!isSubmittedCase && (
        <StepsCol>
          {steps.map((step, index) => {
            const isActive = activeFormIndex === index

            return (
              <ProgressElement
                bsStyle={isActive ? 'info' : 'default'}
                active={isActive}
                key={step.id}
                onClick={() => goToStep(index)}
                disabled={!form.formState.isValid}
                data-testid={`HybridCaseWizardProgress-${step.id}`}
              >
                <Padder width="10px" />
                <div>{step.name}</div>
              </ProgressElement>
            )
          })}
        </StepsCol>
      )}

      <ColMargined>
        <OfficeNotesButton
          onClick={openOfficeModal}
          data-testid="HybridCaseWizardProgress-OfficeNotesButton"
          title={officeNotesButtonText}
        >
          <Box paddingRight="8px" width="auto">
            <Edit />
          </Box>
          <NoteButton>{officeNotesButtonText}</NoteButton>
        </OfficeNotesButton>
      </ColMargined>
    </Row>
  )
}

const NoteButton = styled.div`
  display: block;

  overflow: hidden;

  max-width: 75px;

  white-space: nowrap;

  text-overflow: ellipsis;
`

const ProgressElement = styled(Button)<ButtonProps>`
  margin-left: 22px;
  padding-right: 18px;
  position: relative;

  border-color: ${({ theme }) => theme.colors.base200};

  font-size: 12px;

  color: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.black)};

  background-color: ${({ active, theme }) =>
    active ? theme.colors.base800 : theme.colors.base200};
  background-image: ${({ active, theme }) =>
    active
      ? 'none'
      : `linear-gradient(to bottom, ${theme.colors.white} 0, ${theme.colors.base200} 100%)`};

  &:after {
    position: absolute;
    top: 4px;
    right: -10px;

    content: '';

    width: 21px;
    height: 21px;
    border: inherit;
    border-bottom-color: transparent;
    border-left-color: transparent;

    background: inherit;
    border-radius: 0 4px 0 0;

    transform: rotate(45deg);
  }
`
const OfficeNotesButton = styled(Button)`
  margin-right: 24px;
  margin-left: auto;
  position: relative;

  cursor: pointer;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  height: 35px;
  border-color: ${({ theme }) => theme.colors.base200};

  text-align: center;

  font-size: 12px;
  font-weight: 400;
  line-height: 1.5715;

  color: ${({ theme }) => theme.colors.black};

  background-color: initial;
  background-image: ${({ theme }) =>
    `linear-gradient(to bottom, ${theme.colors.white} 0, ${theme.colors.base200} 100%)`};

  border-radius: 6px;
  float: right;
  font-variant: tabular-nums;
`

const ColMargined = styled(Col)`
  margin-top: 10px;
  margin-left: auto;
`

const StepsCol = styled(Col)`
  margin-top: 10px;
`

export { HybridCaseWizardProgress, PATIENT_PRESCRIPTION_INDEX }
