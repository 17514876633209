import { ReactElement } from 'react'

import { Col, Row } from 'antd'
import {
  Upper1_16,
  Upper2_15,
  Upper3_14,
  Upper4_13,
  Upper5_12,
  Upper6_11,
  Upper8_9,
  Upper7_10,
  Lower18_31,
  Lower19_30,
  Lower20_29,
  Lower21_28,
  Lower22_27,
  Lower23_26,
  Lower24_25,
  Lower17_32,
} from 'app/components/ui/Icons/teeth'
import { Arch, getArchTeethNumbers, Universal } from 'app/core/domain/ArchTools'
import { TeethImageType } from 'app/core/domain/TeethImageType'
import styled from 'styled-components'

import { sortOnArch } from './sortOnArchHelper'

export const TEETH_MATRIX = {
  1: Upper1_16,
  2: Upper2_15,
  3: Upper3_14,
  4: Upper4_13,
  5: Upper5_12,
  6: Upper6_11,
  7: Upper7_10,
  8: Upper8_9,

  9: Upper8_9,
  10: Upper7_10,
  11: Upper6_11,
  12: Upper5_12,
  13: Upper4_13,
  14: Upper3_14,
  15: Upper2_15,
  16: Upper1_16,

  17: Lower17_32,
  18: Lower18_31,
  19: Lower19_30,
  20: Lower20_29,
  21: Lower21_28,
  22: Lower22_27,
  23: Lower23_26,
  24: Lower24_25,

  25: Lower24_25,
  26: Lower23_26,
  27: Lower22_27,
  28: Lower21_28,
  29: Lower20_29,
  30: Lower19_30,
  31: Lower18_31,
  32: Lower17_32,
}
const REVERSIBLE_TEETH = [9, 10, 11, 12, 13, 14, 15, 16, 25, 26, 27, 28, 29, 30, 31, 32]

interface ClickableTeethProps {
  arches: Arch[]
  onToothClick?: (toothNumber: Universal) => void
  getToothType?: (toothNumber: Universal) => keyof typeof TeethImageType
  getAuxComponent?: (toothNumber: Universal) => ReactElement | null
  'data-testid'?: string
}

const ClickableTeeth = (props: ClickableTeethProps) => {
  const { arches, getAuxComponent, getToothType, onToothClick, 'data-testid': testId } = props

  return (
    <Col span={24}>
      {arches.map((arche) => (
        <ToothRow
          key={arche}
          align={arche === Arch.UPPER ? 'bottom' : 'top'}
          data-testid={`${testId}-${arche}`}
        >
          {getArchTeethNumbers(arche)
            .sort(sortOnArch(arche))
            .map((toothNumber) => {
              const Icon = TEETH_MATRIX[toothNumber as keyof typeof TEETH_MATRIX]
              const isReversed = REVERSIBLE_TEETH.includes(toothNumber)
              const toothType = (getToothType && getToothType(toothNumber)) ?? TeethImageType.PLAIN

              return (
                <Tooth
                  key={toothNumber}
                  onClick={() => onToothClick?.(toothNumber)}
                  disabled={toothType === TeethImageType.DISABLED}
                  data-testid={`${testId}-${toothNumber}`}
                >
                  <div data-testid={`ToothType-${toothType}`}>
                    <Icon mirror={isReversed} type={toothType} />
                  </div>
                  {getAuxComponent && getAuxComponent(toothNumber)}
                </Tooth>
              )
            })}
        </ToothRow>
      ))}
    </Col>
  )
}

const ToothRow = styled(Row)`
  margin-top: 8px;

  flex-wrap: nowrap;
  justify-content: center;
`

const Tooth = styled.div<{ disabled?: boolean }>`
  position: relative;

  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};

  overflow: hidden;

  height: fit-content;
`

export { ClickableTeeth }
