import { NavigationLink } from 'app/components/routes/BasicPage/Header/NavigationBar/NavigationItems/NavigationLink/NavigationLink'
import { GlyphIcon, IconType } from 'app/components/ui/Icon'
import { postLogoutMessage } from 'app/core/auth/AuthenticationBroadcast'
import { SessionStorageKeysMap } from 'app/core/domain/SessionStorageKeysMap'
import { SiteMap } from 'app/core/react/SiteMap'
import styled from 'styled-components'

interface LogoutLinkProps {
  'data-testid': string
}

const LogoutLink = (props: LogoutLinkProps) => {
  const handleLogout = () => {
    sessionStorage.removeItem(SessionStorageKeysMap.isIntegratedHooksModalOpen)
    postLogoutMessage()
  }

  return (
    <NavigationLink
      icon={<LogoutIcon />}
      href={SiteMap.logout()}
      onClick={handleLogout}
      data-testid={props['data-testid']}
      linkTextId="header.navigation.logout"
    />
  )
}

const LogoutIcon = styled(GlyphIcon).attrs(() => ({
  type: IconType.LOGOUT,
}))`
  margin-right: 4px;
`

export { LogoutLink }
