import React from 'react'
import { useSelector } from 'react-redux'

import { CaseFilter } from 'app/components/routes/CaseList/CaseFilter'
import { CaseListTable } from 'app/components/routes/CaseList/CaseListTable'
import { CasePerksDashboard } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/CasePerksDashboard'
import { CaseSubmittedModal } from 'app/components/routes/CaseList/DoctorCaseList/CaseSubmittedModal/CaseSubmittedModal'
import {
  doctorColumnsMap,
  doctorSearchColumnSettings,
} from 'app/components/routes/CaseList/DoctorCaseList/doctorColumnSetting'
import { TabFilters } from 'app/components/routes/CaseList/TabFilters'
import { ToolPanel } from 'app/components/routes/CaseList/ToolPanel'
import { caseListSelectors, ListType } from 'app/components/routes/CaseList/logic'
import styled from 'styled-components'

import { UnarchiveModal } from './DoctorActionButtons/UnarchiveModal/UnarchiveModal'

const DoctorCaseList: React.FC = () => {
  const tab = useSelector(caseListSelectors.getDoctorActiveCaseFilter())
  const caseListType = useSelector(caseListSelectors.getListType())
  const columnSettings =
    caseListType === ListType.SEARCH ? doctorSearchColumnSettings : doctorColumnsMap[tab]

  return (
    <>
      <CaseSubmittedModal />
      <UnarchiveModal />
      <CasePerksDashboard />
      <CaseListWithPaddings>
        <ToolPanel />

        {caseListType === ListType.TAB && (
          <>
            <CaseFilter />
            <TabFilters />
          </>
        )}

        <CaseListTable columnSettings={columnSettings} />
      </CaseListWithPaddings>
    </>
  )
}
const CaseListWithPaddings = styled.div`
  margin: 0 auto;
  position: relative;

  width: 97%;
  height: 100%;
`

export { DoctorCaseList }
