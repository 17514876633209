import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { CaseTableData } from 'app/components/routes/CaseList/CaseTableData'
import { ContactSupportButton } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/ContactSupportButton'
import { OnHoldContactSupportButton } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/OnHoldContactSupportButton'
import { OnHoldUnarchiveButton } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/OnHoldUnarchiveButton'
import { OpenApproverButtons } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/OpenApproverButtons'
import { SubmitCaseButton } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/SubmitCaseButton'
import {
  TableCellStyledButton,
  OnHoldLinkBtn,
} from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/TableCellStyledButtons'
import { TrackButton } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/TrackButton'
import { UnarchiveButton } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/UnarchiveButton'
import { SdsToolOutlined, SdsExclamationCircleFilled } from 'app/components/ui/Icons/sds'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Case, HoldDetailsType, HoldReasonCodes } from 'app/core/domain/Case'
import { OrderStatus } from 'app/core/domain/CaseStatus'
import styled from 'styled-components'

import { HoldReasonPopover } from './Popover/HoldReasonPopover'

const doctorStatusToComponentMap: { [type: string]: React.FC<{ case: Case }> } = {
  [OrderStatus.UNSUBMITTED]: SubmitCaseButton,
  [OrderStatus.AWAITING_APPROVAL]: OpenApproverButtons,
  [OrderStatus.SHIPPED]: TrackButton,
  [OrderStatus.SHIPPED_PARTIALLY]: TrackButton,
  none: () => <></>,
}

const DoctorStatusCard: React.FC<{ data: CaseTableData }> = (props) => {
  const intl = useIntl()
  const { caseId, hold, archived, orderId, awaitingApprovalCount, orderStatus, holdDetails } =
    props.data.case
  let button = <DoctorActionButton onHold={hold} case={props.data.case} />
  let titleId = 'cases.list.doctor.action'
  let iteration
  const showDoctorApprovalIteration =
    orderStatus === OrderStatus.AWAITING_APPROVAL && awaitingApprovalCount > 0

  if (showDoctorApprovalIteration) {
    iteration = awaitingApprovalCount
  }

  const isHoldArchivedOrOther =
    holdDetails &&
    (holdDetails.reasonCode === HoldReasonCodes.ARCHIVED ||
      holdDetails.reasonCode === HoldReasonCodes.OTHER)
  const isHoldDetailsAvailable = hold && holdDetails && !isHoldArchivedOrOther

  /**
   * I hope, that we can refactor the following piece of code in the nearest future.
   * It looks, that when we will have new design for all buttons of this ui,
   * we can rewrite this code more elegant reducing count of conditions.
   */
  if (archived) {
    if (isHoldDetailsAvailable) {
      button = <OnHoldUnarchiveButton caseId={caseId} />
      titleId = ''
    } else {
      button = <UnarchiveButton case={props.data.case} />
      titleId = 'cases.list.doctor.button.unarchive'
    }
  } else if (hold) {
    if (isHoldArchivedOrOther) {
      button = <ContactSupportButton caseId={caseId} />
      titleId = 'cases.list.doctor.button.support'
    } else {
      button = <OnHoldContactSupportButton caseId={caseId} />
      titleId = ''
    }
  }

  const title = titleId ? intl.formatMessage({ id: titleId }, { status: orderStatus }) : ''

  const statusText = intl.formatMessage(
    { id: 'cases.list.doctor.status' },
    { status: hold ? 'ON_HOLD' : orderStatus },
  )

  const ResolveBtnText = intl.formatMessage({
    id: 'cases.list.doctor.onHoldPopover.resolveBtnText',
  })

  const statusRenderer = isHoldDetailsAvailable ? (
    <HoldReasonPopoverWrapper
      holdDetails={holdDetails}
      caseId={caseId}
      orderId={orderId}
      statusText={statusText}
    >
      <OnHoldLinkBtn type={'link'}>
        <SdsExclamationCircleFilled width="14px" color="danger400" />
        <BtnText>{statusText}</BtnText>
      </OnHoldLinkBtn>
    </HoldReasonPopoverWrapper>
  ) : (
    <Typography data-testid="Case-StatusName" title={statusText} component="span">
      {statusText}
    </Typography>
  )

  return (
    <CaseStatusContainer paddingLeft="8px" display="block">
      <Box display="inline-flex" justify="flex-start">
        {statusRenderer}
        {iteration && (
          <Typography component="span" data-testid="Case-StatusIterationText">
            {' '}
            ({iteration})
          </Typography>
        )}
      </Box>

      <Box paddingBottom="4px" justify="flex-start">
        {isHoldDetailsAvailable && (
          <HoldReasonPopoverWrapper
            holdDetails={holdDetails}
            caseId={caseId}
            orderId={orderId}
            statusText={statusText}
          >
            <TableCellStyledButton data-testid={`ResolveButton-${caseId}`}>
              <SdsToolOutlined width="11px" height="11px" color="secondary300" />
              <BtnText>{ResolveBtnText}</BtnText>
            </TableCellStyledButton>
          </HoldReasonPopoverWrapper>
        )}

        <div title={title}>{button}</div>
      </Box>
    </CaseStatusContainer>
  )
}

const DoctorActionButton: React.FC<{ case: Case; onHold?: boolean }> = (props) => {
  const transferableStatuses = Object.keys(doctorStatusToComponentMap)
  const statusName = transferableStatuses.includes(props.case.orderStatus)
    ? props.case.orderStatus
    : 'none'
  const ActionComponent = doctorStatusToComponentMap[statusName]

  return (
    <ActionComponent case={props.case}>
      <FormattedMessage id="cases.list.doctor.action" values={{ status: props.case.orderStatus }} />
    </ActionComponent>
  )
}

interface HoldReasonPopoverWrapperProps {
  caseId: string
  orderId: string
  statusText: string
  holdDetails: HoldDetailsType
}

const HoldReasonPopoverWrapper: React.FC<HoldReasonPopoverWrapperProps> = ({
  children,
  holdDetails,
  statusText,
  caseId,
  orderId,
}) => (
  <HoldReasonPopover
    className="hold-reason-popover"
    caseId={caseId}
    orderId={orderId}
    statusText={statusText}
    holdDetails={holdDetails}
  >
    {children}
  </HoldReasonPopover>
)

const CaseStatusContainer = styled(Box)`
  overflow: hidden;
`

const BtnText = styled.span`
  margin-left: 5px;
`

export { DoctorStatusCard }
