import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { truGenXRModalActions } from './logic'

const saveTruGenPopupState: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(truGenXRModalActions.trugenModalDontShowUpdateRequested)),
    switchMap((action) => {
      const username = appSelectors.getUsername()(state$.value)
      const docSelection = action.payload

      return from(
        axios.post(
          `/api/v1/doctors/${username}/donotShowBrandingPopup?docSelection=${!docSelection}`,
        ),
      ).pipe(
        switchMap(() => of(truGenXRModalActions.trugenModalDontShowUpdateFinished())),
        catchError(() => of(truGenXRModalActions.trugenModalDontShowUpdateFailed())),
      )
    }),
  )

const getTruGenXRGifEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([truGenXRModalActions.primaryTruGenXRGifRequested])),
    switchMap(() =>
      from(
        axios
          .get('/api/v1/webapi/staticS3Images/trugenXR')
          .then((res) => truGenXRModalActions.primaryTruGenXRGifReceived(res.data))
          .catch(() => truGenXRModalActions.primaryTruGenXRGifFailed()),
      ),
    ),
  )

const truGenXRSelectModalEpic = combineEpics(saveTruGenPopupState, getTruGenXRGifEpic)

export { truGenXRSelectModalEpic }
