import React, { useCallback } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { casePerksSelectors } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import { caseListActions, caseListSelectors } from 'app/components/routes/CaseList/logic'
import { Loader } from 'app/components/ui/Loader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Region } from 'app/core/domain/Region'
import styled from 'styled-components'

interface TableBodyProps {
  length: number
  'data-testid'?: string
}

const TableBody: React.FC<TableBodyProps> = (props) => {
  const { length } = props
  const dispatch = useDispatch()
  const hasMore = useSelector(caseListSelectors.hasMoreCaseList())
  const loading = useSelector(caseListSelectors.isCaseListLoading())
  const casePerksRegion = useSelector(casePerksSelectors.getRegion())
  const showShippedCasesPerks = useSelector(casePerksSelectors.getShowShippedCasesPerks())
  const testId = props['data-testid']
  const displayLoader = loading ? (
    <Loader />
  ) : (
    <Typography component="span">
      <FormattedMessage id="cases.list.empty" />
    </Typography>
  )

  let height = '80vh'

  const loadMoreCallback = useCallback(() => {
    dispatch(caseListActions.loadMoreCasesReached())
  }, [dispatch])

  if (casePerksRegion) {
    height = casePerksRegion === Region.AUSTRALIA || showShippedCasesPerks ? '63vh' : '58vh'
  }

  return (
    <TableRowsContainer data-testid={testId}>
      {length ? (
        <InfiniteScroll
          next={loadMoreCallback}
          hasMore={hasMore}
          dataLength={length}
          loader={<TableLoader />}
          height={height}
          style={{
            maxHeight: '65vh',
            minHeight: '55vh',
          }}
          hasChildren
          className="tableRowsContainer"
        >
          {props.children}
        </InfiniteScroll>
      ) : (
        <Box display="block" className="tableRowsContainer">
          <Box height="65vh" data-testid={`${testId}-EmptyTable`}>
            {displayLoader}
          </Box>
        </Box>
      )}
    </TableRowsContainer>
  )
}

const TableRowsContainer = styled.div`
  overflow: hidden;

  .tableRowsContainer {
    border: 1px solid ${({ theme }) => theme.colors.primary600};
    border-top: none;

    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`

const TableLoader = () => (
  <Box height="100%">
    <Loader />
  </Box>
)

export { TableBody }
