import { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Select } from 'antd'
import { Box } from 'app/components/ui/SDS/common/Box'
import { COOKIES } from 'app/core/browser/COOKIES'
import { setCookie } from 'app/core/browser/cookieUtils'
import { getSameLanguagesMap } from 'app/core/getSameLanguagesMapUtil'
import { Locale } from 'app/core/i18n/Locale'
import { appActions, appSelectors } from 'app/logic/app/logic'

import { LanguageDropdownProps } from './interfaces'
import { StyledSelect } from './styles'

//TODO: should became standard dropdown later
export const LanguageDropdown = ({ 'data-testid': dataTestId, isDark }: LanguageDropdownProps) => {
  const locales = useSelector(appSelectors.getLocales())
  const userLocale = useSelector(appSelectors.getLocale())
  const sameLanguagesMap = getSameLanguagesMap(locales)
  const intl = useIntl()
  const dispatch = useDispatch()
  const hasChoiceOfLanguages = locales.length > 1
  const handleSelectLanguage = useCallback(
    (value) => {
      setCookie(`${COOKIES.impersonatorLocale}=${value}`)
      dispatch(appActions.localeChanged({ locale: value }))
      dispatch(appActions.termsPdfLinkRequested())
    },
    [dispatch],
  )

  if (!hasChoiceOfLanguages) {
    return null
  }

  return (
    <Box width="auto" data-testid={dataTestId}>
      <StyledSelect
        id="languages"
        onChange={handleSelectLanguage}
        defaultValue={userLocale}
        value={userLocale}
        size="small"
        isDark={isDark}
      >
        {locales.map((localeString: string) => {
          const locale = new Locale(localeString)
          const { languageCode, regionCode } = locale

          if (languageCode && regionCode) {
            const region = sameLanguagesMap[languageCode] > 1 ? regionCode : undefined
            const lang = intl.formatMessage({ id: 'language.code' }, { code: languageCode })

            return (
              <Select.Option key={locale.localeString} value={locale.localeString}>
                <span>
                  {lang}
                  {region}
                </span>
              </Select.Option>
            )
          }
          return <></>
        })}
      </StyledSelect>
    </Box>
  )
}
