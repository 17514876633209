import { ThemeColors } from 'styled-components'

import { Typography } from '../SDS/common/Typography'

import { ButtonProps } from './interfaces'
import { StyledButton, StyledLinkButton } from './styles'

export const Button = (props: React.PropsWithChildren<ButtonProps>) => {
  const fontColor: keyof ThemeColors = props.variant === 'white' ? 'base800' : 'white'

  //TODO: till migration for new react router, since react router handles events otherwise
  const handleClick = (
    event: React.SyntheticEvent<HTMLButtonElement> | React.SyntheticEvent<HTMLAnchorElement>,
  ) => {
    event.preventDefault()

    if (props.onClick) {
      props.onClick(event as any)
    }
  }

  if (props.component === 'link') {
    const { to, icon, children, 'data-testid': dataTestId, variant, width } = props

    return (
      <StyledLinkButton
        to={to}
        data-testid={dataTestId}
        variant={variant}
        width={width}
        onClick={handleClick}
      >
        <Typography component="span" color={fontColor}>
          {icon}
          {children}
        </Typography>
      </StyledLinkButton>
    )
  }

  const { icon, children, component, 'data-testid': dataTestId, ...rest } = props

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <StyledButton as={component} data-testid={dataTestId} {...rest} onClick={handleClick}>
      <Typography component="span" color={fontColor}>
        {icon}
        {children}
      </Typography>
    </StyledButton>
  )
}
