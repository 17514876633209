import styled from 'styled-components'

interface BasicPageContainerProps {
  isSDS?: boolean
}

const BasicPageContainer = styled.div<BasicPageContainerProps>`
  margin: 0 auto;

  box-sizing: border-box;
  min-width: 1174px;

  color: ${({ theme }) => theme.colors.base700};
  background-color: ${({ theme, isSDS }) => (isSDS ? theme.colors.base10 : theme.colors.white)};
  border-radius: 6px;

  @media (min-width: 1200px) {
    width: 1200px;
  }

  @media (min-width: 992px) {
    width: 995px;
  }

  @media (min-width: 768px) {
    width: 780px;
  }
`

export { BasicPageContainer }
