import React from 'react'

import { ActionButton } from 'app/components/ui/ActionButton'
import { SubmitCase } from 'app/components/ui/Icons/common'
import { Padder } from 'app/components/ui/Padder'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Case } from 'app/core/domain/Case'

const SubmitCaseButton: React.FC<{ case: Case }> = (props) => (
  <ActionButton data-testid={`SubmitCaseButton-${props.case.caseId}`}>
    <Box as="span" minWidth="16px" marginRight="4px">
      <SubmitCase width="16px" />
    </Box>
    <Padder width="5px" />
    {props.children}
  </ActionButton>
)

export { SubmitCaseButton }
