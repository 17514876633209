import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { Tooltip } from 'antd'
import { TableCellStyledButton } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/TableCellStyledButtons'
import { unarchiveModalActions } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/UnarchiveModal/logic'
import { SdsUnarchiveOutlined } from 'app/components/ui/Icons/sds'

interface ContactSupportButtonProps {
  caseId: string
}

const OnHoldUnarchiveButton: React.FC<ContactSupportButtonProps> = (props) => {
  const { caseId } = props
  const dispatch = useDispatch()

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation()
    dispatch(unarchiveModalActions.openModalButtonClicked({ caseId }))
  }

  return (
    <Tooltip placement="top" title={<FormattedMessage id="cases.list.doctor.button.unarchive" />}>
      <TableCellStyledButton data-testid={`UnarchiveButton-${caseId}`} onClick={handleClick}>
        <SdsUnarchiveOutlined width="11px" color="secondary300" />
      </TableCellStyledButton>
    </Tooltip>
  )
}

export { OnHoldUnarchiveButton }
