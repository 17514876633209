import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Email } from 'app/components/routes/MyProfile/ContactDetails/Email'
import { Phone } from 'app/components/routes/MyProfile/ContactDetails/Phone'
import { ReceiveEmail } from 'app/components/routes/MyProfile/ContactDetails/ReceiveEmail'
import { VisitWebsiteButton } from 'app/components/routes/MyProfile/ContactDetails/VisitWebsiteButton'
import { WebsiteUrl } from 'app/components/routes/MyProfile/ContactDetails/WebsiteUrl'
import { FormCaption } from 'app/components/ui/Form/FormCaption'
import { appSelectors } from 'app/logic/app/logic'
import { featureFlagSelectors } from 'app/logic/features/logic'
import { store } from 'app/logic/store'
import styled from 'styled-components'

const ContactDetails: React.FC = () => {
  const isDoctor = appSelectors.isDoctor()(store.getState())
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())

  return (
    <>
      <ContactDetailsFormSection data-testid="ContactDetails">
        <ContactDetailsForm name="contactDetailsForm">
          <FormCaption data-testid="ContactDetails-FormCaption">
            <FormattedMessage id="contact.info" />
          </FormCaption>
          {isDoctor && <Phone />}
          <Email />
          <ReceiveEmail />
          {featurePermissions?.doctorLocator && (
            <>
              <WebsiteUrl />
              <VisitWebsiteButton />
            </>
          )}
        </ContactDetailsForm>
      </ContactDetailsFormSection>
    </>
  )
}

const ContactDetailsForm = styled.form`
  box-sizing: border-box;
`

const ContactDetailsFormSection = styled.section`
  margin-top: 10px;

  max-width: 370px;
`

export { ContactDetails }
