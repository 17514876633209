import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CasePerks } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/CasePerks'
import { ShippedCasePerks } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/ShippedCasePerks'
import {
  casePerksActions,
  casePerksSelectors,
} from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import { Region } from 'app/core/domain/Region'
import { useDidMount } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

const CasePerksDashboard: React.FC = () => {
  const dispatch = useDispatch()
  let chosenPerkDashboard
  const isEurope = useSelector(casePerksSelectors.getRegion()) === Region.EUROPE
  const isNorthAmerica = useSelector(casePerksSelectors.getRegion()) === Region.NORTH_AMERICA
  const isAustralia = useSelector(casePerksSelectors.getRegion()) === Region.AUSTRALIA
  const showShippedCasesPerks = useSelector(casePerksSelectors.getShowShippedCasesPerks())

  useDidMount(() => {
    dispatch(casePerksActions.casePerksMounted())
  })

  if (isEurope) {
    chosenPerkDashboard = <ShippedCasePerks />
  }
  if (isNorthAmerica) {
    chosenPerkDashboard = <CasePerks />
  }
  if (isAustralia || showShippedCasesPerks) {
    chosenPerkDashboard = <ShippedCasePerks />
  }

  return (
    <>
      {chosenPerkDashboard && (
        <CasePerksDashboardContainer data-testid="CasePerksDashboardContainer">
          {chosenPerkDashboard}
        </CasePerksDashboardContainer>
      )}
    </>
  )
}

const CasePerksDashboardContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.base700};

  background-color: ${({ theme }) => theme.colors.base10};
  box-shadow: 2px 2px 3px 0 rgba(0, 70, 127, 0.12);
`

export { CasePerksDashboard }
