import React, { useCallback } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { caseListActions } from 'app/components/routes/CaseList/logic'
import { ActionButton } from 'app/components/ui/ActionButton'
import { Desktop, Web } from 'app/components/ui/Icons/common'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Case } from 'app/core/domain/Case'
import { featureFlagSelectors } from 'app/logic/features/logic'
import styled, { css } from 'styled-components'

const OpenApproverButtons: React.FC<{ case: Case }> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const featurePermissions = useSelector(featureFlagSelectors.getFeatureFlags())
  const openApprover = useCallback(
    (isWeb: boolean) => (event: React.MouseEvent) => {
      event.stopPropagation()

      dispatch(caseListActions.openApproverButtonClicked({ case: props.case, isWeb }))
    },
    [dispatch, props.case],
  )

  if (!featurePermissions?.approverWeb) {
    return (
      <ActionButton
        data-testid={`OpenApproverButton-${props.case.caseId}`}
        onClick={openApprover(false)}
        title={intl.formatMessage({ id: 'cases.list.approver.desktop' })}
        padding="0 7px 1px"
      >
        <IconDesktop width="14px" />

        <Typography
          data-testid={`OpenApproverButtons-Typography-${props.case.caseId}`}
          component="span"
          variant="small"
        >
          {props.children}
        </Typography>
      </ActionButton>
    )
  }

  return (
    <Box flexDirection="row" justify="flex-start">
      <ActionButton
        data-testid={`OpenApproverButton-Desktop-${props.case.caseId}`}
        onClick={openApprover(false)}
        title={intl.formatMessage({ id: 'cases.list.approver.open.desktop' })}
        height="18px"
        padding="0 3px"
      >
        <IconDesktop width="14px" />

        <FormattedMessage id="cases.list.approver.desktop" />
      </ActionButton>

      <ActionButton
        data-testid={`OpenApproverButton-Web-${props.case.caseId}`}
        onClick={openApprover(true)}
        title={intl.formatMessage({ id: 'cases.list.approver.open.web' })}
        height="18px"
        padding="0 3px"
      >
        <IconWeb width="14px" />

        <FormattedMessage id="cases.list.approver.web" />
      </ActionButton>
    </Box>
  )
}

export { OpenApproverButtons }

const buttonIconMixin = css`
  margin-right: 4px;

  flex-shrink: 0;
`

const IconDesktop = styled(Desktop)`
  ${buttonIconMixin};
`

const IconWeb = styled(Web)`
  ${buttonIconMixin};
`
