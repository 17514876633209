import { useCallback, useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Divider } from 'antd'
import { caseWizardSelectors } from 'app/components/routes/CaseWizard/logic'
import { ThemedBox } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/ResolveCrowding'
import { TreatmentAction } from 'app/components/routes/CaseWizard/steps/CasePrescription/TreatmentAction'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { OrmcoRadio } from 'app/components/ui/Form/OrmcoRadio'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ThemeContext } from 'app/components/ui/Theme/ThemeContext'
import { Vertical } from 'app/components/ui/Vertical'
import { ApRelationshipPrimary } from 'app/core/domain/ApRelationshipPrimary'
import { CanineMolar } from 'app/core/domain/CanineMolar'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { TreatmentType } from 'app/core/domain/PrimaryCasePreferences'

import { APRelationsImpovementSettings } from './APRelationsImpovementSettings/APRelationsImpovementSettings'

const PROPERTY_KEY = 'primaryCasePreferences.apRelationshipPrimary'

const DEFAULT_VALUE = {
  alignerCutouts: false,
  virtualSimulation: false,
  posteriorIPR: false,
  IPR3_6: false,
}

const AnteriorPosteriorRelationship = () => {
  const form = useFormContext<CaseDraft>()

  const treatmentType = useSelector(caseWizardSelectors.getTreatmentType())

  const themeContext = useContext(ThemeContext)
  const apRelationshipPrimary = form.getValues(PROPERTY_KEY)

  const changeRadio = useCallback(
    (res?: ApRelationshipPrimary) => {
      form.setValue(PROPERTY_KEY, res)
    },
    [form],
  )

  const setMaintain = useCallback(
    () =>
      changeRadio({
        ...DEFAULT_VALUE,
        apActionPrimary: TreatmentAction.MAINTAIN,
        aPrelationshipDistalization: false,
        canineMolarActionPrimary: CanineMolar.CANINE,
      }),
    [changeRadio],
  )

  const setCanineOnly = useCallback(
    () =>
      changeRadio({
        ...DEFAULT_VALUE,
        apActionPrimary: TreatmentAction.CORRECT,
        aPrelationshipDistalization: false,
        canineMolarActionPrimary: CanineMolar.CANINE,
      }),
    [changeRadio],
  )

  const setCanineAndMolar = useCallback(
    () =>
      changeRadio({
        ...DEFAULT_VALUE,
        apActionPrimary: TreatmentAction.CORRECT,
        aPrelationshipDistalization: true,
        canineMolarActionPrimary: CanineMolar.CANINE_MOLAR,
      }),
    [changeRadio],
  )

  return (
    <div>
      <OrmcoFormItemVertical
        label={
          <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.anteriorPosteriorRelationship.label" />
        }
        hasFeedback
        data-testid="AnteriorPosteriorRelationship"
      >
        <Vertical>
          <OrmcoRadio
            onChange={setMaintain}
            checked={
              !apRelationshipPrimary ||
              apRelationshipPrimary?.apActionPrimary === TreatmentAction.MAINTAIN
            }
            data-testid="AnteriorPosteriorRelationship-Maintain"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.maintain" />
            </Typography>
          </OrmcoRadio>
          <OrmcoRadio
            onChange={setCanineOnly}
            checked={apRelationshipPrimary?.apActionPrimary === TreatmentAction.CORRECT}
            data-testid="AnteriorPosteriorRelationship-Correct"
          >
            <Typography component="span">
              <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.correct" />
            </Typography>
          </OrmcoRadio>

          {apRelationshipPrimary?.apActionPrimary === TreatmentAction.CORRECT && (
            <ThemedBox
              contextTheme={themeContext}
              data-testid="AnteriorPosteriorRelationship-Section"
            >
              <Vertical>
                <OrmcoRadio
                  onChange={setCanineOnly}
                  checked={apRelationshipPrimary?.canineMolarActionPrimary === CanineMolar.CANINE}
                  disabled={!apRelationshipPrimary}
                  data-testid="AnteriorPosteriorRelationship-CanineOnly"
                >
                  <Typography component="span">
                    <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.improveBothCanineAndMolar.false" />
                  </Typography>
                </OrmcoRadio>

                <OrmcoRadio
                  onChange={setCanineAndMolar}
                  checked={
                    apRelationshipPrimary?.canineMolarActionPrimary === CanineMolar.CANINE_MOLAR
                  }
                  disabled={!apRelationshipPrimary}
                  data-testid="AnteriorPosteriorRelationship-CanineMolar"
                >
                  <Typography component="span">
                    <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.improveBothCanineAndMolar.true" />
                  </Typography>
                </OrmcoRadio>
              </Vertical>

              {treatmentType !== TreatmentType.IDB && (
                <>
                  <Divider />
                  <APRelationsImpovementSettings />
                </>
              )}
            </ThemedBox>
          )}
        </Vertical>
      </OrmcoFormItemVertical>
    </div>
  )
}

export { AnteriorPosteriorRelationship }
