import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { AmericaCasePerksProgress } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/AmericaCasePerksProgress'
import { Badge } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/Badge'
import { EuropeCasePerksProgress } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/EuropeCasePerksProgress'
import { iconMap } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/iconMap'
import { casePerksSelectors } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Region } from 'app/core/domain/Region'
import styled from 'styled-components'

const CasePerks = () => {
  const badge = useSelector(casePerksSelectors.getBadge())
  const Icon = badge && iconMap[badge]
  let currentCasePerksProgress

  const isEurope = useSelector(casePerksSelectors.getRegion()) === Region.EUROPE
  const isNorthAmerica = useSelector(casePerksSelectors.getRegion()) === Region.NORTH_AMERICA
  const showShippedCasesPerks = useSelector(casePerksSelectors.getShowShippedCasesPerks())

  if (isEurope && !showShippedCasesPerks) {
    currentCasePerksProgress = <EuropeCasePerksProgress />
  }
  if (isNorthAmerica) {
    currentCasePerksProgress = <AmericaCasePerksProgress />
  }

  return (
    <Box width="auto" height="82px" justify="flex-start" align="normal">
      <StandartStatusContainer flexBasis="23%">
        <Box margin="0 10px" maxHeight="32px">
          {Icon && <Icon data-testid="CasePerks-Picture" />}
        </Box>

        <Box flexDirection="column" align="flex-start" minWidth="10em">
          <Typography
            data-testid="CasePerks-PartnerPerks"
            component="span"
            color="base900"
            letterSpacing="-0.3px"
          >
            <FormattedMessage id="perks.partnerPerks" />
          </Typography>
          <Typography
            data-testid="CasePerks-CurrentLevel"
            component="span"
            color="base900"
            textFontWeight="600"
          >
            <FormattedMessage id="perks.currentLevel" />
          </Typography>
          <Typography
            data-testid="CasePerks-Badge"
            component="span"
            color="base900"
            letterSpacing="-0.3px"
          >
            <FormattedMessage id="perks.badge" values={{ badge }} />
          </Typography>
        </Box>
      </StandartStatusContainer>

      {currentCasePerksProgress}
    </Box>
  )
}

const StandartStatusContainer = styled(Box)`
  border-right: 1px solid ${({ theme }) => theme.colors.base800};
`

export { CasePerks, Badge }
