import React, { useState } from 'react'

import { QuestionMarkMessage } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/QuestionMark/QuestionMarkMessage'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

interface QuestionMarkProps {
  'data-testid': string
}

const QuestionMark = (props: QuestionMarkProps) => {
  const [showFull, setShowFull] = useState(false)

  return (
    <QuestionMarkContainer
      width="16px"
      height="16px"
      data-testid={props['data-testid']}
      onMouseEnter={() => setShowFull(true)}
      onMouseLeave={() => setShowFull(false)}
    >
      <Typography component="span" variant="small" color="primary800" textFontWeight="600">
        ?
      </Typography>
      {showFull && <QuestionMarkMessage data-testid={`${props['data-testid']}-Message`} />}
    </QuestionMarkContainer>
  )
}
const QuestionMarkContainer = styled(Box)`
  position: relative;

  cursor: pointer;

  border: 1px solid ${({ theme }) => theme.colors.primary800};

  background-color: ${({ theme }) => theme.colors.primary100};
  border-radius: 50%;
`

export { QuestionMark }
