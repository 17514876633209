import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { caseListActions } from 'app/components/routes/CaseList/logic'
import { ActionButton } from 'app/components/ui/ActionButton'
import { Truck } from 'app/components/ui/Icons/common'
import { Padder } from 'app/components/ui/Padder'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Case } from 'app/core/domain/Case'
import styled from 'styled-components'

const TrackButton: React.FC<{ case: Case }> = (props) => {
  const dispatch = useDispatch()
  const onClick = useCallback(
    () => (e: React.MouseEvent) => {
      e.stopPropagation()
      dispatch(caseListActions.trackCaseButtonClicked({ case: props.case }))
    },
    [dispatch, props.case],
  )

  return (
    <ActionButton data-testid={`TrackButton-${props.case.caseId}`} onClick={onClick()}>
      <TruckIconContainer width="16px">
        <Truck width="16px" />
      </TruckIconContainer>
      <Padder width="5px" />
      {props.children}
    </ActionButton>
  )
}

const TruckIconContainer = styled(Box)`
  transform: scale(-1, 1);
  g {
    fill: ${({ theme }) => theme.colors.success300};
  }
`

export { TrackButton }
