import { caseSubmittedModalSelectors } from 'app/components/routes/CaseList/DoctorCaseList/CaseSubmittedModal/logic'
import { editScansActions } from 'app/components/routes/CaseWizard/steps/EditScans/logic'
import { DicomUploadStatus } from 'app/components/routes/Dicom/DicomUploadStatus'
import { appSelectors } from 'app/logic/app/logic'
import { RootAction, RootState } from 'app/logic/rootReducer'
import axios from 'axios'
import { combineEpics, Epic } from 'redux-observable'
import { from, of } from 'rxjs'
import { catchError, filter, mergeMap, switchMap } from 'rxjs/operators'
import { isActionOf } from 'typesafe-actions'

import { uploadDicomActions } from './logic'

const getTempCredsEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([uploadDicomActions.getTempCredentialsRequest])),
    mergeMap((action) => {
      const { caseId } = action.payload

      return from(axios.post(`/api/v1/cases/${caseId}/dcmTemporaryCredentials`)).pipe(
        mergeMap((response) => of(uploadDicomActions.getTempCredentialsReceived(response.data))),
        catchError((err) =>
          of(uploadDicomActions.getTempCredentialsFailed({ message: err.error })),
        ),
      )
    }),
  )

const deleteDicomEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([editScansActions.deleteDicomRequested])),
    switchMap(() => {
      const username = appSelectors.getUsername()(state$.value)
      const caseId = caseSubmittedModalSelectors.getCaseId()(state$.value)

      return from(axios.delete(`/api/v1/webapi/${username}/${caseId}/dicom`)).pipe(
        switchMap(() => of(editScansActions.updateDicomUploadStatus(false))),
        catchError(() => of(editScansActions.deleteDicomFailed())),
      )
    }),
  )

const getDicomPdfEpic: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf([uploadDicomActions.getDicomPdfRequested])),
    switchMap((action) =>
      from(
        axios
          .get<string>(
            `/api/v1/webapi${action.payload.getPresignedLink}?userName=${appSelectors.getUsername()(
              state$.value,
            )}`,
            { params: { language: appSelectors.getLanguage()(state$.value) } },
          )
          .then((res) => uploadDicomActions.getDicomPdfReceived(res.data))
          .catch((res) => uploadDicomActions.getDicomPdfFailed(res)),
      ),
    ),
  )

const dicomAuditEpic: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(
    filter(isActionOf([uploadDicomActions.dicomAuditRequested])),
    switchMap((action) => {
      const { caseId } = action.payload

      return from(
        axios
          .post(`/api/v1/cases/${caseId}/audit?auditType=dicom`)
          .then(() => {
            localStorage.setItem(`dicom-${caseId}`, DicomUploadStatus.UPLOAD_COMPLETE)
            window.close()
            return uploadDicomActions.dicomAuditReceived()
          })
          .catch(() => {
            localStorage.setItem(`dicom-${caseId}`, DicomUploadStatus.UPLOAD_COMPLETE)
            window.close()
            return uploadDicomActions.dicomAuditFailed()
          }),
      )
    }),
  )

const uploadDicomEpic = combineEpics(
  getTempCredsEpic,
  deleteDicomEpic,
  getDicomPdfEpic,
  dicomAuditEpic,
)

export { uploadDicomEpic }
