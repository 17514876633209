import React, { useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'
import { OrmcoSelect } from 'app/components/ui/Form/OrmcoSelect'
import { Language } from 'app/components/ui/Icons/common'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { COOKIES } from 'app/core/browser/COOKIES'
import { setCookie } from 'app/core/browser/cookieUtils'
import { getSameLanguagesMap } from 'app/core/getSameLanguagesMapUtil'
import { Locale } from 'app/core/i18n/Locale'
import { appActions, appSelectors } from 'app/logic/app/logic'
import styled from 'styled-components'

interface LanguageDropdownProps {
  'data-testid': string
}

const LanguageDropdown: React.FC<LanguageDropdownProps> = (props) => {
  const locales = useSelector(appSelectors.getLocales())
  const userLocale = useSelector(appSelectors.getLocale())
  const sameLanguagesMap = getSameLanguagesMap(locales)
  const intl = useIntl()
  const dispatch = useDispatch()
  const hasChoiceOfLanguages = locales.length > 1
  const onLanguageSelect = useCallback(
    (value) => {
      setCookie(`${COOKIES.impersonatorLocale}=${value}`)
      dispatch(appActions.localeChanged({ locale: value }))
      dispatch(appActions.termsPdfLinkRequested())
    },
    [dispatch],
  )

  return (
    <>
      {hasChoiceOfLanguages && (
        <LanguageSelectContainer data-testid={props['data-testid']}>
          <DropdownIcon>
            <Language width="20px" />
          </DropdownIcon>
          <LanguageSelect
            dropdownStyle={{ zIndex: 10000 }}
            id="languages"
            onChange={onLanguageSelect}
            defaultValue={userLocale}
            value={userLocale}
            suffixIcon={<div>▼</div>}
          >
            {locales.map((localeString: string) => {
              const locale = new Locale(localeString)
              const { languageCode, regionCode } = locale

              if (languageCode && regionCode) {
                const region = sameLanguagesMap[languageCode] > 1 ? regionCode : undefined
                const lang = intl.formatMessage({ id: 'language.code' }, { code: languageCode })

                return (
                  <Select.Option key={locale.localeString} value={locale.localeString}>
                    <Typography component="span" textFontWeight="inherit">
                      {lang}
                      {region}
                    </Typography>
                  </Select.Option>
                )
              }
              return <></>
            })}
          </LanguageSelect>
        </LanguageSelectContainer>
      )}
    </>
  )
}

const LanguageSelectContainer = styled(Box)`
  position: relative;

  width: 150px;
`

const DropdownIcon = styled.span`
  position: absolute;
  top: 5px;
  left: 10px;
  z-index: 1;
`

const LanguageSelect = styled(OrmcoSelect)<SelectProps<any>>`
  &&& {
    min-width: 150px;

    &.ant-select-open {
      z-index: ${({ theme }) => theme.zIndex.dropdown};
    }

    .ant-select-selection-item {
      padding-left: 20px;
    }
  }
`

export { LanguageDropdown }
