import { FC } from 'react'
import { Link } from 'react-router-dom'

import { Typography } from 'app/components/ui/SDS/common/Typography'

import { DropdownItemProps } from './interfaces'
import { DropdownItemContainer } from './styles'


export const DropdownItem: FC<DropdownItemProps> = ({
  href,
  onClick,
  isLink,
  children,
  'data-testid': dataTestId,
}) => {
  if (isLink) {
    return (
      <Link
        className="dropdown-item__header-link"
        data-testid={dataTestId}
        to={href}
        onClick={onClick}
      >
        <DropdownItemContainer>
          <Typography
            data-testid={`${dataTestId}-Text`}
            component="span"
            textFontWeight="600"
            whiteSpace="nowrap"
            color="primary800"
          >
            {children}
          </Typography>
        </DropdownItemContainer>
      </Link>
    )
  }

  return (
    <a
      className="dropdown-item__header-link"
      data-testid={dataTestId}
      href={href}
      onClick={onClick}
    >
      <DropdownItemContainer>
        <Typography
          data-testid={`${dataTestId}-Text`}
          component="span"
          textFontWeight="600"
          whiteSpace="nowrap"
          color="primary800"
        >
          {children}
        </Typography>
      </DropdownItemContainer>
    </a>
  )
}
