import { Box } from 'app/components/ui/SDS/common/Box'
import styled from 'styled-components'

import { StyledNavigationDropdownProps } from './interfaces'

export const StyledNavigationDropdown = styled(Box)<StyledNavigationDropdownProps>`
  margin: 0 12px;
  position: relative;

  cursor: pointer;
  //TODO: move all the svg icon from ant.d to the application
  .dropdown-content {
    overflow: hidden;

    border-bottom: 2px solid
      ${({ theme, isActive }) => (isActive ? theme.colors.white : 'transparent')};

    & .anticon {
      vertical-align: sub;
    }

    .mainIcon svg {
      width: 16px;
      height: auto;
    }

    .carpetIcon svg {
      padding-bottom: 4px;

      width: 8px;
      height: auto;
    }

    & span,
    & svg {
      color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.primary300)};
    }

    &:hover,
    &:focus,
    &:focus-within,
    &:active {
      span,
      svg {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`
