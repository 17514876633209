import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'

import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { OrmcoRadioGroup } from 'app/components/ui/Form/OrmcoRadioGroup'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'

import { ArchesToTreatDataTestIdMap } from './ArchesToTreatDataTestIdMap'
import { ArchesToTreatRadio } from './ArchesTreatRadio'

const LABEL_KEY = 'caseWizard.casePrescription.affectedArche.label'
const PROPERTY_KEY = 'primaryCasePreferences.treatArches'
const CROWDING_LOWER_KEY = 'primaryCasePreferences.resolveCrowdingByLower'
const CROWDING_UPPER_KEY = 'primaryCasePreferences.resolveCrowdingByUpper'

interface ArchesToTreatProps {
  defaultArch?: TreatArches
}

const ArchesToTreat: React.FC<ArchesToTreatProps> = (props) => {
  const form = useFormContext<CaseDraft>()

  const { defaultArch } = props

  const defaultArchToTreat = defaultArch || TreatArches.BOTH
  const propertyValue = form.getValues(PROPERTY_KEY)
  const archTypes = Object.keys(ArchesToTreatDataTestIdMap)

  const changeRadio = useCallback(
    (res?: TreatArches) => {
      if (res === 'UPPER_ONLY') {
        form.setValue(CROWDING_LOWER_KEY, [])
      }

      if (res === 'LOWER_ONLY') {
        form.setValue(CROWDING_UPPER_KEY, [])
      }

      form.setValue(PROPERTY_KEY, res)
    },
    [form],
  )

  return (
    <OrmcoFormItemVertical
      label={<OrmcoFormItemVerticalHeader headerTextId={LABEL_KEY} />}
      hasFeedback
      data-testid="ArchesToTreat"
    >
      <OrmcoRadioGroup
        defaultValue={defaultArchToTreat}
        onChange={(selected) => changeRadio(selected.target.value)}
        value={propertyValue}
      >
        {archTypes.map((archType) => (
          <ArchesToTreatRadio key={archType} value={archType} />
        ))}
      </OrmcoRadioGroup>
    </OrmcoFormItemVertical>
  )
}

export { ArchesToTreat }
