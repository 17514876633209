import { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { TreatmentAction } from 'app/components/routes/CaseWizard/steps/CasePrescription/TreatmentAction'
import { OrmcoCheckbox } from 'app/components/ui/Form/OrmcoCheckbox'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ApRelationshipPrimary } from 'app/core/domain/ApRelationshipPrimary'
import { CaseDraft } from 'app/core/domain/CaseDraft'

const PROPERTY_KEY = 'primaryCasePreferences.apRelationshipPrimary'

interface CanineMolarSettingsProps {
  onChange: (value: ApRelationshipPrimary) => void
}

const CanineMolarSettings = ({ onChange }: CanineMolarSettingsProps) => {
  const form = useFormContext<CaseDraft>()

  const apRelationshipPrimary = form.getValues(PROPERTY_KEY)

  const setApPrelationshipDistalization = useCallback(
    (selected: CheckboxChangeEvent) =>
      onChange({
        ...apRelationshipPrimary,
        aPrelationshipDistalization: selected.target.checked,
      }),
    [apRelationshipPrimary, onChange],
  )

  const setPosteriorIPR = useCallback(
    (selected: CheckboxChangeEvent) =>
      onChange({
        ...apRelationshipPrimary,
        posteriorIPR: selected.target.checked,
      }),
    [apRelationshipPrimary, onChange],
  )

  if (apRelationshipPrimary?.apActionPrimary !== TreatmentAction.CORRECT) {
    return null
  }

  return (
    <>
      <Box justify="flex-start">
        <OrmcoCheckbox
          onChange={setApPrelationshipDistalization}
          checked={apRelationshipPrimary?.aPrelationshipDistalization === true}
          data-testid="AnteriorPosteriorRelationship-CanineMolar-Distalization"
        >
          <Typography component="span">
            <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.doDistalization" />
          </Typography>
        </OrmcoCheckbox>
      </Box>

      <Box justify="flex-start">
        <OrmcoCheckbox
          onChange={setPosteriorIPR}
          checked={apRelationshipPrimary?.posteriorIPR === true}
          data-testid="AnteriorPosteriorRelationship-CanineMolar-PosteriorIPR"
        >
          <Typography component="span">
            <FormattedMessage id="caseWizard.casePrescription.anteriorPosteriorRelationship.posteriorIpr" />
          </Typography>
        </OrmcoCheckbox>
      </Box>
    </>
  )
}

export { CanineMolarSettings }
