import { AccessTokenPayload } from 'app/core/auth/AccessTokenPayload'
import { TokenGrantResult } from 'app/core/auth/TokenGrantResult'
import { EnvironmentParams } from 'app/core/domain/EnvironmentParams'
import { UserProfile } from 'app/core/domain/UserProfile'
import { ensure } from 'app/core/lang/TypeUtils'
import { createForm } from 'app/core/net/FormUtils'
import { MimeTypes } from 'app/core/net/MimeTypes'
import axios, { AxiosResponse } from 'axios'
import { fromUnixTime, isAfter, subSeconds } from 'date-fns/fp'
import jwtDecode from 'jwt-decode'
import { from, NEVER, Observable, of } from 'rxjs'
import { map, tap } from 'rxjs/operators'
import URIJS from 'urijs'

import { AUTHENTICATED_KEY } from './AuthenticationBroadcast'

const AUTHORIZE_ENDPOINT = URIJS(EnvironmentParams.SSO_URL).pathname('/oauth/authorize').href()

const TOKEN_ENDPOINT = URIJS(EnvironmentParams.SSO_URL).pathname('/oauth/token').href()

const UI_CLIENT_ID = 'aligners_ui'

const SAVED_URL_KEY = 'savedUrl'

class AuthenticationManager {
  private _refreshToken?: string

  private _accessToken?: string

  private decodedAccessToken?: AccessTokenPayload

  get accessToken(): string | undefined {
    return this._accessToken
  }

  get refreshToken(): string | undefined {
    return this._refreshToken
  }

  constructor() {
    this.handleTokenGrantResponse = this.handleTokenGrantResponse.bind(this)
    this.getProfile = this.getProfile.bind(this)
    this.isExpired = this.isExpired.bind(this)
  }

  authenticate(): Observable<UserProfile> {
    const authorizationCode: string = (URIJS(window.location.href).query(true) as any).code

    if (!authorizationCode) {
      sessionStorage.setItem(SAVED_URL_KEY, window.location.href)
      window.location.href = URIJS(AUTHORIZE_ENDPOINT)
        .addQuery({
          response_type: 'code',
          client_id: UI_CLIENT_ID,
          redirect_uri: window.location.origin,
        })
        .href()
      return NEVER
    }

    return from(
      axios.post<TokenGrantResult>(
        TOKEN_ENDPOINT,
        createForm({
          code: authorizationCode,
          grant_type: 'authorization_code',
          client_id: UI_CLIENT_ID,
          redirect_uri: window.location.origin,
        }),
        {
          headers: {
            'Content-Type': MimeTypes.URL_FORM_ENCODED,
          },
        },
      ),
    ).pipe(map(this.handleTokenGrantResponse), tap(AuthenticationManager.restoreUserUrl))
  }

  private handleTokenGrantResponse(resp: AxiosResponse<TokenGrantResult>): UserProfile {
    const tokenResp = resp.data
    const tokenData: AccessTokenPayload = jwtDecode(tokenResp.access_token)

    this._accessToken = tokenResp.access_token
    this._refreshToken = tokenResp.refresh_token
    this.decodedAccessToken = tokenData

    localStorage.setItem(AUTHENTICATED_KEY, 'true')

    return this.getProfile()
  }

  private static restoreUserUrl() {
    const savedUrl = sessionStorage.getItem(SAVED_URL_KEY)

    if (savedUrl) {
      window.history.replaceState(null, document.title, savedUrl)
      sessionStorage.removeItem(SAVED_URL_KEY)
    }
  }

  private getProfile(): UserProfile {
    const decodedToken = ensure(this.decodedAccessToken)

    return {
      username: decodedToken.user_name,
      firstName: decodedToken.profile.firstName,
      middleName: decodedToken.profile.middleName,
      roles: [...decodedToken.authorities],
      lastName: decodedToken.profile.lastName,
      impersonator: decodedToken.impersonator,
    }
  }

  isExpired(): boolean {
    const tokenExpirationGap = 60
    const decodedToken = ensure(this.decodedAccessToken)
    const expirationTime = subSeconds(tokenExpirationGap, fromUnixTime(decodedToken.exp))

    return isAfter(expirationTime, Date.now())
  }

  updateToken(): Observable<UserProfile> {
    if (this.isExpired()) {
      return from(
        axios.post<TokenGrantResult>(
          TOKEN_ENDPOINT,
          createForm({
            grant_type: 'refresh_token',
            refresh_token: this._refreshToken,
            client_id: UI_CLIENT_ID,
          }),
        ),
      ).pipe(map(this.handleTokenGrantResponse))
    }
    return of(this.getProfile())
  }
}

const AuthenticationManagerInstance = new AuthenticationManager()

export { AuthenticationManagerInstance as AuthenticationManager }
