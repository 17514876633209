import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { Badge } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/Badge'
import { QuestionMark } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/QuestionMark/QuestionMark'
import { casePerksSelectors } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'

const ShippedCasePerks = () => {
  const cases = useSelector(casePerksSelectors.getTotalCases()) || 0

  return (
    <Box data-testid="AustraliaCasePerks" height="36px" padding="0 36px">
      <Box>
        <Typography data-testid="AustraliaCasePerks-Total" component="span">
          {cases > 0 && (
            <Typography component="span" textFontWeight="600">
              {cases}
            </Typography>
          )}
          &nbsp;
          <FormattedMessage id="perks.progress.total" values={{ count: cases }} />
        </Typography>
      </Box>
      <Box width="auto" marginLeft="auto">
        <QuestionMark data-testid="AustraliaCasePerks-Help" />
      </Box>
    </Box>
  )
}

export { ShippedCasePerks, Badge }
