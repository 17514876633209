import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import { unarchiveModalActions } from 'app/components/routes/CaseList/DoctorCaseList/DoctorActionButtons/UnarchiveModal/logic'
import { ActionButton } from 'app/components/ui/ActionButton'
import { Archived } from 'app/components/ui/Icons/common'
import { Padder } from 'app/components/ui/Padder'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Case } from 'app/core/domain/Case'
import styled from 'styled-components'

const UnarchiveButton: React.FC<{ case: Case }> = (props) => {
  const dispatch = useDispatch()
  const { caseId } = props.case
  const click = useCallback(
    () => (e: React.MouseEvent) => {
      e.stopPropagation()
      dispatch(unarchiveModalActions.openModalButtonClicked({ caseId }))
    },
    [caseId, dispatch],
  )

  return (
    <ActionButton data-testid={caseId} onClick={click()}>
      <IconContainer width="auto">
        <Archived />
      </IconContainer>
      <Padder width="5px" />
      <FormattedMessage id="cases.list.doctor.button.unarchive" />
    </ActionButton>
  )
}

const IconContainer = styled(Box)`
  g {
    fill: ${({ theme }) => theme.colors.primary500};
  }
`

export { UnarchiveButton }
