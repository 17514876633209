import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { DoctorCaseTabs } from 'app/components/routes/CaseList/DoctorCaseCount'
import { caseListActions, caseListSelectors } from 'app/components/routes/CaseList/logic'
import { ActionRequired, InProgress, Truck, Archived } from 'app/components/ui/Icons/common'
import { Padder } from 'app/components/ui/Padder'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import styled from 'styled-components'

const TABS_ITEMS = [
  { key: DoctorCaseTabs.actionRequired, Icon: ActionRequired },
  { key: DoctorCaseTabs.withOrmco, Icon: InProgress },
  { key: DoctorCaseTabs.shipped, Icon: Truck },
  { key: DoctorCaseTabs.archived, Icon: Archived },
] as const

const TabFilters: React.FC = () => {
  const dispatch = useDispatch()
  const counters = useSelector(caseListSelectors.getDoctorCaseFiltersCounters())
  const activeFilter = useSelector(caseListSelectors.getDoctorActiveCaseFilter())

  const tabClickCallback = useCallback(
    (name: keyof typeof DoctorCaseTabs) => () => {
      dispatch(caseListActions.doctorActiveCaseTabClicked(name))
    },
    [dispatch],
  )

  return (
    <TabFilterContainer justify="flex-start" data-testid="TabFilters">
      <Padder width="14px" />
      {TABS_ITEMS.map(({ key, Icon }) => (
        <Tab
          margin="0 4px"
          padding="0 8px"
          justify="space-around"
          width="auto"
          key={key}
          active={activeFilter === key}
          onClick={tabClickCallback(key)}
          data-testid={`TabFilters-Tab-${key}`}
        >
          <Icon />
          <Box paddingLeft="12px" justify="flex-start" flexDirection="column" align="flex-start">
            <Typography
              component="span"
              variant="small"
              color={activeFilter === key ? 'primary800' : 'base900'}
              textFontWeight="600"
            >
              <FormattedMessage id={`cases.counter.${key}`} />
            </Typography>
            <Typography
              component="span"
              variant="small"
              color={activeFilter === key ? 'primary800' : 'base900'}
              textFontWeight="600"
            >
              {counters[key]}
            </Typography>
          </Box>
        </Tab>
      ))}
      <BottomLine />
    </TabFilterContainer>
  )
}

const BottomLine = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 1px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.primary600};
`

interface TabProps {
  active?: boolean
}

const Tab = styled(Box)<TabProps>`
  position: relative;
  z-index: ${({ theme }) => theme.zIndex.layer1};

  cursor: pointer;

  height: 42px;
  border: 1px solid
    ${({ active, theme }) => (active ? theme.colors.primary600 : theme.colors.base700)};
  border-bottom: 1px solid
    ${({ active, theme }) => (active ? 'rgba(0,0,0,0)' : theme.colors.primary600)};

  background-color: white;
  border-radius: 4px 4px 0 0;

  g {
    fill: ${({ active, theme }) => (active ? theme.colors.primary800 : theme.colors.base700)};
  }

  &:hover {
    border-bottom: 1px solid
      ${({ active, theme }) => (active ? theme.colors.white : theme.colors.primary600)};

    background-color: ${({ active, theme }) =>
      active ? theme.colors.white : 'rgba(217,226,233,0.29)'};
  }
`

const TabFilterContainer = styled(Box)`
  position: relative;
`

export { TabFilters }
