import { FC, SVGProps } from 'react'

import styled, { css } from 'styled-components'

interface IconsProps {
  width?: string
}

const iconStyle = css`
  display: block;

  width: 100%;
  height: auto;
`

const createSVGIcon = (Icon: FC<SVGProps<SVGSVGElement>>, initWidth: string) => styled(
  Icon,
)<IconsProps>`
  max-width: ${({ width = initWidth }) => width};

  ${iconStyle}
`

export { IconsProps, createSVGIcon }
