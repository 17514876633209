import { configureStore } from '@reduxjs/toolkit'
import { rootEpic } from 'app/logic/rootEpic'
import { RootAction, rootReducer, RootState } from 'app/logic/rootReducer'
import { createEpicMiddleware } from 'redux-observable'

const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState>({
  dependencies: {
    get store() {
      return store
    },
  },
})

const store = configureStore({ reducer: rootReducer, middleware: [epicMiddleware] })

epicMiddleware.run(rootEpic)

export { store }
