import React, { memo } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'

import { AddAddress } from 'app/components/routes/Address/AddAddress'
import { Footer } from 'app/components/routes/BasicPage/Footer/Footer'
import { Header } from 'app/components/routes/BasicPage/Header/Header'
import { CaseList, DoctorCaseListQueryUrlParams } from 'app/components/routes/CaseList/CaseList'
import { DoctorCaseCount, DoctorCaseTabs } from 'app/components/routes/CaseList/DoctorCaseCount'
import { HybridCaseWizardPage } from 'app/components/routes/CaseWizard/hybrid/HybridCaseWizard'
import { MyProfile } from 'app/components/routes/MyProfile/MyProfile'
import { AddStaffPage } from 'app/components/routes/Staff/AddStaff'
import { DoctorDetails } from 'app/components/routes/Staff/DoctorDetails/DoctorDetails'
import { MyPractice } from 'app/components/routes/Staff/MyPractice'
import { Header as SDSHeader, Footer as SDSFooter } from 'app/components/ui/SDS'
import { Box } from 'app/components/ui/SDS/common/Box'
import { parseQuery } from 'app/core/browser/LocationUtils'
import { featureFlagSelectors } from 'app/logic/features/logic'
import { Location } from 'history'

import { BasicPageContainer } from './BasicPage.style'
import { GlobalModalsProvider } from './GlobalModalsProvider'

const parseDoctorQueryParams = (location: Location) => {
  let {
    tab,
    search,
  }: {
    tab?: keyof DoctorCaseCount
    search?: string
  } = parseQuery<DoctorCaseListQueryUrlParams>(location.search)

  if (!search && !tab) {
    tab = DoctorCaseTabs.actionRequired
    search = undefined
  }
  return { tab, search }
}
const BasicPage: React.FC = memo(() => {
  const isSDS = useSelector(featureFlagSelectors.isSDS())

  return (
    <>
      <BasicPageContainer isSDS={isSDS}>
        {isSDS ? <SDSHeader /> : <Header />}
        <GlobalModalsProvider />

        <Switch>
          <Route path="/homePage">
            <HomePageContainer />
          </Route>
          <Route path="/caseWizard">
            <HybridCaseWizardPage />
          </Route>
          <Route path="/myPractice">
            <MyPractice />
          </Route>
          <Route path="/createUpdateStaff">
            <AddStaffPage />
          </Route>
          <Route path="/doctorDetails">
            <DoctorDetails />
          </Route>
          <Route path="/myProfile">
            <Box>
              <MyProfile />
            </Box>
          </Route>
          <Route path="/addAddress">
            <AddAddress />
          </Route>
          <Route path="/editAddress/:username/:id">
            <AddAddress />
          </Route>
          <Route render={() => <Redirect to="/homePage" />} />
        </Switch>
        {isSDS ? <SDSFooter /> : <Footer />}
      </BasicPageContainer>
    </>
  )
})

const HomePageContainer = () => {
  const location = useLocation()
  const { tab, search }: DoctorCaseListQueryUrlParams = parseDoctorQueryParams(location)
  const { key } = location

  return <CaseList key={key} tab={tab} search={search} />
}

export { BasicPage }
