import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'formdata-polyfill/formdata.min'
import 'scroll-behavior-polyfill'
import 'intl-pluralrules'
import 'normalize.css'
import React from 'react'
import ReactDOM from 'react-dom'

import { App } from 'app/App'
import 'app/core/net/axiosConfig'
import { AuthenticationManager } from 'app/core/auth/AuthenticationManager'
import { SystemDictionaries } from 'app/core/domain/Http/SystemDictionaries'
import { SiteMap } from 'app/core/react/SiteMap'
import { appActions, appSelectors } from 'app/logic/app/logic'
import { store } from 'app/logic/store'
import axios from 'axios'
import { from } from 'rxjs'
import { map, switchMap } from 'rxjs/operators'

AuthenticationManager.authenticate()
  .pipe(
    switchMap((profile) =>
      from(axios.get<SystemDictionaries>('/api/v1/systemInfo/dictionaries')).pipe(
        map((res) => ({ profile, dictionaries: res.data })),
      ),
    ),
  )
  .subscribe({
    next: ({ profile, dictionaries }) => {
      store.dispatch(appActions.profileInitialized(profile))
      store.dispatch(appActions.systemInfoRequestFinished(dictionaries))
      const isDoctor = appSelectors.isDoctor()(store.getState())
      const isStaff = appSelectors.isStaff()(store.getState())

      if (!isDoctor && !isStaff) {
        // currently not implemented for any other role
        window.location.replace(SiteMap.legacyHomePage())
      } else {
        ReactDOM.render(<App />, document.getElementById('root'))
      }
    },
    error: (error) => {
      console.error(error)
    },
  })
