import { Select } from 'antd'
import styled from 'styled-components'

import { StyledSelectProps } from './interfaces'

export const StyledSelect = styled(Select)<StyledSelectProps>`
  & .ant-select-selector {
    border: 1px solid ${({ theme, isDark }) => isDark && theme.colors.whiteTransparent30} !important;

    background: ${({ theme, isDark }) => isDark && theme.colors.whiteTransparent10} !important;
  }

  & .ant-select-selection-item {
    color: ${({ theme, isDark }) => isDark && theme.colors.white} !important;
  }

  & .ant-select-arrow {
    color: ${({ theme, isDark }) => isDark && theme.colors.white} !important;
  }
`
