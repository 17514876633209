import React from 'react'

import { Nullable } from 'app/core/types/utils'
import styled, { css, ThemeColors } from 'styled-components'

import { Spinner } from './Icons/common'
import { Box } from './SDS/common/Box'

interface LoaderProps {
  isAbsoluteCenter?: boolean
  width?: string
  height?: string
  zIndex?: Nullable<number>
  fill?: keyof ThemeColors
  margin?: string
  className?: string
}

const Loader: React.FC<LoaderProps> = ({
  isAbsoluteCenter,
  fill,
  width = '32px',
  height = '32px',
  zIndex = null,
  margin,
  className,
}) => (
  <LoaderContainer
    isAbsoluteCenter={isAbsoluteCenter}
    width={width}
    height={height}
    zIndex={zIndex}
    margin={margin}
    className={className}
  >
    <Spinner fill={fill} />
  </LoaderContainer>
)

const absoluteCenterMixin = css`
  position: absolute;
  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
`

const LoaderContainer = styled(Box)<LoaderProps>`
  margin: ${({ margin }) => margin};
  z-index: ${({ zIndex }) => zIndex};
  fill: ${({ theme, fill }) => (fill ? theme.colors[fill] : theme.colors.primary800)};

  ${({ isAbsoluteCenter }) => isAbsoluteCenter && absoluteCenterMixin};
`

export { Loader }
