import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'

import { casePerksSelectors } from 'app/components/routes/CaseList/DoctorCaseList/CasePerksDashboard/logic'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { Region } from 'app/core/domain/Region'
import styled from 'styled-components'

interface QuestionMarkMessageProps {
  'data-testid': string
}

const QuestionMarkMessage = (props: QuestionMarkMessageProps) => {
  const region = useSelector(casePerksSelectors.getRegion())
  const showShippedCasesPerks = useSelector(casePerksSelectors.getShowShippedCasesPerks())
  let text

  if ((region === Region.NORTH_AMERICA || region === Region.EUROPE) && !showShippedCasesPerks) {
    text = (
      <>
        <Typography color="primary800" margin="0 0 13px">
          <FormattedMessage id="perks.fullHelpMessage1" />
          <FormattedMessage id="perks.fullHelpMessage3" />
        </Typography>
        <Typography color="primary800">
          <FormattedMessage id="perks.fullHelpMessage4" />
        </Typography>
      </>
    )
  } else if (region === Region.AUSTRALIA || showShippedCasesPerks) {
    text = (
      <Typography color="primary800">
        <FormattedMessage id="perks.helpMessage" />
      </Typography>
    )
  }
  return (
    <QuestionMarkMessageContainer
      padding="16px"
      width="356px"
      display="block"
      data-testid={props['data-testid']}
    >
      {text}
    </QuestionMarkMessageContainer>
  )
}

const QuestionMarkMessageContainer = styled(Box)`
  position: absolute;
  top: 25px;
  right: -15px;
  z-index: ${({ theme }) => theme.zIndex.notification};

  background-color: ${({ theme }) => theme.colors.primary100};
  box-shadow: 0 -1px 4px 2px rgba(0, 0, 0, 0.26);
  border-radius: 5px;

  &:before {
    position: absolute;
    top: -19px;
    right: 12px;

    content: '';

    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-bottom: ${({ theme }) => theme.colors.base200};
  }
`

export { QuestionMarkMessage }
