import { useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { Col, Row } from 'antd'
import { HorizontalLine } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/HorizontalLine'
import { OrmcoFormItemVertical } from 'app/components/ui/Form/OrmcoFormItemVertical'
import { OrmcoFormItemVerticalHeader } from 'app/components/ui/Form/OrmcoFormItemVerticalHeader'
import { Box } from 'app/components/ui/SDS/common/Box'
import { Typography } from 'app/components/ui/SDS/common/Typography'
import { ThemeContext, ThemeType, ContextThemeProps } from 'app/components/ui/Theme/ThemeContext'
import { Arch, getArchTeethNumbers, Universal } from 'app/core/domain/ArchTools'
import { CaseDraft } from 'app/core/domain/CaseDraft'
import { TreatArches } from 'app/core/domain/PrimaryCasePreferences'
import { addOrRemoveFromArray } from 'app/core/lang/ArrayUtils'
import { useEffectSomeDepsChange } from 'app/core/react/CustomHooks'
import styled from 'styled-components'

import { ActiveAuxiliarySelector } from './ActiveAuxiliarySelector'
import { ActivitySelection } from './ActivitySelection'
import { AuxiliariesSummary } from './Summary/AuxiliariesSummary'
import { AuxClickableTeeth } from './TeethPanel/AuxClickableTeeth'
import { useAvailableAuxiliaries } from './useAvailableAuxiliaries'

const TeethSection = () => {
  const form = useFormContext<CaseDraft>()
  const [activeSelect, setActiveSelect] = useState<keyof typeof ActivitySelection>(
    ActivitySelection.dontMoveTeeth,
  )
  const {
    primaryCasePreferences: { treatArches },
  } = form.getValues()

  const availableAuxiliaries = useAvailableAuxiliaries()

  useEffectSomeDepsChange(() => {
    const removeTeethFromAux = (
      teeth: Array<Universal>,
      selection: ActivitySelection,
      arches?: TreatArches,
    ) => {
      let selectedArchTeethNumbers: Array<Universal> = []

      if (arches === TreatArches.LOWER_ONLY) {
        selectedArchTeethNumbers = getArchTeethNumbers(Arch.LOWER)
      }
      if (arches === TreatArches.UPPER_ONLY) {
        selectedArchTeethNumbers = getArchTeethNumbers(Arch.UPPER)
      }

      const teethToRemove: Array<Universal> = []

      if (teeth) {
        teeth.forEach((n) => {
          if (!selectedArchTeethNumbers.includes(n)) {
            teethToRemove.push(n)
          }
        })

        const res = addOrRemoveFromArray(teethToRemove, teeth)

        form.setValue(`primaryCasePreferences.${selection}`, res)
      }
    }

    if (treatArches !== TreatArches.BOTH) {
      availableAuxiliaries.forEach(({ arr, type }) => removeTeethFromAux(arr, type, treatArches))
    }
  }, [treatArches])

  const theme = useContext(ThemeContext)

  return (
    <OrmcoFormItemVertical
      label={
        <OrmcoFormItemVerticalHeader headerTextId="caseWizard.casePrescription.teethSection.label" />
      }
      hasFeedback
    >
      <TeethInstructions align="middle">
        <FormattedMessage id="caseWizard.casePrescription.teethSection.instructions" />
      </TeethInstructions>
      <Row>
        <TeethSelectionContainer span={16} contextTheme={theme} data-testid="TeethSelection">
          <Box marginTop="28px" data-testid="TeethSelection-ClickableTeeth">
            <AuxClickableTeeth
              auxiliaries={availableAuxiliaries}
              activeSelect={activeSelect}
              data-testid="TeethSelection-Aux"
            />
          </Box>
          <Row>
            <Box marginTop="14px" justify="space-between">
              <Typography data-testid="TeethSelection-Right" component="label">
                <FormattedMessage id="caseWizard.casePrescription.resolveCrowdingBy.extraction.right" />
              </Typography>
              <HorizontalLine />
              <Typography data-testid="TeethSelection-Left" component="label">
                <FormattedMessage id="caseWizard.casePrescription.resolveCrowdingBy.extraction.left" />
              </Typography>
            </Box>
          </Row>
        </TeethSelectionContainer>
        <AuxiliariesSummary auxiliaries={availableAuxiliaries} />
      </Row>
      <TeethSelectorContainer justify="start">
        <Col span={3}>
          <ActiveAuxiliarySelector
            auxiliaries={availableAuxiliaries}
            selected={activeSelect}
            onSelect={setActiveSelect}
            data-testid="TeethSelection-ActivitySelector"
          />
        </Col>
        <IconIntructions>
          <Typography component="label">
            <FormattedMessage id="caseWizard.casePrescription.teethSection.iconInstructions" />
          </Typography>
        </IconIntructions>
      </TeethSelectorContainer>
    </OrmcoFormItemVertical>
  )
}
const TeethSelectorContainer = styled(Row)`
  padding-top: 13px;
`
const TeethInstructions = styled(Row)`
  height: 40px;
`
const IconIntructions = styled(Col)`
  display: flex;
  align-items: center;
`

const TeethSelectionContainer = styled(Col)<ContextThemeProps>`
  &&& {
    padding: 25px;

    background-color: ${({ theme, contextTheme }) =>
      contextTheme === ThemeType.BOOTSTRAP ? theme.colors.base100 : theme.colors.primary10};
    border-radius: 4px;
  }
`

export { TeethSection }
