import { Nullable } from 'app/core/types/utils'
import { RootState } from 'app/logic/rootReducer'
import { ActionType, createReducer, createAction } from 'typesafe-actions'

export interface AmazonStaticImagePayload {
  method: string
  url?: string
}

interface TruGenXRModalState {
  isTrugenXRModelOpen: boolean
  trugenXRGifUrl: Nullable<string>
  initBrandingPopUpValue: boolean
}

const INITIAL_STATE = {
  initBrandingPopUpValue: false,
  isTrugenXRModelOpen: false,
  trugenXRGifUrl: null,
}

const truGenXRModalActions = {
  truGenModalUnmounted: createAction('@TRUGEN_MODAL/MODAL_UNMOUNTED')(),
  setInitialTruGenModalState: createAction(
    '@TRUGEN_MODAL/SET_INITIAL_TRUGEN_MODEL_STATE',
  )<boolean>(),
  toggleTruGenModal: createAction('@TRUGEN_MODAL/TRUGEN_MODEL_OPEN')<boolean>(),

  trugenModalDontShowUpdateRequested: createAction(
    '@TRUGEN_MODAL/DONT_SHOW_UPDATE_REQUESTED',
  )<boolean>(),
  trugenModalDontShowUpdateFinished: createAction('@TRUGEN_MODAL/DONT_SHOW_UPDATE_FINISHED')(),
  trugenModalDontShowUpdateFailed: createAction('@TRUGEN_MODAL/DONT_SHOW_UPDATE_FAILED')(),

  primaryTruGenXRGifRequested: createAction('@TRUGEN_MODAL/TRUGEN_GIF_URL_REQUESTED')(),
  primaryTruGenXRGifReceived: createAction(
    '@TRUGEN_MODAL/TRUGEN_GIF_URL_RECEIVED',
  )<AmazonStaticImagePayload>(),
  primaryTruGenXRGifFailed: createAction('@TRUGEN_MODAL/TRUGEN_GIF_URL_FAILED')(),
}

type TruGenXRModalActions = ActionType<typeof truGenXRModalActions>
const truGenXRModalReducer = createReducer<TruGenXRModalState, TruGenXRModalActions>(INITIAL_STATE)
  .handleAction([truGenXRModalActions.setInitialTruGenModalState], (state, action) => ({
    ...state,
    initBrandingPopUpValue: action.payload,
    isTrugenXRModelOpen: action.payload,
  }))
  .handleAction([truGenXRModalActions.toggleTruGenModal], (state, action) => ({
    ...state,
    isTrugenXRModelOpen: action.payload,
  }))
  .handleAction([truGenXRModalActions.trugenModalDontShowUpdateRequested], (state, action) => ({
    ...state,
    initBrandingPopUpValue: !action.payload,
  }))
  .handleAction([truGenXRModalActions.primaryTruGenXRGifReceived], (state, action) => ({
    ...state,
    trugenXRGifUrl: action.payload?.url ? action.payload.url : null,
  }))
  .handleAction([truGenXRModalActions.truGenModalUnmounted], (state) => ({
    ...state,
    trugenXRGifUrl: null,
    isTrugenXRModelOpen: state.initBrandingPopUpValue,
  }))

const truGenXRModalSelectors = {
  getIsTrugenXRModelOpen: () => (state: RootState) => state.truGenXRModal.isTrugenXRModelOpen,
  getPrimaryTruGenXRGif: () => (state: RootState) => state.truGenXRModal.trugenXRGifUrl,
}

export {
  TruGenXRModalState,
  TruGenXRModalActions,
  truGenXRModalActions,
  truGenXRModalSelectors,
  truGenXRModalReducer,
}
