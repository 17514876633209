import { Button } from 'antd'
import styled, { css } from 'styled-components'

const btnFlexContentCenterStyle = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const btnFontStyle = css`
  font-family: 'Montserrat';
  font-size: 13px;
  font-weight: 400;
`

const commonBtnTextColor = css`
  color: ${({ theme }) => theme.colors.base700};
`

const tableCellBtnCommonStyles = css`
  height: 18px;

  line-height: 0;
`

export const TableCellStyledButton = styled(Button)`
  margin-right: 5px;
  padding: 0 3px;

  border-color: ${({ theme }) => theme.colors.base300};

  background: linear-gradient(180deg, #fbfbfb 0%, #e1e1e1 99.14%);
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  border-radius: 4px;

  ${btnFontStyle}
  ${commonBtnTextColor}
  ${btnFlexContentCenterStyle}
  ${tableCellBtnCommonStyles}
  
  &:active, &:focus, &:hover {
    border-color: ${({ theme }) => theme.colors.base300};
    ${commonBtnTextColor}
  }
`

export const OnHoldLinkBtn = styled(Button)`
  padding: 0;

  text-decoration: underline;

  color: ${({ theme }) => theme.colors.primary700};

  ${btnFontStyle}
  ${btnFlexContentCenterStyle}
  ${tableCellBtnCommonStyles}
  
  &:active, &:focus, &:hover {
    text-decoration: underline !important;
  }
`
