import React, { useContext } from 'react'

import { ThemedBox } from 'app/components/routes/CaseWizard/steps/CasePrescription/ResolveCrowding/ResolveCrowding'
import { Scan } from 'app/components/routes/CaseWizard/steps/EditScans/Scan'
import { ScanType } from 'app/components/routes/CaseWizard/steps/EditScans/ScanType'
import { ThreeShapeScan } from 'app/components/routes/CaseWizard/steps/EditScans/ThreeShapeScan'
import { ThemeContext } from 'app/components/ui/Theme/ThemeContext'
import styled from 'styled-components'

import { STLUploadButton } from './STLUploadButton'

interface DisplayScanTypeProps {
  selectedScan: Scan
}

const DisplayScanType: React.FC<DisplayScanTypeProps> = (props) => {
  const { selectedScan } = props
  const theme = useContext(ThemeContext)

  return (
    <BoxMargin contextTheme={theme}>
      {selectedScan.name === ScanType.THREESHAPESCANS ? (
        <ThreeShapeScan />
      ) : (
        selectedScan.uploadScan && <STLUploadButton />
      )}
    </BoxMargin>
  )
}

const BoxMargin = styled(ThemedBox)`
  margin-top: 10px;

  cursor: auto;

  display: flex;
  align-items: center;

  width: 700px;
`

export { DisplayScanType, BoxMargin }
