import { AlignerMaterial } from './AlignerMaterial'
import { ApRelationshipPrimary } from './ApRelationshipPrimary'
import { Universal } from './ArchTools'
import { BracketType } from './BracketType'
import { MidlinePreferencesPrimary } from './MidlinePreferencesPrimary'
import { MolarTubeType } from './MolarTubeType'
import { OverbitePrimary } from './OverbitePrimary'
import { ResolveSpacingBy } from './ResolveSpacingBy'

interface PrimaryCasePreferences {
  alignerMaterial: AlignerMaterial
  additionalInstruction?: string
  biteRampTeeth: Array<Universal>
  dontMoveTeeth: Array<Universal>
  dontPlaceTeeth: Array<Universal>
  extractionTeeth: Array<Universal>
  midlinePreferencesPrimary?: MidlinePreferencesPrimary
  overbitePrimary?: OverbitePrimary
  overjetPrimary?: OverjetPrimary
  placePontics?: boolean
  resolveCrowdingByLower?: CrowdingOptions[]
  resolveCrowdingByUpper?: CrowdingOptions[]
  treatArches?: TreatArches
  treatmentType?: TreatmentType
  version?: string
  apRelationshipPrimary?: ApRelationshipPrimary
  resolveSpacingBy: ResolveSpacingBy
  upperBracket?: BracketType
  lowerBracket?: BracketType
  lowerFirstMolar?: MolarTubeType
  lowerSecondMolar?: MolarTubeType
  upperFirstMolar?: MolarTubeType
  upperSecondMolar?: MolarTubeType
}

enum CrowdingOptions {
  'IPR' = 'IPR',
  'Proclination' = 'Proclination',
  'Expansion' = 'Expansion',
}

enum OverjetPrimary {
  'MAINTAIN' = 'MAINTAIN',
  'IMPROVE' = 'IMPROVE',
}

enum TreatArches {
  'BOTH' = 'BOTH',
  'UPPER_ONLY' = 'UPPER_ONLY',
  'LOWER_ONLY' = 'LOWER_ONLY',
}

enum TreatmentType {
  'ALIGNER' = 'ALIGNER',
  'IOP' = 'IOP',
  'IDB' = 'IDB',
}

export { PrimaryCasePreferences, TreatArches, TreatmentType, CrowdingOptions, OverjetPrimary }
