import { getStyledConfig } from 'app/core/react/getStyledConfig'
import styled from 'styled-components'

interface ActionButtonProps {
  height?: string
  padding?: string
}

const ActionButton = styled.button.withConfig<ActionButtonProps>(getStyledConfig('padding'))`
  padding: ${({ padding }) => padding ?? '0 7px 1px 5px'};

  cursor: pointer;

  display: inline-flex;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  max-width: 142px;
  height: ${({ height }) => height ?? '21px'};
  border: 1px solid ${({ theme }) => theme.colors.base200};

  white-space: nowrap;
  text-overflow: ellipsis;

  color: ${({ theme }) => theme.colors.base700};
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.colors.base10} 0%, ${theme.colors.base200} 100%)`};
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  :hover {
    border-color: ${({ theme }) => theme.colors.base800};

    background: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 1px 2px ${({ theme }) => theme.colors.base200};
  }
  a {
    white-space: nowrap;
    text-decoration: none;

    color: ${({ theme }) => theme.colors.base700};
    :focus {
      text-decoration: none;
    }
  }
  div {
    overflow: hidden;

    text-overflow: ellipsis;
  }

  & + & {
    margin-left: 4px;
  }
`

export { ActionButton }
