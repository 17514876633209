import React, { useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'

import { QuestionCircleOutlined, SolutionOutlined, UserOutlined } from '@ant-design/icons'
import { MenuProps } from 'antd'
import { basicPageActions } from 'app/components/routes/BasicPage/logic'
import { Box } from 'app/components/ui/SDS/common/Box'
import { postLogoutMessage } from 'app/core/auth/AuthenticationBroadcast'
import { SessionStorageKeysMap } from 'app/core/domain/SessionStorageKeysMap'
import { SiteMap } from 'app/core/react/SiteMap'
import { appSelectors } from 'app/logic/app/logic'

import { DropdownItem } from '../DropDownItem'
import { HeaderNavigationDropdown } from '../HeaderNavigationDropdown'
import { HeaderNavigationLink } from '../HeaderNavigationLink'

interface HeaderNavigationProps {
  'data-testid': string
}

export const HeaderNavigation = ({ 'data-testid': dataTestId }: HeaderNavigationProps) => {
  const dispatch = useDispatch()
  const fullName = useSelector(appSelectors.getFullName())
  const location = useLocation()
  const isDoctor = useSelector(appSelectors.isDoctor())
  const isStaff = useSelector(appSelectors.isStaff())
  const myTeamLinkClickCallBack = useCallback(() => {
    dispatch(basicPageActions.myTeamLinkClicked())
  }, [dispatch])

  const handleLogout = () => {
    sessionStorage.removeItem(SessionStorageKeysMap.isIntegratedHooksModalOpen)
    postLogoutMessage()
  }

  const patientsMenu: MenuProps['items'] = [
    {
      label: (
        <DropdownItem href={SiteMap.DOCTORS.support()} data-testid={`${dataTestId}-support`}>
          <FormattedMessage id="header.navigation.doctors.support" />
        </DropdownItem>
      ),
      key: `${dataTestId}-support`,
    },
    {
      label: (
        <DropdownItem
          href={SiteMap.DOCTORS.downloadApprover()}
          data-testid={`${dataTestId}-downloadApprover`}
        >
          <FormattedMessage id="header.navigation.doctors.downloadApprover" />
        </DropdownItem>
      ),
      key: `${dataTestId}-downloadApprover`,
    },
    {
      label: (
        <DropdownItem href={SiteMap.DOCTORS.education()} data-testid={`${dataTestId}-education`}>
          <FormattedMessage id="header.navigation.doctors.education" />
        </DropdownItem>
      ),
      key: `${dataTestId}-education`,
    },
  ]
  const doctorMenu: MenuProps['items'] = isDoctor
    ? [
        {
          label: (
            <DropdownItem
              href="/myPractice"
              onClick={myTeamLinkClickCallBack}
              data-testid={`${dataTestId}-practice`}
            >
              <FormattedMessage id="header.navigation.doctors.practice" />
            </DropdownItem>
          ),
          key: `${dataTestId}-practice`,
        },
        {
          label: (
            <DropdownItem
              href={SiteMap.DOCTORS.clinicalPreferences()}
              data-testid={`${dataTestId}-clinicalPreferences`}
            >
              <FormattedMessage id="header.navigation.doctors.clinicalPreferences" />
            </DropdownItem>
          ),
          key: `${dataTestId}-clinicalPreferences`,
        },
      ]
    : []
  const userMenu: MenuProps['items'] = [
    {
      label: (
        <DropdownItem href={SiteMap.DOCTORS.profile()} data-testid={`${dataTestId}-preferences`}>
          <FormattedMessage id="header.navigation.doctors.preferences" />
        </DropdownItem>
      ),
      key: `${dataTestId}-preferences`,
    },
    {
      label: (
        <DropdownItem href="/myProfile" data-testid={`${dataTestId}-profile`}>
          <FormattedMessage id="header.navigation.doctors.profile" />
        </DropdownItem>
      ),
      key: `${dataTestId}-profile`,
    },
    ...doctorMenu,
    {
      label: (
        <DropdownItem
          href={SiteMap.logout()}
          onClick={handleLogout}
          data-testid={`${dataTestId}-logout`}
        >
          <FormattedMessage id="header.navigation.doctors.logout" />
        </DropdownItem>
      ),
      key: `${dataTestId}-logout`,
    },
  ]
  const isUserMenuActive = ['/myPractice', '/myProfile'].includes(location.pathname)

  if (isDoctor || isStaff) {
    return (
      <Box justify="flex-end" paddingTop="2px" height="100%">
        <HeaderNavigationLink
          icon={<SolutionOutlined />}
          data-testid={`${dataTestId}-patients`}
          textId="header.navigation.patients"
          href="/homePage"
          isActive={location.pathname === '/homePage'}
          isLink
        />
        <HeaderNavigationDropdown
          menu={patientsMenu}
          //TODO: change when new pages are available to check
          isActive={false}
          icon={<QuestionCircleOutlined />}
          textId="header.navigation.patients"
          data-testid={`${dataTestId}-patients`}
        />
        <HeaderNavigationDropdown
          menu={userMenu}
          isActive={isUserMenuActive}
          icon={<UserOutlined />}
          textId={fullName}
          data-testid={`${dataTestId}-User`}
          isRawText
        />
      </Box>
    )
  }

  return (
    <div data-testid={`${dataTestId}-NoNavigationMessage`}>
      No suitable Navigation Bar found for user
    </div>
  )
}
